import { createSlice } from '@reduxjs/toolkit';
import { StarredMessagesState } from './types';
import { LoadingTypes } from '@commonTypes/common';
import { fetchStarredMessages } from './thunks';

export const initialState: StarredMessagesState = {
  total: 0,
  data: [],
  loading: LoadingTypes.idle,
};

export const starredMessagesSlice = createSlice({
  name: 'starredMessages',
  initialState,
  reducers: {
    increaseStarredCount: (state) => {
      state.total += 1;
    },
    decreaseStarredCount: (state) => {
      const newValue = state.total - 1;
      state.total = newValue >= 0 ? newValue : 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStarredMessages.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchStarredMessages.fulfilled, (state, { payload, meta }) => {
      const prevData = meta.arg.skip ? [...state.data] : [];
      state.data = [...prevData, ...payload.items];
      state.total = payload.totalCount;
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(fetchStarredMessages.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });
  },
});

export const actions = starredMessagesSlice.actions;
export default starredMessagesSlice.reducer;
