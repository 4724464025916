import apiClient from '@api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchUserAttributesValuesPayload } from './types';

export const fetchUserAttributesValues = createAsyncThunk(
  'usersAttributes/fetchCustomAttributesByUserId',
  async ({ id }: FetchUserAttributesValuesPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get(`/users/${id}/attribute-values`);

      return data.userAttributes;
    } catch (error) {
      return rejectWithValue('Failed to fetch user attributes. Please try again later.');
    }
  }
);
