import { createSlice } from '@reduxjs/toolkit';
import { LoadingTypes } from '@commonTypes/common';
import { UsersListState } from './types';
import { fetchUsers, postUser, putUser } from './thunks';

export const initialState: UsersListState = {
  list: [],
  loading: LoadingTypes.idle,
  totalUsers: 0,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUserLocally: (state, { payload }) => {
      const index = state.list.findIndex((u) => u.id === payload.id);

      if (index !== -1) {
        state.list[index] = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchUsers.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchUsers.fulfilled, (state, { meta, payload }) => {
      const prevList = meta.arg.skip > 0 ? state.list : [];
      const newList = payload.items || [];

      state.list = [...prevList, ...newList];
      state.totalUsers = payload.totalCount;
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(postUser.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(postUser.fulfilled, (state, action) => {
      const prevList = state.list || [];
      state.list = [action.payload, ...prevList];

      state.totalUsers += 1;
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(postUser.rejected, (state) => {
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(putUser.fulfilled, (state, { payload }) => {
      const prevList = state.list || [];
      const updatedList = prevList.map((user) => {
        if (user.id === payload.id) {
          user = payload;
        }

        return user;
      });

      state.list = updatedList;
    });
  },
});

export const actions = usersSlice.actions;
export default usersSlice.reducer;
