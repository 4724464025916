import React from 'react';
import { MessageTextProps } from './MessageText.types';
import { LoadingTypes } from '@commonTypes/common';
import { UserRoles } from '@commonTypes/user';
import { MessageLoading, MessageSendStatuses } from '@commonTypes/message';
import { MessageItem, MessageItemControls } from '@components/Common';
import { formatDisplayDate, getUserInitials } from '@utils';
import classNames from 'classnames';
import { Attachment } from '@commonTypes/attachment';
import styles from './MessageText.module.scss';

const MessageText = (props: MessageTextProps) => {
  const hasLocalError = props.loading === LoadingTypes.rejected;
  const hasRemoteError = props.status === MessageSendStatuses.failed;
  const hasLoadingPendingState = props.loading === LoadingTypes.pending;
  const loadingState = props.loading || LoadingTypes.fulfilled;
  const isPartOfMassMessage = props.isPartOfMassMessage && props?.recipientsCount !== undefined;

  return (
    <MessageItemControls
      {...props}
      bottomControlsComponent={
        <>
          {props.isOwner && ((!hasLocalError && !hasRemoteError) || hasLoadingPendingState) && (
            <span className={styles.transparentText}>
              'You' · {formatDisplayDate(props.sentAt)} ·{' '}
              {isPartOfMassMessage
                ? `Message sent to ${props.recipientsCount}`
                : MessageLoading[loadingState]}
            </span>
          )}

          {!props.isOwner && (
            <span className={styles.transparentText}>{formatDisplayDate(props.sentAt)}</span>
          )}
        </>
      }
      className={classNames(styles.container, props.className)}
      messagePosition={
        props.isOwner || props.sender?.type !== UserRoles.fieldAgent ? 'right' : 'left'
      }
      senderInitials={getUserInitials(props.sender?.firstName, props.sender?.lastName)}
      withDelete={hasLocalError && !hasRemoteError}
      withErrorState={props.isOwner && (hasLocalError || hasRemoteError) && !hasLoadingPendingState}
      withSenderAvatar={!props.isOwner}
      withStarring={!hasLocalError && !hasRemoteError && !hasLoadingPendingState}
    >
      <MessageItem
        body={props.body}
        error={props.isOwner && (hasLocalError || hasRemoteError) && !hasLoadingPendingState}
        files={props.files}
        isMass={isPartOfMassMessage}
        isOwner={props.isOwner || props.sender?.type !== UserRoles.fieldAgent}
        onFilePreview={(file: Attachment) => props.onFilePreview?.(file)}
      />
    </MessageItemControls>
  );
};

export default MessageText;
