import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import apiClient from '@api';
import authReducer from './auth';
import activityReducer from './activity';
import departmentsReducer from './departments';
import channelsReducer from './channels';
import currentUserReducer from './currentUser';
import messagesReducer from './messages';
import massMessagesReducer from './massMessages';
import messagesTemplatesReducer from './messagesTemplates';
import currentUserSubscriptionsReducer from './currentUserSubscriptions';
import channelSubscribersReducer from './channelsSubscribers';
import settingsReducer from './settings';
import starredMessagesReducer from './starredMessages';
import channelsFilesReducer from './channelsFiles';
import toastsReducer from './toasts';
import usersDetailsReducer from './usersDetails';
import usersReducer from './users';
import tagsReducer from './tags';
import attributesReducer from './attributes';
import usersAttributesReducer from './usersAttributes';
import notesReducer from './notes';
import persistPreferencesReducer from './persistPreferences';
import globalSettingsReducer from './globalSettings';
import personalSettingsReducer from './personalSettings';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'activity', 'persistPreferences'],
};

const reducers = combineReducers({
  auth: authReducer.reducer,
  activity: activityReducer.reducer,
  departments: departmentsReducer.reducer,
  channels: channelsReducer.reducer,
  currentUser: currentUserReducer.reducer,
  messages: messagesReducer.reducer,
  massMessages: massMessagesReducer.reducer,
  messagesTemplates: messagesTemplatesReducer.reducer,
  currentUserSubscriptions: currentUserSubscriptionsReducer.reducer,
  channelSubscribers: channelSubscribersReducer.reducer,
  settings: settingsReducer.reducer,
  starredMessages: starredMessagesReducer.reducer,
  channelsFiles: channelsFilesReducer.reducer,
  toasts: toastsReducer.reducer,
  usersDetails: usersDetailsReducer.reducer,
  users: usersReducer.reducer,
  tags: tagsReducer.reducer,
  attributes: attributesReducer.reducer,
  usersAttributes: usersAttributesReducer.reducer,
  notes: notesReducer.reducer,
  persistPreferences: persistPreferencesReducer.reducer,
  globalSettings: globalSettingsReducer.reducer,
  personalSettings: personalSettingsReducer.reducer,
});

const initialState = {
  auth: authReducer.initialState,
  activity: activityReducer.initialState,
  departments: departmentsReducer.initialState,
  channels: channelsReducer.initialState,
  currentUser: currentUserReducer.initialState,
  messages: messagesReducer.initialState,
  massMessages: massMessagesReducer.initialState,
  messagesTemplates: messagesTemplatesReducer.initialState,
  currentUserSubscriptions: currentUserSubscriptionsReducer.initialState,
  channelSubscribers: channelSubscribersReducer.initialState,
  settings: settingsReducer.initialState,
  starredMessages: starredMessagesReducer.initialState,
  channelsFiles: channelsFilesReducer.initialState,
  toasts: toastsReducer.initialState,
  usersDetails: usersDetailsReducer.initialState,
  users: usersReducer.initialState,
  tags: tagsReducer.initialState,
  attributes: attributesReducer.initialState,
  usersAttributes: usersAttributesReducer.initialState,
  notes: notesReducer.initialState,
  persistPreferences: persistPreferencesReducer.initialState,
  globalSettings: globalSettingsReducer.initialState,
  personalSettings: personalSettingsReducer.initialState,
};

export const persistedReducer = persistReducer(
  persistConfig,
  (state: ReturnType<typeof reducers> | undefined, action: AnyAction) => {
    if (action.type === 'auth/reset') {
      // Clearing all data from current session.

      const updatesState = {
        ...initialState,
      };

      return reducers(updatesState, action);
    }

    if (action.type === 'auth/reload') {
      const updatesState = {
        ...initialState,
        activity: state?.activity || activityReducer.initialState,
        auth: state?.auth || authReducer.initialState,
      };
      return reducers(updatesState, action);
    }

    if (action.type === 'auth/reset-keep-logged-in') {
      // Clearing all data from current session except auth data.
      const updatesState = {
        ...initialState,
        auth: state?.auth || authReducer.initialState,
      };
      return reducers(updatesState, action);
    }

    return reducers(state, action);
  }
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'notes/updateNote/fulfilled',
        ],
      },
    }),
});

apiClient.setupApiClient(store);

export const persistor = persistStore(store);

export default store;
