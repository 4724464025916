import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { TabProps } from './Tabs.types';
import styles from './Tabs.module.scss';

const Tab = ({ children, icon, active, warning, onClick, ...rest }: TabProps) => {
  const onTabClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick?.(event);
  };

  return (
    <button
      type="button"
      {...rest}
      className={classNames(styles.tab, { [styles.tabItemActive]: active })}
      onClick={onTabClick}
    >
      {icon}
      {children}
      <span
        className={classNames({
          [styles.warningIcon]: !!warning,
        })}
      />
    </button>
  );
};

export default Tab;
