import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { MassMessageRecipient, MessageRecipientType } from '@commonTypes/massMessage';
import { LoadingTypes } from '@commonTypes/common';
import massMessagesState from '@state/massMessages';
import activityState from '@state/activity';
import channelsState from '@state/channels';
import messagesState from '@state/messages';
import currentUserSubscriptionsState from '@state/currentUserSubscriptions';
import { MessageAreaValues } from '@components/Conversation/MessageArea/MessageArea.types';
import { Channel, ChannelListTypes, ChannelTypes } from '@commonTypes/channel';
import { Attachment } from '@commonTypes/attachment';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { useNotificationsContext } from '@providers/NotificationsProvider/useNotificationsContext';
import { FilePreviewContext } from '@providers/FilePreviewProvider';

const useNewConversation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const filesModalContext = useContext(FilePreviewContext);
  const { addInterest } = useNotificationsContext() || {};
  const [phoneNumberValue, setPhoneNumberValue] = useState<string | undefined>();
  const [recipients, setRecipients] = useState<MassMessageRecipient[]>([]);
  const [recipientsLoading, setRecipientsLoading] = useState<LoadingTypes>(LoadingTypes.idle);
  const [sendLoading, setSendLoading] = useState<LoadingTypes>(LoadingTypes.idle);

  const { data: activityData } = useSelector((state: RootState) => state.activity);

  const isPhoneNumberValid = () => {
    const numbers = replaceCharacters(phoneNumberValue);

    return numbers.length === 10 || (numbers.length === 11 && numbers[0] === '1');
  };

  const replaceCharacters = (value = '') => {
    return value.replace(/\D/g, '');
  };

  const preparePhoneNumber = (value = '') => {
    const numbers = replaceCharacters(value);

    if (numbers.length === 10) {
      return `+1${numbers}`;
    } else {
      return `+${numbers}`;
    }
  };

  const filterRecipientsList = (recipientsList: MassMessageRecipient[] = []) => {
    try {
      const filtered = recipientsList.filter(
        (recipient) => recipient.type === MessageRecipientType.fieldAgent
      );

      return filtered;
    } catch (error) {
      return recipientsList;
    }
  };
  const onSearchRecipients = (searchTerm: string) => {
    setPhoneNumberValue(searchTerm);
  };

  const onFetchRecipients = async () => {
    try {
      if (activityData?.activeDepartment?.id) {
        const params = {
          search: phoneNumberValue || '',
          departmentId: activityData.activeDepartment.id,
        };

        setRecipientsLoading(LoadingTypes.pending);

        const data = await dispatch(massMessagesState.actions.searchRecipients(params)).unwrap();
        const filteredData = filterRecipientsList(data);

        setRecipients(filteredData);
        setRecipientsLoading(LoadingTypes.fulfilled);
      }
    } catch (error) {
      setRecipientsLoading(LoadingTypes.fulfilled);
    }
  };

  const onRecipientSelected = (recipient: MassMessageRecipient) => {
    if (recipient.phoneNumber) {
      setPhoneNumberValue(recipient.phoneNumber);
    }
  };

  const onMessageSentToNewChannel = (channel: Channel) => {
    if (activityData.activeChannelsFilter !== ChannelListTypes.my) {
      dispatch(activityState.actions.setActiveChannelsFilter(ChannelListTypes.my));
    }

    dispatch(channelsState.actions.addChannelIfNotExist(channel));
    dispatch(
      currentUserSubscriptionsState.actions.subscribeCurrentUser({
        channelId: channel.id,
        departmentId: channel.departmentId,
      })
    );

    addInterest?.(channel.id);
    dispatch(activityState.actions.setActiveChannel(channel));
    navigate(ROUTES.conversation);
  };

  const onMessageSentToExistingChannel = (channel: Channel) => {
    dispatch(activityState.actions.setActiveChannel(channel));
    navigate(ROUTES.conversation);
  };

  const onRedirectToChannels = () => {
    navigate(ROUTES.channels);
  };

  const onMessageSend = async ({ attachments, textValue, isSigned }: MessageAreaValues) => {
    try {
      if (activityData?.activeDepartment?.id && isPhoneNumberValid()) {
        setSendLoading(LoadingTypes.pending);

        const messageData = {
          body: textValue,
          isSigned: isSigned,
          channelType: ChannelTypes.fieldAgentPhone,
          departmentId: activityData.activeDepartment.id,
          fieldAgentIdentifier: preparePhoneNumber(phoneNumberValue),
          fileIds: attachments.map(({ id }: Attachment) => id),
        };

        const data = await dispatch(
          messagesState.actions.postMessageToNewRecipient(messageData)
        ).unwrap();

        if (data?.channel && data?.fieldAgent) {
          const payload = {
            ...data.channel,
            fieldAgent: data.fieldAgent,
          };

          data?.sentToExistingChannel
            ? onMessageSentToExistingChannel(payload)
            : onMessageSentToNewChannel(payload);
        }

        if (data?.channel && data?.fieldAgent && data?.sentToExistingChannel) {
          onMessageSentToExistingChannel({
            ...data.channel,
            fieldAgent: data.fieldAgent,
          });
        }

        if (data?.channel && data?.fieldAgent && !data?.sentToExistingChannel) {
          onMessageSentToNewChannel({
            ...data.channel,
            fieldAgent: data.fieldAgent,
          });
        }

        setSendLoading(LoadingTypes.fulfilled);
      }
    } catch (error) {
      setSendLoading(LoadingTypes.rejected);
    }
  };

  const onFilePreview = (file: Attachment) => {
    if (file.mimeType.includes('image')) {
      filesModalContext?.openFileInModalWindow(file);
    }
  };

  useEffect(() => {
    if (phoneNumberValue !== undefined) {
      onFetchRecipients();
    }
  }, [phoneNumberValue]);

  return {
    sendLoading,
    recipients,
    phoneNumberValue,
    isPhoneNumberValid: isPhoneNumberValid(),
    recipientsLoading,
    onRedirectToChannels,
    onSearchRecipients,
    onRecipientSelected,
    onFilePreview,
    onMessageSend,
  };
};

export default useNewConversation;
