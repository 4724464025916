import React from 'react';
import classNames from 'classnames';
import { AlertIcon } from '../../Icons';
import { InputContainerComponentProps } from './InputContainer.types';
import styles from './InputContainer.module.scss';

const InputContainer = ({
  id,
  children,
  inputSize,
  error,
  success,
  label,
  subLabel,
  labelIcon,
  iconLeft,
  iconRight,
  onIconLeftClick,
  onIconRightClick,
  className,
}: InputContainerComponentProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      {label && (
        <label htmlFor={id}>
          {labelIcon}
          {label}
          {subLabel && <span className={styles.subLabel}>({subLabel})</span>}
        </label>
      )}

      <div
        className={classNames(styles.fieldContainer, {
          [styles.smallSize]: inputSize === 'small',
          [styles.mediumSize]: inputSize !== 'small',
          [styles.leftSpace]: !!iconLeft,
          [styles.rightSpace]: !!iconRight,
          [styles.errorState]: !!error,
          [styles.successState]: !!(success && !error),
        })}
      >
        {iconLeft && (
          <div
            className={styles.iconLeft}
            onClick={(event) => {
              event.stopPropagation();
              onIconLeftClick?.();
            }}
          >
            {!error ? (
              iconLeft
            ) : (
              <span className={styles.errorIcon}>
                <AlertIcon />
              </span>
            )}
          </div>
        )}

        {children}

        {iconRight && (
          <div
            className={styles.iconRight}
            onClick={(event) => {
              event.stopPropagation();
              onIconRightClick?.();
            }}
          >
            {!error ? (
              iconRight
            ) : (
              <span className={styles.errorIcon}>
                <AlertIcon />
              </span>
            )}
          </div>
        )}
      </div>

      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

export default InputContainer;
