import { Channel } from '@commonTypes/channel';
import { ChannelEntry, MessageTypes } from '@commonTypes/message';
import { User, UserRoles, UserShort } from '@commonTypes/user';
import dayjs from 'dayjs';

export const modifyLastEntryInChannelsList = (channels: Channel[], lastEntry: ChannelEntry) => {
  try {
    return channels.map((channel) => {
      if (channel.id === lastEntry?.channelId) {
        channel.lastEntry = lastEntry;
      }

      return channel;
    });
  } catch (e) {
    return channels;
  }
};

export const getLastEntryDate = (lastEntry?: ChannelEntry) => {
  let date = '';
  if (lastEntry?.type === MessageTypes.text || lastEntry?.type === MessageTypes.massMessage) {
    date = lastEntry?.createdAt || '';
  }

  if (lastEntry?.type === MessageTypes.phoneCall) {
    date = lastEntry?.endedAt || '';
  }

  return date;
};

export const getLastEntryText = (lastEntry?: ChannelEntry) => {
  if (lastEntry?.type === MessageTypes.text || lastEntry?.type === MessageTypes.massMessage) {
    if (lastEntry?.body) {
      return lastEntry?.body;
    }

    if (lastEntry?.files?.length) {
      return lastEntry?.files?.length > 1 ? 'Files' : 'File';
    }
  }

  if (lastEntry?.type === MessageTypes.phoneCall) {
    // message for phone call will be updated
    return `Call ${lastEntry?.status || ''}`;
  }

  return '';
};

export const sortChannelsByLastEntry = (channels: Channel[], lastEntry: ChannelEntry) => {
  try {
    const modifiedChannels = modifyLastEntryInChannelsList(channels, lastEntry);

    return modifiedChannels.sort((a, b) => {
      const dateA = getLastEntryDate(a.lastEntry);
      const dateB = getLastEntryDate(b.lastEntry);
      const timeA = dayjs.utc(dateA).tz().valueOf();
      const timeB = dayjs.utc(dateB).tz().valueOf();

      if (a.lastEntry?.createdAt && !b.lastEntry?.createdAt) {
        return -1;
      }

      if (!a.lastEntry?.createdAt && b.lastEntry?.createdAt) {
        return 1;
      }

      if (timeA > timeB) {
        return -1;
      }

      if (timeA < timeB) {
        return 1;
      }

      return 0;
    });
  } catch (e) {
    return channels;
  }
};

export const extractSubscribersNames = (
  subscribers: User[],
  currentUserId: number,
  withLastName = true
): string => {
  try {
    return subscribers
      .filter((subscriber) => subscriber.id !== currentUserId)
      .map((follower) => `${follower.firstName}${withLastName ? ' ' + follower.lastName : ''}`)
      .join(', ');
  } catch (e) {
    return '';
  }
};

export const findChannelById = (channelsList: Channel[], currentChannelId: number) => {
  try {
    return channelsList?.find((channel: Channel) => channel.id === currentChannelId);
  } catch (e) {
    return null;
  }
};

export const findFieldAgentByPhoneNumber = (channelsList: Channel[], phoneNumber?: string) => {
  try {
    return channelsList?.find((channel) => channel.fieldAgent?.phoneNumber === phoneNumber)
      ?.fieldAgent;
  } catch (e) {
    return null;
  }
};

export const filterFieldAgentFromSubscriber = (subscribersList?: UserShort[]) => {
  try {
    if (!subscribersList) {
      return [];
    }
    return subscribersList?.filter((subscriber) => subscriber.type !== UserRoles.fieldAgent);
  } catch (e) {
    return subscribersList;
  }
};

export const getChannelById = (channelId?: number, channelsList?: Channel[]) => {
  try {
    if (!channelId) return undefined;

    return channelsList?.find((channel) => channel.id === channelId);
  } catch (error) {
    return undefined;
  }
};
