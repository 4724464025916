import React from 'react';
import classNames from 'classnames';
import { ChannelsCardProps } from './ChannelsCard.types';
import ChannelsCardIcon from './ChannelsCardIcon';
import { dt, formatCardLastMessageDate, formatPhoneNumberToLocalUS } from '@utils';
import styles from './ChannelsCard.module.scss';

const ChannelsCard = ({
  channelName,
  unreadCount,
  messageOwner,
  messageText,
  messageDate,
  active,
  channelType,
  className,
  ...rest
}: ChannelsCardProps) => {
  return (
    <div
      {...rest}
      className={classNames(styles.container, className, {
        [styles.unreadBadge]: unreadCount,
        [styles.selected]: active,
      })}
    >
      <div className={styles.messageContainer}>
        <div className={classNames(styles.titleContainer)}>
          <ChannelsCardIcon channelType={channelType} />
          <div className={styles.title}>{formatPhoneNumberToLocalUS(channelName)}</div>
          {!!unreadCount && <div className={styles.counter}>{unreadCount}</div>}
        </div>

        <div className={styles.message}>
          <span className={styles.arrowIcon}>
            {messageText && <>{messageOwner ? <>&rarr;</> : <>&larr;</>}</>}
          </span>
          <div className={styles.text}>{messageText || 'No messages sent yet'}</div>
        </div>
      </div>

      {messageDate && (
        <div className={styles.dateTimeContainer}>
          <p className={styles.time}>{dt(messageDate)}</p>
          <p className={styles.date}>{formatCardLastMessageDate(messageDate)}</p>
        </div>
      )}
    </div>
  );
};

export default ChannelsCard;
