import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  FetchRecipientsPayload,
  FetchMassMessagesPayload,
  FetchChannelRecipientsPayload,
  UpdateChannelNamePayload,
  FetchChannelFilesPayload,
  FetchChannelByRecipientsPayload,
  PostNewMassChannelPayload,
  PostMassMessagePayload,
} from './types';
import apiClient from '@api';
import { PostFilePayload } from '@state/messages/types';

export const fetchMassMessages = createAsyncThunk(
  'massMessages/fetchMassMessages',
  async (
    { massMessageChannelId, beforeMassMessageId, take }: FetchMassMessagesPayload,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await apiClient.instance.get('mass-messages', {
        params: {
          MassMessageChannelId: massMessageChannelId,
          BeforeMassMessageId: beforeMassMessageId,
          Take: take,
        },
      });

      return data;
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const searchRecipients = createAsyncThunk(
  'massMessages/searchRecipients',
  async ({ departmentId, search }: FetchRecipientsPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get(
        `messages/recipients/${departmentId}/${search}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const verifyIfMassMessagesChannelExist = createAsyncThunk(
  'massMessages/verifyIfMassMessagesChannelExist',
  async (
    { departmentId, tagRecipientIds, fieldAgentRecipientIds }: FetchChannelByRecipientsPayload,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await apiClient.instance.post('mass-message-channels/id', {
        departmentId,
        tagRecipientIds,
        fieldAgentRecipientIds,
      });
      return data;
    } catch (error) {
      return rejectWithValue(undefined);
    }
  }
);

export const getMassMessageChannelRecipients = createAsyncThunk(
  'massMessages/getMassMessageChannelRecipients',
  async ({ massMessagesChannelId }: FetchChannelRecipientsPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get(
        `mass-message-channels/${massMessagesChannelId}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const getMassMessageChannelFiles = createAsyncThunk(
  'massMessages/getMassMessageChannelFiles',
  async (
    { massMessageChannelId, beforeFileId, take }: FetchChannelFilesPayload,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await apiClient.instance.get('mass-message-files', {
        params: {
          massMessageChannelId: massMessageChannelId,
          beforeFileId: beforeFileId,
          take: take,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const putMassMessagesChannelName = createAsyncThunk(
  'massMessages/putMassMessagesChannel',
  async ({ massMessagesChannelId, name }: UpdateChannelNamePayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.put(
        `mass-message-channels/${massMessagesChannelId}/name`,
        name,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const postMassMessageFile = createAsyncThunk(
  'massMessages/postMassMessageFile',
  async ({ file }: PostFilePayload, { fulfillWithValue }) => {
    try {
      const response = await apiClient.instance.post('mass-message-files', file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return response.data;
    } catch (error) {
      return fulfillWithValue(null);
    }
  }
);

export const postMassMessage = createAsyncThunk(
  'massMessages/postMassMessage',
  async (
    { massMessageChannelId, body, fileIds, isSigned, conversationId }: PostMassMessagePayload,
    { fulfillWithValue }
  ) => {
    try {
      const { data } = await apiClient.instance.post(
        `mass-messages/mass-message-channel/${massMessageChannelId}`,
        {
          body,
          fileIds,
          isSigned,
          conversationId,
        }
      );

      return data;
    } catch (error) {
      return fulfillWithValue(null);
    }
  }
);

export const postNewMassChannel = createAsyncThunk(
  'massMessages/postNewMassChannel',
  async (payload: PostNewMassChannelPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.post('mass-messages/', payload);

      return data;
    } catch (error) {
      return rejectWithValue('');
    }
  }
);
