export const emailRegexp = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const phoneNumberRegexp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
export const unbreakablePassword = new RegExp(
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{32,128})'
);
export const veryStrongPassword = new RegExp(
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,64})'
);
export const strongPassword = new RegExp(
  '((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,64}))|((?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,64}))|((?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,64}))'
);
export const goodPassword = new RegExp(
  '((?=.*[a-z])(?=.*[A-Z])(?=.{8,64}))|((?=.*[a-z])(?=.*[0-9])(?=.{8,64}))|((?=.*[A-Z])(?=.*[0-9])(?=.{8,64}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,64}))'
);
export const weakPassword = new RegExp(
  '((?=.*[a-z])(?=.{8,64}))|((?=.*[A-Z])(?=.{8,64}))|((?=.*[0-9])(?=.{8,64}))|((?=.*[^A-Za-z0-9])(?=.{8,64}))'
);
