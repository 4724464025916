import React, { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Dropdown,
  InfiniteScroll,
  Loader,
  MenuItem,
  MenuList,
  Tag,
  TextField,
} from '@components/Common';
import { MultiSelectProps } from './MultiSelect.types';
import styles from './MultiSelect.module.scss';
import { LoadingTypes, Option } from '@commonTypes/common';

const MultiSelect = ({
  options,
  icon,
  value,
  size,
  placeholder,
  onSelectChange,
  onSelectRemove,
  emptyMessage = 'No options found',
  loadMoreMessage = 'Load more options',
  onFilter,
  label,
  initLoading,
  dropdownPosition,
  moreLoading = LoadingTypes.idle,
  onInitFetch,
  onMoreFetch,
  className,
  ...rest
}: MultiSelectProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selected, setSelected] = useState<MultiSelectProps['value']>([]);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);

  const onBlur = () => {
    if (searchValue) {
      setSearchValue('');
      onFilter?.('');
    }
  };

  const onSelect = (option: Option) => {
    const selection = [...(selected || []), option];
    setIsSelectOpen(false);
    setSelected(selection);
    onSelectChange(option);
  };

  const onSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(target.value);
    onFilter?.(target.value);
  };

  const onRemove = (option: Option) => {
    const filtered = selected?.filter((opt) => opt.value !== option.value) || [];
    setSelected(filtered);
    onSelectRemove(option);
  };

  useEffect(() => {
    const filtered = options?.filter((option: Option) => {
      return !selected?.some((selection: Option) => selection.value === option.value);
    });

    setFilteredOptions(filtered);
  }, [searchValue, options, selected]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (
      searchValue &&
      !isSelectOpen &&
      (initLoading === LoadingTypes.pending || moreLoading === LoadingTypes.pending)
    ) {
      setIsSelectOpen(true);
    }
  }, [moreLoading, initLoading]);

  return (
    <div {...rest} className={classNames(styles.container, className)}>
      {label && <div className={styles.label}>{label}</div>}

      <div
        className={classNames(styles.optionsContainer, {
          [styles.withIcon]: !!icon,
          [styles.small]: size === 'small',
          [styles.medium]: size !== 'small',
        })}
      >
        {icon && <span className={styles.icon}>{icon}</span>}
        <div className={styles.tagsContent}>
          {selected?.length ? (
            <div className={styles.tagsList}>
              {selected?.map((option: Option) => (
                <Tag
                  className={styles.tag}
                  icon={option.icon}
                  key={option.value}
                  label={option.label}
                  value={option.value}
                  onTagRemove={() => onRemove(option)}
                />
              ))}
            </div>
          ) : null}

          <Dropdown
            containerClassName={styles.dropdownContainer}
            offsetVertical={8}
            content={
              <MenuList className={styles.menuContainer}>
                <>
                  <InfiniteScroll
                    className={styles.optionsListContainer}
                    dataLength={filteredOptions.length}
                    direction="end"
                    emptyMessage={emptyMessage}
                    firstElementId={filteredOptions[0]?.value}
                    key="infinite-scroll-multi-select"
                    lastElementId={filteredOptions[options.length - 1]?.value}
                    onLoadMore={() => onMoreFetch?.()}
                    loading={moreLoading}
                    // loadingMessage={loadMoreMessage}
                  >
                    {filteredOptions.map((option) => (
                      <MenuItem key={option.value} onMouseDown={() => onSelect(option)}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </InfiniteScroll>

                  <Loader fullSize visible={initLoading === LoadingTypes.pending} />
                </>
              </MenuList>
            }
            open={isSelectOpen}
            onVisibilityChange={setIsSelectOpen}
          >
            <TextField
              className={styles.textField}
              inputSize={size}
              placeholder={placeholder}
              value={searchValue}
              onBlur={onBlur}
              onChange={onSearch}
            />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
