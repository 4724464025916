import React from 'react';
import classNames from 'classnames';
import { VideoProps } from './Video.types';
import styles from './Video.module.scss';

const Video = ({ srcLarge, mimeType, ...props }: VideoProps) => {
  return (
    <div onClick={props.onClick} className={classNames(styles.container, props.className)}>
      <video controls>
        <source src={srcLarge} type={mimeType} />
        Video player not supported by your browser
      </video>
    </div>
  );
};

export default Video;
