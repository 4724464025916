import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';

export const fetchUser = createAsyncThunk(
  'currentUser/fetchProfile',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/me');

      return data;
    } catch (error) {
      console.log('[Profile: fetch profile]: Failed to fetch user info', error);
      return rejectWithValue({});
    }
  }
);
