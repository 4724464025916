import React from 'react';
import classNames from 'classnames';
import styles from './StarredChannelEntries.module.scss';
import { StarredChannelEntriesProps } from './StarredChannelEntries.types';
import useStarredChannelEntries from './useStarredChannelEntries';
import { InfiniteScroll } from '@components/Common';
import StarredChannelEntry from './StarredChannelEntry';
import { dt } from '@utils';
import { MessageText } from '@commonTypes/message';

const StarredChannelEntries = (props: StarredChannelEntriesProps) => {
  const {
    currentUser,
    starredMessages,
    starredMessagesCount,
    starredMessagesLoading,
    fetchMoreStarredMessages,
  } = useStarredChannelEntries();
  return (
    <div className={classNames(styles.container, props.className)}>
      <InfiniteScroll
        className={styles.entriesContainer}
        firstElementId={starredMessages?.[0]?.id}
        lastElementId={starredMessages?.[starredMessages.length - 1]?.id}
        dataLength={starredMessages?.length || 0}
        direction={'end'}
        loading={starredMessagesLoading}
        // loaderComponent={<div>Loading...</div>}
        emptyMessage={'No messages yet'}
        onLoadMore={fetchMoreStarredMessages}
      >
        {starredMessages?.map((message: MessageText) => (
          <StarredChannelEntry
            attachments={message.files}
            className={styles.message}
            key={`starred-message-${message.id}`}
            owner={currentUser?.id === message?.sender?.id}
            senderInitials={`${message?.sender?.firstName} ${message?.sender?.lastName}`}
            sentAt={dt(message?.createdAt || '')}
            text={message.body}
            onOpen={() => {}}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default StarredChannelEntries;
