import React from 'react';
import { MessageTypes } from '@commonTypes/message';
import { MessageProps } from './ChannelEntry.types';
import MessagePhoneCall from './MessagePhoneCall';
import MessageText from './MessageText';

const ChannelEntry = (props: MessageProps) => {
  return (
    <>
      {props.type === MessageTypes.text && <MessageText {...props} />}

      {props.type === MessageTypes.phoneCall && <MessagePhoneCall {...props} />}
    </>
  );
};

export default ChannelEntry;
