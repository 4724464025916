import { createSlice } from '@reduxjs/toolkit';
import { LoadingTypes } from '@commonTypes/common';
import { UsersDetailsState } from './types';
import { fetchUserById } from './thunks';
import { putUser } from '@state/users/thunks';

export const initialState: UsersDetailsState = {};

export const usersDetailsSlice = createSlice({
  name: 'usersDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserById.pending, (state, { meta }) => {
      const userId = meta.arg;
      const prevData = state[userId]?.data || {};
      state[userId] = {
        ...prevData,
        loading: LoadingTypes.pending,
      };
    });

    builder.addCase(fetchUserById.rejected, (state, { meta }) => {
      const userId = meta.arg;
      const prevData = state[userId]?.data || {};
      state[userId] = {
        ...prevData,
        loading: LoadingTypes.rejected,
      };
    });

    builder.addCase(fetchUserById.fulfilled, (state, { meta, payload }) => {
      const userId = meta.arg;
      const prevData = state[userId]?.data || {};

      state[userId] = {
        data: { ...prevData, ...payload },
        loading: LoadingTypes.fulfilled,
      };
    });

    builder.addCase(putUser.pending, (state, { meta }) => {
      const userId = meta.arg.userId;
      state[userId].loading = LoadingTypes.pending;
    });

    builder.addCase(putUser.fulfilled, (state, { meta, payload }) => {
      const userId = meta.arg.userId;
      const prevData = state[userId].data;

      state[userId] = {
        data: {
          ...prevData,
          ...payload,
        },
        loading: LoadingTypes.fulfilled,
      };
    });

    builder.addCase(putUser.rejected, (state, { meta }) => {
      const userId = meta.arg.userId;
      state[userId].loading = LoadingTypes.rejected;
    });
  },
});

export const actions = usersDetailsSlice.actions;
export default usersDetailsSlice.reducer;
