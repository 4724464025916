import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Channel, ChannelListTypes, ChannelTypes } from '@commonTypes/channel';
import activityState from '@state/activity';
import { ROUTES } from '@constants';
import { FetchChannelsPayload } from '@state/channels/types';
import { ChannelsListProps } from './ChannelsList.types';
import { LoadingTypes } from '@commonTypes/common';
import channelsState from '@state/channels';

const useChannelsList = (props: ChannelsListProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { activeDepartment, activeChannel } = useSelector(
    (state: RootState) => state.activity.data || {}
  );
  const sessionData = useSelector((state: RootState) => state.auth.data?.session);
  const [channelsList, setChannelsList] = useState<Channel[]>([]);

  const { list: channels, loading: channelsLoading } = useSelector(
    (state: RootState) => state.channels.data
  );

  const setActiveChannel = (channel: Channel) => {
    dispatch(activityState.actions.setActiveChannel(channel));
    navigate(ROUTES.conversation);
  };

  const extractChannelName = (channel: Channel) => {
    if (channel.type === ChannelTypes.massMessageChannel && channel.name) {
      return channel.name;
    } else {
      return `${channel.fieldAgent?.firstName} ${channel.fieldAgent?.lastName}`;
    }
  };

  const fetchChannels = async (params: FetchChannelsPayload) => {
    if (channelsLoading !== LoadingTypes.pending) {
      return dispatch(channelsState.actions.fetchChannels(params)).unwrap();
    }
  };

  useEffect(() => {
    setChannelsList(channels);
  }, [channels]);

  useEffect(() => {
    if (activeDepartment && props.searchValue !== undefined) {
      fetchChannels({
        search: props.searchValue,
        channelsType: props.channelsType,
        departmentId: activeDepartment.id,
      });
    }
  }, [props.searchValue]);

  useEffect(() => {
    if (activeDepartment) {
      fetchChannels({
        search: props.searchValue,
        channelsType: props.channelsType,
        departmentId: activeDepartment.id,
      });
    }
  }, [activeDepartment?.id, props.channelsType]);

  return {
    activeChannel,
    channelsList,
    channelsLoading,
    sessionData,
    setActiveChannel,
    extractChannelName,
  };
};

export default useChannelsList;
