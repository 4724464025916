import React, { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '@constants';
import { useSelector } from 'react-redux';
import { RootState } from '@state/types';

export interface AuthProviderProps {
  children: ReactElement;
}

const AuthProvider = ({ children }: AuthProviderProps): ReactElement => {
  const { pathname } = useLocation();
  const { session } = useSelector((state: RootState) => state.auth?.data) || {};

  return (
    <>
      {!session?.sessionId && pathname !== ROUTES.signIn ? (
        <Navigate replace to={ROUTES.signIn} />
      ) : (
        children
      )}

      {session?.sessionId && pathname === ROUTES.signIn ? (
        <Navigate replace to={ROUTES.channels} />
      ) : null}
    </>
  );
};

export default AuthProvider;
