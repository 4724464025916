import React from 'react';
import classNames from 'classnames';
import { Button, H5 } from '@components/Common';
import { DownloadIcon } from '@components/Common/Icons';
import FileIcon from '../FileIcon';
import { FilePreviewDetailedProps } from './FilePreviewDetailed.types';
import styles from './FilePreviewDetailed.module.scss';

const FilePreviewDetailed = ({
  srcSmall,
  fileSize,
  ext,
  type,
  srcLarge,
  fileName,
  createdAt,
  owner,
  mimeType,
  onFilePreview,
  onFileDownload,
  className,
  ...props
}: FilePreviewDetailedProps) => {
  return (
    <div className={classNames(styles.container, className)} {...props}>
      <FileIcon
        className={styles.preview}
        ext={ext}
        fileName={fileName}
        mimeType={mimeType}
        srcLarge={srcLarge}
        srcSmall={srcSmall}
        type={type}
        onClick={onFilePreview}
      />

      <div className={styles.description}>
        <H5 className={styles.fileName} weight="normal">
          {fileName}
        </H5>
        {createdAt && (
          <H5 className={styles.ownerName} weight="normal">
            {createdAt}
          </H5>
        )}
        {owner && (
          <H5 className={styles.ownerName} weight="normal">
            {owner}
          </H5>
        )}
        {fileSize && (
          <H5 className={styles.ownerName} weight="normal">
            {fileSize}
          </H5>
        )}
      </div>

      <Button
        className={styles.downloadButton}
        icon={<DownloadIcon />}
        size="small"
        onClick={onFileDownload}
      />
    </div>
  );
};

export default FilePreviewDetailed;
