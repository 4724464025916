import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { FetchChannelsPayload } from '@state/channels/types';

export const fetchChannels = createAsyncThunk(
  'channels/fetchChannels',
  async ({ channelsType, search, departmentId }: FetchChannelsPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/channels', {
        params: {
          Filter: channelsType,
          Keyword: search,
          DepartmentId: departmentId,
        },
      });

      return data.channels;
    } catch (error) {
      return rejectWithValue([]);
    }
  }
);

export const searchChannels = createAsyncThunk(
  'channels/searchChannels',
  async ({ channelsType, search, departmentId }: FetchChannelsPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/channels', {
        params: {
          Filter: channelsType,
          Keyword: search,
          DepartmentId: departmentId,
        },
      });

      return data.channels;
    } catch (error) {
      return rejectWithValue([]);
    }
  }
);
