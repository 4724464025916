import { Attachment } from './attachment';
import { LoadingTypes } from '@commonTypes/common';
import { UserShort } from '@commonTypes/user';

export enum MessageSendStatuses {
  sent = 'Sent',
  failed = 'SendingFailed',
}

export const MessageLoading: Partial<Record<LoadingTypes, string>> = {
  [LoadingTypes.fulfilled]: 'Sent',
  [LoadingTypes.pending]: 'Sending',
  [LoadingTypes.rejected]: 'Error',
};

export enum MessageTypes {
  text = 'Message',
  phoneCall = 'PhoneCall',
  massMessage = 'MassMessage',
}

export interface MessageTemplate {
  id: number;
  userId?: number;
  title?: string;
  name?: string;
  body?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface MessageCommonProperties {
  id: number;
  type: MessageTypes;
  departmentId: number;
  status?: MessageSendStatuses;
  channelId: number;
  updatedAt: string;
  createdAt: string;
}

export interface MessageText extends MessageCommonProperties {
  body?: string;
  files?: Array<Attachment>;
  sender: UserShort;
  sentAt?: string;
  isStarred?: boolean;
  isSigned: boolean;
  loading?: LoadingTypes;
  isPartOfMassMessage: boolean;
  recipientsCount?: number;
}

export interface MessagePhoneCall extends MessageCommonProperties {
  caller?: UserShort;
  dialingAt?: string;
  ringingAt?: string;
  answeredAt?: string;
  endedAt?: string;
}

export interface ChannelEntry extends MessageText, MessagePhoneCall {}

export interface MassChannelEntry {
  id: number;
  body?: string;
  status: string;
  sentAt: string;
  isSigned: boolean;
  departmentId: number;
  massMessageFiles: Attachment[];
  massMessageChannelId: number;
  senderId: number;
  senderFirstName: string;
  senderLastName: string;
  recipientsCount?: number;
  loading?: LoadingTypes;
}

export interface ScheduledMessage extends MessageCommonProperties {
  body?: string;
  files?: Array<Attachment>;
  sender?: UserShort;
  sentAt?: string;
  isStarred?: boolean;
  isSigned: boolean;
  loading?: LoadingTypes;
  scheduledAt: string;
}
