import { UserInfoProps } from './UserInfo.types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import usersDetailsState from '@state/usersDetails';

const useUserInfo = (props: UserInfoProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { data: userDetails, loading: userDetailsLoading } = useSelector(
    (state: RootState) => state.usersDetails[props.userId] || {}
  );

  useEffect(() => {
    dispatch(usersDetailsState.actions.fetchUserById(props.userId));
  }, [props.userId]);

  return {
    userDetails,
    userDetailsLoading,
  };
};

export default useUserInfo;
