import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { CreateTemplatePayload, EditTemplatePayload } from './types';
// import { MessageTemplatesMessages } from '@api/apiResponseMessages';

export const fetchTemplates = createAsyncThunk(
  'messagesTemplates/fetchTemplates',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/message-templates');

      return data;
    } catch (error) {
      // if (!error.response?.data?.errorCode) {
      //   return rejectWithValue(error.response?.data);
      // }
      // return rejectWithValue(
      //   MessageTemplatesMessages[error.response?.data?.errorCode] || MessageTemplatesMessages.MessageTemplateFetchFailed,
      // );
    }
  }
);

export const createTemplate = createAsyncThunk(
  'messagesTemplates/createTemplate',
  async (templatesPayload: CreateTemplatePayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.post('/message-templates', templatesPayload);

      if (data?.errors?.length) {
        return rejectWithValue(data?.errors?.join('\n'));
      }

      return data;
    } catch (error) {
      // if (!error.response?.data?.errorCode) {
      //   return rejectWithValue(error.response?.data);
      // }
      // return rejectWithValue(
      //   MessageTemplatesMessages[error.response?.data?.errorCode] || MessageTemplatesMessages.MessageTemplateCreateFailed,
      // );
    }
  }
);

export const updateTemplate = createAsyncThunk(
  'messagesTemplates/updateTemplate',
  async ({ id, ...rest }: EditTemplatePayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.put(`/message-templates/${id}`, rest);

      if (data?.errors?.length) {
        return rejectWithValue(data?.errors?.join('\n'));
      }

      return data;
    } catch (error) {
      // if (!error.response?.data?.errorCode) {
      //   return rejectWithValue(error.response?.data);
      // }
      // return rejectWithValue(
      // MessageTemplatesMessages[error.response?.data?.errorCode] || MessageTemplatesMessages.MessageTemplateUpdateFailed,
      // );
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  'messagesTemplates/deleteTemplate',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.delete(`/message-templates/${id}`);

      if (data?.errors?.length) {
        return rejectWithValue(data?.errors?.join('\n'));
      }

      return data;
    } catch (error) {
      // if (!error.response?.data?.errorCode) {
      //   return rejectWithValue(error.response?.data);
      // }
      // return rejectWithValue(
      //   MessageTemplatesMessages.MessageTemplateDeleteFailed,
      // );
    }
  }
);
