import { phoneNumberRegexp } from '@utils';

export const getRawNumber = (value: string) => {
  return value.replace(/\D/g, '');
};

export const isValidPhoneNumber = (value: string) => {
  return phoneNumberRegexp?.test(value);
};

const validPhoneNumber = (value?: string) => {
  if (!value) return undefined;
  const phoneNumber = getRawNumber(value);
  if (isValidPhoneNumber(phoneNumber)) {
    return phoneNumber;
  }
  return undefined;
};

export const removeUSCountryCode = (value?: string) => {
  if (!value) return undefined;
  const phoneNumber = getRawNumber(value);
  if (isValidPhoneNumber(phoneNumber)) {
    return phoneNumber.slice(1);
  }
  return undefined;
};

const addUSCountryCode = (value: string) => `+1${value}`;

export const formatToInternational = (value?: string) => {
  const phoneNumber = validPhoneNumber(value);
  if (phoneNumber) {
    return addUSCountryCode(phoneNumber);
  }
  return undefined;
};

export const formatPhoneNumberToLocalUS = (value: string) => {
  const withoutCode = removeUSCountryCode(value) || '';
  const positions = withoutCode.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  if (!withoutCode || !value) {
    return value;
  }

  if (positions) {
    return !positions[2]
      ? positions[1]
      : '(' + positions[1] + ') ' + positions[2] + (positions[3] ? '-' + positions[3] : '');
  }

  return value;
};
