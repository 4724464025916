import { ChannelListTypes } from '@commonTypes/channel';

export const channelTypesOptions = [
  {
    value: ChannelListTypes.my,
    label: 'My channels',
  },
  {
    value: ChannelListTypes.all,
    label: 'All channels',
  },
  {
    value: ChannelListTypes.new,
    label: 'New channels',
  },
];
