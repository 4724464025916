import React from 'react';
import { NavLayout } from '@components/Navigation';
import { Loader, RoundButton, Text } from '@components/Common';
import { MenuIcon, PenIcon } from '@components/Common/Icons';
import { MessageArea } from '@components/Conversation';
import { ChannelTypes } from '@commonTypes/channel';
import { LoadingTypes } from '@commonTypes/common';
import { SingleRecipientSearch } from '@components/NewConversation';
import useNewConversation from './useNewConversation';
import styles from './NewConversation.module.scss';

const NewConversation = () => {
  const {
    sendLoading,
    phoneNumberValue,
    recipients,
    recipientsLoading,
    isPhoneNumberValid,
    onRedirectToChannels,
    onSearchRecipients,
    onRecipientSelected,
    onFilePreview,
    onMessageSend,
  } = useNewConversation();

  return (
    <NavLayout
      className={styles.container}
      backButton={<RoundButton variant="text" icon={<MenuIcon />} onClick={onRedirectToChannels} />}
    >
      <div className={styles.header}>
        <SingleRecipientSearch
          isPhoneNumberValid={isPhoneNumberValid}
          loading={recipientsLoading}
          phoneNumberValue={phoneNumberValue}
          recipients={recipients}
          onRecipientSelect={(recipient) => onRecipientSelected(recipient)}
          onSearch={onSearchRecipients}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.messageHint}>
          {sendLoading === LoadingTypes.pending ? (
            <>
              <Loader visible background="none" />
              <Text>
                New message is sending to <strong>{phoneNumberValue}</strong>
              </Text>
            </>
          ) : (
            <>
              <PenIcon />
              <Text>
                <strong>Nothing here yet.</strong> Start typing a message below and press the send
                button.
              </Text>
            </>
          )}
        </div>
      </div>

      <MessageArea
        controls={['emoji', 'template', 'send-button', 'sign', 'file-uploading']}
        currentChannelType={ChannelTypes.fieldAgentPhone}
        initialRows={4}
        isAreaDisabled={!isPhoneNumberValid || sendLoading === LoadingTypes.pending}
        placeholder={'Send a message'}
        onFilePreview={onFilePreview}
        onMessageSend={onMessageSend}
      />
    </NavLayout>
  );
};

export default NewConversation;
