import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ROUTES from '@constants/routes';
import ConversationPage from '@pages/Conversation';
import ChannelsPage from '@pages/Channels';
import SignInPage from '@pages/SignIn';
import SubscribersPage from '@pages/Subscribers';
import NewConversation from '@pages/NewConversation';

const NavigationProvider = () => {
  return (
    <Routes>
      <Route element={<SignInPage />} path={ROUTES.signIn} />
      <Route element={<ConversationPage />} path={ROUTES.conversation} />
      <Route element={<ChannelsPage />} path={ROUTES.channels} />
      <Route element={<SubscribersPage />} path={ROUTES.subscribers} />
      <Route element={<NewConversation />} path={ROUTES.newConversations} />

      <Route element={<Navigate replace to={ROUTES.channels} />} path="/" />
      <Route element={<Navigate replace to={ROUTES.channels} />} path="*" />
    </Routes>
  );
};

export default NavigationProvider;
