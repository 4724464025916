import React from 'react';
import classNames from 'classnames';
import { ConversationToolbarProps } from './ConversationToolbar.types';
import { Button } from '@components/Common';
import styles from './ConversationToolbar.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@state/types';
import useConversationToolbar from './useConversationToolbar';
import { PencilAlternateIcon, SearchIcon } from '@components/Common/Icons';

const ConversationToolbar = (props: ConversationToolbarProps) => {
  const {
    filteredSubscribers,
    isCurrentUserSubscriber,
    getSubscribersPlaceholder,
    addCurrentUserToSubscriber,
    deleteCurrentUserFromSubscribers,
  } = useConversationToolbar();

  return (
    <div className={classNames(styles.container, props.className)}>
      <div className={styles.controls}>
        {isCurrentUserSubscriber ? (
          <Button variant="contained" onClick={() => deleteCurrentUserFromSubscribers()}>
            Unsubscribe
          </Button>
        ) : (
          <Button variant="outlined" onClick={() => addCurrentUserToSubscriber()}>
            Subscribe
          </Button>
        )}

        <Button
          className={styles.subscribersButton}
          position="right"
          variant="text"
          onClick={props.onSubscribersButtonClick}
        >
          {!!filteredSubscribers?.length && <strong>{filteredSubscribers?.length}</strong>}
          {getSubscribersPlaceholder()}
        </Button>
      </div>

      <div className={styles.controls}>
        <Button
          icon={<PencilAlternateIcon />}
          variant="outlined"
          onClick={props.onEditButtonClick}
        />

        <Button icon={<SearchIcon />} variant="outlined" />
      </div>
    </div>
  );
};

export default ConversationToolbar;
