import React from 'react';
import classNames from 'classnames';
import styles from './MenuList.module.scss';
import { MenuProps } from './MenuList.types';

const MenuList = ({ children, className }: MenuProps) => {
  return <div className={classNames(styles.container, className)}>{children}</div>;
};

export default MenuList;
