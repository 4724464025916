import { useState, KeyboardEvent, useRef, useEffect, useImperativeHandle, MouseEvent } from 'react';
import { MessageAreaProps, MessageAreaRef, MessageAreaValues } from './MessageArea.types';
import { useFilesUploading } from '@hooks';
import { MessageTemplate } from '@commonTypes/message';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import templatesState from '@state/messagesTemplates';
import personalSettingsState from '@state/personalSettings';
import { LoadingTypes } from '@commonTypes/common';
import { getAppSettings } from '@utils/appSettings';
import { AppSettings, GlobalSetting, SettingNameMap } from '@commonTypes/settings';
import { addToast } from '@providers';
import { getChannelAllowedFiles, getChannelMaxFilesSize } from '@utils';

const useMessageArea = (
  {
    onMessageSend,
    initialValues,
    onValuesChange,
    onTemplateChange,
    currentChannelType,
    ...rest
  }: MessageAreaProps,
  ref: MessageAreaRef
) => {
  const dispatch = useDispatch<AppDispatch>();
  const { data: messagesTemplates, loading: messagesTemplatesLoading } = useSelector(
    (state: RootState) => state.messagesTemplates
  );
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [textValue, setTextValue] = useState<string>(initialValues?.textValue || '');
  const [isAIMessagesModalOpen, setIsAIMessagesModalOpen] = useState<boolean>(false);
  const [filesRules, setFilesRules] = useState<{
    maxSize: number | undefined;
    accepted: string[] | undefined;
  }>();

  const { data: globalSettings } = useSelector((state: RootState) => state.globalSettings);
  const { data: personalSettings } = useSelector((state: RootState) => state.personalSettings);
  const { filesLimits } = useSelector((state: RootState) => state.settings);
  const [appSettings, setAppSettings] = useState<AppSettings>(
    getAppSettings(globalSettings, personalSettings)
  );

  const isSendOnEnter = appSettings[SettingNameMap.sendOnEnter];
  const isMessageSigned = appSettings[SettingNameMap.useSignature];

  const {
    files: attachments,
    setFiles,
    filesLoading,
    uploadFiles,
  } = useFilesUploading(filesRules?.maxSize, filesRules?.accepted, initialValues?.attachments);

  const removeAttachment = (fileId: number) => {
    const filtered = attachments.filter((file) => file.id !== fileId);
    setFiles(filtered);
  };

  const onSendClick = () => {
    const isValid = textValue?.length || attachments?.length;

    if (isValid) {
      onMessageSend?.({
        textValue,
        attachments,
        isSigned: isMessageSigned,
      });

      setFiles([]);
      setTextValue('');
    }
  };

  const onSendAIMessageClicked = (values: MessageAreaValues) => {
    setIsAIMessagesModalOpen(false);

    onMessageSend?.({
      textValue: values.textValue,
      attachments: values.attachments,
      isSigned: isMessageSigned,
    });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' && (!isSendOnEnter || event.ctrlKey || event.metaKey)) {
      event.preventDefault();

      const { current } = textAreaRef;

      if (current) {
        const { selectionStart } = current;

        setTextValue(
          (prevText) => `${prevText.slice(0, selectionStart)}\n${prevText.slice(selectionStart)}`
        );

        setTimeout(() => {
          current.setSelectionRange(selectionStart + 1, selectionStart + 1);
        }, 0);
      }
    } else if (event.key === 'Enter' && isSendOnEnter) {
      event.preventDefault();

      onSendClick();
    }
  };

  const onMessageAreaClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    textAreaRef?.current?.focus();
    rest.onClick?.(event);
  };

  const onTemplateSelect = (template: MessageTemplate) => {
    setTextValue(template.body || '');
    onTemplateChange?.(template);
  };

  const onMessageSign = async (value: boolean) => {
    try {
      const settingId = globalSettings.find(
        (personalSetting: GlobalSetting) => personalSetting.name === SettingNameMap.useSignature
      )?.id;

      if (settingId) {
        await dispatch(
          personalSettingsState.actions.updatePersonalSettings([
            { id: settingId, value: value ? 'true' : 'false' },
          ])
        ).unwrap();
      }
    } catch (error) {
      addToast({
        type: 'error',
        message: error as string,
      });
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [textValue]);

  useEffect(() => {
    onValuesChange?.({
      textValue,
      attachments,
      isSigned: isMessageSigned,
    });
  }, [textValue, attachments]);

  useEffect(() => {
    if (initialValues && initialValues.textValue !== textValue) {
      setTextValue(initialValues.textValue);
    }
  }, [initialValues]);

  useEffect(() => {
    setAppSettings(getAppSettings(globalSettings, personalSettings));
  }, [globalSettings, personalSettings]);

  useEffect(() => {
    if (!messagesTemplates?.length && messagesTemplatesLoading !== LoadingTypes.pending) {
      dispatch(templatesState.actions.fetchTemplates());
    }
  }, []);

  useEffect(() => {
    if (filesLimits.data?.length) {
      setFilesRules({
        maxSize: getChannelMaxFilesSize(currentChannelType, filesLimits.data),
        accepted: getChannelAllowedFiles(currentChannelType, filesLimits.data),
      });
    }
  }, [filesLimits]);

  useImperativeHandle(ref, () => ({
    values: {
      textValue,
      attachments,
      isSigned: isMessageSigned,
    },
    resetValues: () => {
      setFiles([]);
      setTextValue('');
    },
  }));

  return {
    isMessageSigned,
    isSendOnEnter,
    textValue,
    attachments,
    setTextValue,
    filesRules,
    textAreaRef,
    filesLoading,
    messagesTemplates,
    isAIMessagesModalOpen,
    setIsAIMessagesModalOpen,
    currentChannelType,
    onMessageAreaClick,
    uploadFiles,
    removeAttachment,
    handleKeyPress,
    onSendClick,
    onSendAIMessageClicked,
    onMessageSign,
    onTemplateSelect,
    ...rest,
  };
};

export default useMessageArea;
