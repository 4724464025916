import React from 'react';
import { NavLayout } from '@components/Navigation';
import { ChannelsFilter, ChannelsHeader, ChannelsList } from '@components/Channels';
import { channelTypesOptions } from './Channels.data';
import { StarredChannelEntries } from '@components/Conversation';
import useChannels from './useChannels';
import styles from './Channels.module.scss';

const Channels = () => {
  const {
    isStarredEntriesOpen,
    setIsStarredEntriesOpen,
    channelsSearchValue,
    setChannelsSearchValue,
    selectedChannelFilter,
    setSelectedChannelFilter,
    onRedirectToNewConversation,
  } = useChannels();

  return (
    <NavLayout className={styles.container}>
      <ChannelsHeader onNewChatClick={onRedirectToNewConversation} />

      <ChannelsFilter
        options={channelTypesOptions}
        searchValue={channelsSearchValue}
        selectedFilter={selectedChannelFilter}
        onSearch={setChannelsSearchValue}
        onFilterSelected={setSelectedChannelFilter}
        isStarrButtonActive={isStarredEntriesOpen}
        onStarrButtonClicked={() => setIsStarredEntriesOpen(!isStarredEntriesOpen)}
      />

      {isStarredEntriesOpen ? (
        <div className={styles.starredMessagesContainer}>
          <StarredChannelEntries />
        </div>
      ) : (
        <ChannelsList
          searchValue={channelsSearchValue}
          className={styles.channelContainer}
          channelsType={selectedChannelFilter}
        />
      )}
    </NavLayout>
  );
};

export default Channels;
