import { createSlice } from '@reduxjs/toolkit';
import { fetchNote, postNewNote, updateNote } from './thunks';
import { LoadingTypes } from '@commonTypes/common';
import { NotesState } from './types';

export const initialState: NotesState = {};

export const settingsSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNote.pending, (state, { meta }) => {
      const userId = meta.arg.userId;

      state[userId] = {
        loading: LoadingTypes.pending,
      };
    });

    builder.addCase(fetchNote.fulfilled, (state, { meta, payload }) => {
      const userId = meta.arg.userId;

      state[userId] = {
        data: payload,
        loading: LoadingTypes.fulfilled,
      };
    });

    builder.addCase(fetchNote.rejected, (state, { meta }) => {
      const userId = meta.arg.userId;

      state[userId] = {
        loading: LoadingTypes.rejected,
      };
    });

    builder.addCase(postNewNote.pending, (state, { meta }) => {
      const userId = meta.arg.userId;

      state[userId] = {
        loading: LoadingTypes.pending,
      };
    });

    builder.addCase(postNewNote.fulfilled, (state, { meta, payload }) => {
      const userId = meta.arg.userId;

      state[userId] = {
        data: payload,
        loading: LoadingTypes.fulfilled,
      };
    });

    builder.addCase(updateNote.fulfilled, (state, { meta }) => {
      const userId = meta.arg.userId;
      const prevData = state[userId].data;
      const newValue = meta.arg.value;

      if (prevData?.value) {
        prevData.value = newValue;
      }
    });

    builder.addCase(postNewNote.rejected, (state, { meta }) => {
      const userId = meta.arg.userId;

      state[userId] = {
        loading: LoadingTypes.rejected,
      };
    });
  },
});

export const actions = settingsSlice.actions;
export default settingsSlice.reducer;
