import React, { ChangeEvent, forwardRef } from 'react';
import classNames from 'classnames';
import { MessageAreaProps, MessageAreaRefValues } from './MessageArea.types';
import MessageAreaControls from './MessageAreaControls';
import useMessageArea from './useMessageArea';
import { InfoIcon, SendEmailIcon, CalendarIcon } from '@components/Common/Icons';
import { LoadingTypes } from '@commonTypes/common';
import { FileUploadingPreview, RoundButton, Text } from '@components/Common';
import { Attachment } from '@commonTypes/attachment';
import styles from './MessageArea.module.scss';

const MessageArea = forwardRef<MessageAreaRefValues, MessageAreaProps>((props, forwardedRef) => {
  const {
    hint,
    error,
    label,
    textValue,
    initialRows = 1,
    placeholder = 'Send a message',
    attachments,
    filesLoading,
    messagesTemplates,
    filesRules,
    currentChannelType,
    isAIMessagesModalOpen,
    setIsAIMessagesModalOpen,
    children,
    setTextValue,
    onTemplateSelect,
    textAreaRef,
    controls,
    isAreaDisabled,
    onFilePreview,
    isMessageSigned,
    isSendOnEnter,
    onMessageSign,
    onMessageAreaClick,
    onSendClick,
    handleKeyPress,
    removeAttachment,
    uploadFiles,
    onSendAIMessageClicked,
    onScheduleButtonClicked,
    ...rest
  } = useMessageArea(props, forwardedRef);

  return (
    <div
      {...rest}
      className={classNames(styles.container)}
      onClick={onMessageAreaClick}
      onKeyDown={handleKeyPress}
    >
      {label && <div className={styles.label}>{label}</div>}

      <div
        className={classNames(styles.messageAreaContainer, {
          [styles.error]: error,
        })}
      >
        <div className={styles.messageArea}>
          <textarea
            className={styles.textField}
            placeholder={`${placeholder} ${
              isSendOnEnter ? '(Ctrl/Cmd + Enter to add new line)' : ''
            }`}
            ref={textAreaRef}
            rows={initialRows}
            value={textValue}
            onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) => {
              setTextValue(target.value);
            }}
            onPaste={(event: React.ClipboardEvent<HTMLTextAreaElement>) => {
              const files = event.clipboardData?.files;

              if (files?.length) {
                event.preventDefault();

                uploadFiles(files, currentChannelType);
              }
            }}
          />

          {!!attachments?.length && (
            <div className={styles.filesContainer}>
              {attachments.map((file: Attachment) => (
                <FileUploadingPreview
                  ext={file.ext}
                  fileName={file.fileName}
                  key={file.id}
                  loading={file.loading}
                  mimeType={file.mimeType}
                  srcLarge={file.paths.thumb}
                  srcSmall={file.paths.preview}
                  type={file.type}
                  onClick={() => onFilePreview?.(file)}
                  onRemove={() => removeAttachment(file.id)}
                />
              ))}
            </div>
          )}

          <div className={styles.downBarContainer}>
            <MessageAreaControls
              filesAccept={filesRules?.accepted}
              isMessageSigned={isMessageSigned}
              templates={messagesTemplates}
              withAISuggestionControl={controls?.includes('ai-suggestions')}
              withEmojiControl={controls?.includes('emoji')}
              withFileUploadingControl={controls?.includes('file-uploading')}
              withSignControl={controls?.includes('sign')}
              withTemplateControl={controls?.includes('template')}
              onAISuggestionClicked={() => setIsAIMessagesModalOpen(true)}
              onEmojiSelect={(emoji) => setTextValue(`${textValue} ${emoji}`)}
              onFileSelect={(files) => uploadFiles(files, currentChannelType)}
              onTemplateSelect={onTemplateSelect}
              onMessageSign={onMessageSign}
            />

            <div className={styles.leftControls}>
              {hint && (
                <div className={styles.hint}>
                  <InfoIcon />
                  <Text>{hint}</Text>
                </div>
              )}
            </div>

            <div className={styles.rightControls}>
              {controls?.includes('schedule-button') && (
                <RoundButton
                  disabled={
                    isAreaDisabled ||
                    (!textValue?.length && !attachments?.length) ||
                    filesLoading === LoadingTypes.pending
                  }
                  icon={<CalendarIcon />}
                  variant="outlined"
                  onClick={() => onScheduleButtonClicked?.()}
                />
              )}

              {controls?.includes('send-button') && (
                <RoundButton
                  color="success"
                  size="large"
                  disabled={
                    isAreaDisabled ||
                    (!textValue?.length && !attachments?.length) ||
                    filesLoading === LoadingTypes.pending
                  }
                  icon={<SendEmailIcon />}
                  variant="contained"
                  onClick={onSendClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

MessageArea.displayName = 'MessageArea';
export default MessageArea;
