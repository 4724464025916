import { useState } from 'react';

const useChannelsFilter = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return {
    setIsSearchOpen,
    isSearchOpen,
  };
};

export default useChannelsFilter;
