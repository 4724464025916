import { createSlice } from '@reduxjs/toolkit';
import { ToastProps } from '@components/Common/Toast/Toast.types';
import { ToastsStateProps } from './types';

export const initialState: ToastsStateProps = {
  toastsList: [],
};

export const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    addToastMessage: (state, action) => {
      state.toastsList.push(action.payload);
    },
    removeToastMessage: (state, action) => {
      state.toastsList = state.toastsList.filter((toast) => toast.id !== action.payload);
    },
  },
});

export const actions = toastsSlice.actions;
export default toastsSlice.reducer;
