import { createSlice } from '@reduxjs/toolkit';
import { LoadingTypes } from '@commonTypes/common';
import { CurrentUserState } from './types';
import { fetchUser } from '@state/currentUser/thunks';

export const initialState: CurrentUserState = {
  profile: {
    loading: LoadingTypes.idle,
    data: undefined,
  },
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.profile.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchUser.rejected, (state) => {
      state.profile.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.profile.loading = LoadingTypes.fulfilled;
      state.profile.data = action.payload;
    });
  },
});

export const actions = profileSlice.actions;
export default profileSlice.reducer;
