import { createSlice } from '@reduxjs/toolkit';
import { LoadingTypes } from '@commonTypes/common';
import { postChannelsSubscribers, getChannelsSubscribers } from './thunks';
import { ChannelsSubscribersState } from './types';

export const initialState: ChannelsSubscribersState = {
  data: {},
};

export const subscribersSlice = createSlice({
  name: 'channelsSubscribers',
  initialState,
  reducers: {
    setSubscribersList: (state, { payload }) => {
      const channelId = payload.channelId;
      const prevData = state.data[channelId];

      if (prevData) {
        state.data[channelId] = {
          ...(prevData || {}),
          data: payload?.subscribers || [],
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChannelsSubscribers.pending, (state, { meta }) => {
      const channelId = meta.arg;
      const prevData = state.data[channelId] || {};

      state.data[channelId] = {
        ...prevData,
        loading: LoadingTypes.pending,
      };
    });

    builder.addCase(getChannelsSubscribers.rejected, (state, { meta }) => {
      const channelId = meta.arg;
      const prevData = state.data[channelId] || {};

      state.data[channelId] = {
        ...prevData,
        loading: LoadingTypes.rejected,
      };
    });

    builder.addCase(getChannelsSubscribers.fulfilled, (state, { meta, payload }) => {
      const channelId = meta.arg;
      const subscribers = payload || [];

      state.data[channelId] = {
        data: subscribers,
        loading: LoadingTypes.fulfilled,
      };
    });

    builder.addCase(postChannelsSubscribers.pending, (state, { meta }) => {
      const channelId = meta.arg.channelId;
      const prevData = state.data[channelId] || {};
      state.data[channelId] = {
        ...prevData,
        loading: LoadingTypes.pending,
      };
    });

    builder.addCase(postChannelsSubscribers.rejected, (state, { meta }) => {
      const channelId = meta.arg.channelId;
      const prevData = state.data[channelId] || {};
      state.data[channelId] = {
        ...prevData,
        loading: LoadingTypes.rejected,
      };
    });

    builder.addCase(postChannelsSubscribers.fulfilled, (state, { meta }) => {
      const channelId = meta.arg.channelId;
      const prevData = state.data[channelId] || {};

      state.data[channelId] = {
        ...prevData,
        loading: LoadingTypes.fulfilled,
      };
    });

    // builder.addCase(postCurrentUserToSubscribers.pending, (state) => {
    //   state.loading = LoadingTypes.pending;
    // });
    //
    // builder.addCase(postCurrentUserToSubscribers.rejected, (state) => {
    //   state.loading = LoadingTypes.rejected;
    // });
    //
    // builder.addCase(postCurrentUserToSubscribers.fulfilled, (state) => {
    //   state.loading = LoadingTypes.fulfilled;
    // });
  },
});

export const actions = subscribersSlice.actions;
export default subscribersSlice.reducer;
