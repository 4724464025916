import React, { ReactElement } from 'react';
import useNotificationsProvider from './useNotificationsProvider';
import NotificationsContext from './useNotificationsContext';

export interface NotificationsProviderProps {
  children: ReactElement;
}

const NotificationsProvider = ({ children }: NotificationsProviderProps): ReactElement => {
  const {
    notificationsActivated,
    notificationEnabledStatusLoading,
    enableNotifications,
    disableNotifications,
    removeInterest,
    addInterest,
  } = useNotificationsProvider();

  return (
    <NotificationsContext.Provider
      value={{
        notificationEnabledStatusLoading,
        notificationsActivated,
        enableNotifications,
        disableNotifications,
        removeInterest,
        addInterest,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
