import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Attachment } from '@commonTypes/attachment';
import { LoadingTypes } from '@commonTypes/common';
import { convertBytesToMb, generateImageStub } from '@utils';
import messagesState from '@state/messages';
import channelsFilesState from '@state/channelsFiles';
// import massMessagesState from '@state/massMessages';
import { AppDispatch } from '@state/types';
import { ChannelTypes } from '@commonTypes/channel';
import { addToast } from '@providers';
// import { FileUploadingMessages } from '@api/apiResponseMessages';

const useFilesUploading = (
  filesMaxSize?: number,
  filesAccept?: string[],
  initialFiles?: Attachment[]
) => {
  const dispatch = useDispatch<AppDispatch>();

  const [files, setFiles] = useState<Attachment[]>(initialFiles || []);
  const [filesLoading, setFilesLoading] = useState<LoadingTypes>(LoadingTypes.idle);

  const updateFulfilledFile = (modifiedFile: Attachment, id: number, loading: LoadingTypes) => {
    const file = { ...modifiedFile, loading: loading };

    setFiles((prevFiles) => prevFiles.map((localFile) => (localFile.id === id ? file : localFile)));
  };

  const removeRejectedFile = (file: Attachment) => {
    setFiles((prevFiles) => {
      return prevFiles.filter((localFile) => localFile.id !== file.id);
    });

    addToast({
      type: 'error',
      message: `Failed to upload ${file.fileName}`,
    });
  };

  const sendFile = async (uploadedFile: File, channelType: ChannelTypes) => {
    const formData = new FormData();
    formData.append('file', uploadedFile);

    if (channelType === ChannelTypes.massMessageChannel) {
      // return dispatch(massMessagesState.actions.postMassMessageFile({ file: formData })).unwrap();
    } else {
      formData.append('ChannelType', `${channelType}`);
      return dispatch(channelsFilesState.actions.postNewFile({ file: formData })).unwrap();
    }
  };

  const filterInvalidFiles = (uploadedFiles: FileList) => {
    const filesList = Array.from(uploadedFiles);

    const localFiles = filesList?.filter((file) => {
      const uploadedFileSize = convertBytesToMb(file.size);

      if (filesMaxSize && +uploadedFileSize > filesMaxSize) {
        addToast({
          type: 'error',
          message: `File ${file.name} is too big. Max file size is ${filesMaxSize} MB.`,
        });
      }

      if (filesAccept?.length && !filesAccept?.includes(file.type)) {
        addToast({
          type: 'error',
          message: `File ${file.name} is not supported..`,
        });
      }

      return file;
    });

    return localFiles;
    // return uploadedFiles;
  };

  const sendFileAndUpdateStatus = async (
    uploadingFile: File,
    localFile: Attachment,
    channelType: ChannelTypes
  ) => {
    const data = await sendFile(uploadingFile, channelType);

    if (data) {
      updateFulfilledFile(data, localFile.id, LoadingTypes.fulfilled);
    } else {
      removeRejectedFile(localFile);
    }
  };

  const uploadFiles = async (uploadedFiles: FileList, channelType: ChannelTypes) => {
    try {
      setFilesLoading(LoadingTypes.pending);

      const allFiles = filterInvalidFiles(uploadedFiles);
      const localFiles = allFiles.map((file) => generateImageStub(file));
      setFiles((prevFiles) => prevFiles.concat(localFiles));

      await Promise.allSettled(
        allFiles.map((attachment, index) => {
          return sendFileAndUpdateStatus(attachment, localFiles[index], channelType);
        })
      );

      setFilesLoading(LoadingTypes.fulfilled);
    } catch (error) {
      setFilesLoading(LoadingTypes.fulfilled);
    }
  };

  return {
    files,
    setFiles,
    filesLoading,
    uploadFiles,
  };
};

export default useFilesUploading;
