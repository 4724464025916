import React, { ChangeEvent, useState } from 'react';
import classNames from 'classnames';
import { CheckIcon } from '@components/Common/Icons';
import { CheckboxProps } from './Checkbox.types';
import styles from './Checkbox.module.scss';

const Checkbox = ({ label, onChange, checked, className, ...rest }: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!checked);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);

    onChange?.(event);
  };

  return (
    <div className={classNames(styles.container, className)}>
      <label>
        <input
          checked={isChecked}
          className={styles.hiddenCheckbox}
          type="checkbox"
          onChange={onChangeHandler}
          {...rest}
        />
        <div
          className={classNames(styles.styledCheckbox, {
            [styles.checked]: isChecked,
          })}
        >
          <CheckIcon />
        </div>

        {label && <span className={styles.label}>{label}</span>}
      </label>
    </div>
  );
};

export default Checkbox;
