import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import subscribersState from '@state/channelsSubscribers';
import currentUserSubscriptionsState from '@state/currentUserSubscriptions';
import activityState from '@state/activity';
import usersState from '@state/users';
import { useEffect, useState } from 'react';
import { User, UserShort } from '@commonTypes/user';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import { LoadingTypes, Option } from '@commonTypes/common';
import { useDebounce } from '@hooks';

const useSubscribers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const PAGE_SIZE = 20;

  const [selectedSubscribers, setSelectedSubscribers] = useState<User[]>([]);

  const { activeChannel } = useSelector((state: RootState) => state.activity.data || {});

  const { list: usersList, totalUsers } = useSelector((state: RootState) => state.users);

  const channelsSubscribersData = useSelector((state: RootState) =>
    activeChannel?.id ? state.channelSubscribers.data[activeChannel?.id] : undefined
  );

  const currentUserId = useSelector((state: RootState) => state.currentUser.profile?.data?.id);
  const [sortedSubscribers, setSortedSubscribers] = useState<UserShort[]>([]);
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const debouncedSearchValue = useDebounce(searchValue || '', 500);
  const [usersInitLoading, setUsersInitLoading] = useState<LoadingTypes>(LoadingTypes.idle);
  const [usersMoreLoading, setUsersMoreLoading] = useState<LoadingTypes>(LoadingTypes.idle);

  const onFetchInitialUsers = async () => {
    try {
      setUsersInitLoading(LoadingTypes.pending);

      await dispatch(
        usersState.actions.fetchUsers({
          skip: 0,
          take: PAGE_SIZE,
          query: debouncedSearchValue,
        })
      ).unwrap();

      setUsersInitLoading(LoadingTypes.fulfilled);
    } catch (error) {
      setUsersInitLoading(LoadingTypes.fulfilled);
    }
  };

  const onFetchMoreUsers = async () => {
    try {
      if (usersList.length < totalUsers) {
        setUsersMoreLoading(LoadingTypes.pending);

        await dispatch(
          usersState.actions.fetchUsers({
            take: PAGE_SIZE,
            skip: usersList.length,
            query: debouncedSearchValue,
          })
        ).unwrap();

        setUsersMoreLoading(LoadingTypes.fulfilled);
      }
    } catch (error) {
      setUsersMoreLoading(LoadingTypes.fulfilled);
    }
  };

  const onSelectChange = ({ value }: Option) => {
    const subscriber = availableUsers?.find((user) => user.id === value);

    if (subscriber) {
      setSelectedSubscribers((prevSubscribers) => prevSubscribers.concat(subscriber));
    }
  };

  const onSelectRemove = ({ value }: Option) => {
    const filteredFollowers =
      selectedSubscribers?.filter((subscriber) => subscriber.id !== value) || [];
    setSelectedSubscribers(filteredFollowers);
  };

  const onaAddChannelsSubscribers = async (noteValue: string) => {
    if (activeChannel && selectedSubscribers?.length) {
      const subscriberIds = selectedSubscribers.map(({ id }) => id);
      setSelectedSubscribers([]);

      dispatch(
        subscribersState.actions.postChannelsSubscribers({
          channelId: activeChannel.id,
          note: noteValue,
          subscriberIds,
        })
      );
    }
  };

  const onDeleteChannelsSubscriber = (subscriberId: number) => {
    if (!activeChannel) return;

    if (subscriberId === currentUserId) {
      dispatch(
        currentUserSubscriptionsState.actions.deleteCurrentUserFromSubscribers({
          channelId: activeChannel.id,
          subscriberId,
        })
      );
    } else {
      dispatch(
        subscribersState.actions.deleteChannelsSubscribers({
          channelId: activeChannel.id,
          subscriberId,
        })
      );
    }
  };

  const onFetchSubscribers = async () => {
    if (activeChannel) {
      return dispatch(subscribersState.actions.getChannelsSubscribers(activeChannel?.id)).unwrap();
    }
  };

  const onRedirectToChannelsList = () => {
    dispatch(activityState.actions.setActiveChannel(undefined));
    navigate(ROUTES.channels);
  };

  const onRedirectToConversation = () => {
    navigate(ROUTES.conversation, {
      state: {
        isDriverDetailsActive: true,
      },
    });
  };

  const filterAlreadySubscribedUsers = (allUsers: User[], subscribersList?: UserShort[]) => {
    if (allUsers?.length) {
      return allUsers.filter(
        (user) => !subscribersList?.some((subscribed) => subscribed.id === user.id)
      );
    }
    return [];
  };

  const sortSubscribersList = (subscribersList: UserShort[]): UserShort[] => {
    const sorted = [];
    const currentUser = subscribersList?.find((follower) => follower.id === currentUserId);
    const filteredSubscribers = subscribersList?.filter(
      (follower) => follower.id !== currentUserId
    );

    if (currentUser) {
      sorted.push(currentUser);
    }

    return [...sorted, ...filteredSubscribers];
  };

  useEffect(() => {
    if (activeChannel) {
      const subscribers = channelsSubscribersData?.data;
      if (subscribers) {
        const sorted: UserShort[] = sortSubscribersList(subscribers);
        setSortedSubscribers(sorted);
      }
    }
  }, [channelsSubscribersData?.data]);

  useEffect(() => {
    if (searchValue !== undefined) {
      onFetchInitialUsers();
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    const filteredUsers = filterAlreadySubscribedUsers(usersList, channelsSubscribersData?.data);
    const allUsersExceptCurrent = filteredUsers?.filter(
      (follower) => follower.id !== currentUserId
    );

    setAvailableUsers(allUsersExceptCurrent || []);
  }, [usersList, channelsSubscribersData?.data]);

  useEffect(() => {
    onFetchSubscribers();
    onFetchInitialUsers();
  }, []);

  return {
    searchValue,
    availableUsers,
    setAvailableUsers,
    usersInitLoading,
    usersMoreLoading,
    setSearchValue,
    selectedSubscribers,
    setSelectedSubscribers,
    sortedSubscribers,
    subscribersLoading: channelsSubscribersData?.loading,
    currentUserId,
    onSelectChange,
    onSelectRemove,
    onRedirectToChannelsList,
    onRedirectToConversation,
    onFetchSubscribers,
    onaAddChannelsSubscribers,
    onFetchInitialUsers,
    onFetchMoreUsers,
    onDeleteChannelsSubscriber,
  };
};

export default useSubscribers;
