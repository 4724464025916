import { LoadingTypes } from '@commonTypes/common';
import { createSlice } from '@reduxjs/toolkit';
import { SettingShort } from '@commonTypes/settings';
import { fetchGlobalSettings, updateGlobalSettings } from './thunks';
import { GlobalSettingsState } from './types';

export const initialState: GlobalSettingsState = {
  data: [],
  loading: LoadingTypes.idle,
};

export const globalSettingsSlice = createSlice({
  name: 'globalSettings',
  initialState,
  reducers: {
    setGlobalSettings: (state, { payload }) => {
      const newState = state.data.map((setting) => {
        const updatedSetting = payload.find(
          (newSetting: SettingShort) => newSetting.id === setting.id
        );
        return updatedSetting ? { ...setting, value: updatedSetting.value } : setting;
      });
      state.data = newState;
    },
    updateGlobalSettingsState: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalSettings.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchGlobalSettings.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchGlobalSettings.fulfilled, (state, { payload }) => {
      state.data = payload || [];
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(updateGlobalSettings.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(updateGlobalSettings.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(updateGlobalSettings.fulfilled, (state) => {
      state.loading = LoadingTypes.fulfilled;
    });
  },
});

export const actions = globalSettingsSlice.actions;
export default globalSettingsSlice.reducer;
