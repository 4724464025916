import React, { ReactElement, createContext } from 'react';
import { EventsProviderProps, PusherContextTypes } from './EventProvider.types';
import useEventProvider from './useEventProvider';

export const EventsContext = createContext<PusherContextTypes>(undefined);

const EventsProvider = ({ children }: EventsProviderProps): ReactElement => {
  const { channelRef } = useEventProvider();

  return <EventsContext.Provider value={channelRef?.current}>{children}</EventsContext.Provider>;
};

export default EventsProvider;
