import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { LoadingTypes } from '@commonTypes/common';
import { MessageText } from '@commonTypes/message';
import messagesState from '@state/messages';
import starredMessagesState from '@state/starredMessages';
import { FetchMessagesPayload } from '@state/messages/types';
import { isCurrentUserChannelSubscriber } from '@utils';
import { Attachment } from '@commonTypes/attachment';
import { FilePreviewContext } from '@providers/FilePreviewProvider';

const useChatDriver = () => {
  const PAGE_SIZE = 50;
  const dispatch = useDispatch<AppDispatch>();
  const filesModalContext = useContext(FilePreviewContext);
  const currentUser = useSelector((state: RootState) => state.currentUser.profile?.data);
  // const appInstanceId = useSelector((state: RootState) => state.myProfile?.appInstanceId) || '';

  const { activeChannel } = useSelector((state: RootState) => state.activity.data);

  const { data: messages, loading: messageLoading } =
    useSelector((state: RootState) =>
      activeChannel?.id ? state.messages.data?.[activeChannel.id] : undefined
    ) || {};

  const [messagesInitLoading, setMessagesInitLoading] = useState(LoadingTypes.idle);
  const messagesMoreLoading = messageLoading ? messageLoading : LoadingTypes.idle;

  const onMessageRetry = async (message: MessageText) => {
    // if (message.status === MessageSendStatuses.failed) {
    //   dispatch(messagesState.actions.postRetryExistingMessage({ message, conversationId: appInstanceId }));
    // } else {
    //   dispatch(messagesState.actions.postRetryLocalMessage({ message, conversationId: appInstanceId }));
    // }
  };

  const onMessageRemove = (messageId: number, channelId: number) => {
    // dispatch(messagesState.actions.removeMessage({
    //   messageId,
    //   channelId,
    // }));
  };

  const onMessageStar = (message: MessageText) => {
    dispatch(starredMessagesState.actions.postMessageStar({ message }));
  };

  const fetchMoreMessages = async (params: FetchMessagesPayload) => {
    return dispatch(messagesState.actions.fetchMessages(params)).unwrap();
  };

  const fetchInitialMessages = async (params: FetchMessagesPayload) => {
    if (messageLoading !== LoadingTypes.fulfilled && messageLoading !== LoadingTypes.pending) {
      setMessagesInitLoading(LoadingTypes.pending);

      await fetchMoreMessages(params);
      setMessagesInitLoading(LoadingTypes.fulfilled);
    }
  };

  const onFilePreview = (file: Attachment) => {
    if (file.mimeType.includes('image')) {
      filesModalContext?.openFileInModalWindow(file);
    }
  };

  useEffect(() => {
    if (activeChannel?.id && !messages?.length) {
      fetchInitialMessages({
        channelId: activeChannel.id,
        amount: PAGE_SIZE,
        beforeMessageId: undefined,
      });
    }
  }, [activeChannel?.id]);

  useEffect(() => {
    if (messageLoading === LoadingTypes.fulfilled && activeChannel?.unreadMessagesCount) {
      const isCurrentUserSubscriber = isCurrentUserChannelSubscriber(activeChannel.id);

      if (isCurrentUserSubscriber) {
        dispatch(
          messagesState.actions.readMessageAndUpdateCounter({
            departmentId: activeChannel.departmentId,
            messageId: activeChannel.lastEntry?.id,
            activeChannelId: activeChannel.id,
            unreadMessagesCount: activeChannel.unreadMessagesCount,
          })
        );
      }
    }
  }, [messageLoading]);

  return {
    PAGE_SIZE,
    currentUserId: currentUser?.id,
    messages,
    activeChannel,
    messagesMoreLoading,
    messagesInitLoading,
    onFilePreview,
    onMessageStar,
    onMessageRetry,
    onMessageRemove,
    fetchInitialMessages,
    fetchMoreMessages,
  };
};

export default useChatDriver;
