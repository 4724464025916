import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { Channel } from 'pusher-js';
import channelsState from '@state/channels';
import messagesState from '@state/messages';
import subscribersState from '@state/channelsSubscribers';
import departmentsState from '@state/departments';
import activityState from '@state/activity';
import mySubscriptionsState from '@state/currentUserSubscriptions';
import templatesState from '@state/messagesTemplates';
import { pusherClient } from '@api/pusherClient';
import {
  GlobalSettingsStateChangedPayload,
  ChannelCreatedEventPayload,
  MessageReceivedEventPayload,
  PusherEventTypes,
  SubscribersChangedEventPayload,
  PersonalSettingsStateChangedPayload,
  MessageTemplateDeletedEventPayload,
  MessageTemplateUpdatedEventPayload,
  MessageTemplateCreatedEventPayload,
  MassMessageReceivedPayload,
  MassChannelCreatedPayload,
} from './EventProvider.types';
import { isCurrentUserChannelSubscriber } from '@utils';
import filesState from '@state/channelsFiles';
import store from '@state/store';
import globalSettingsState from '@state/globalSettings';
import personalSettingsState from '@state/personalSettings';
import { ChannelListTypes } from '@commonTypes/channel';
import { User } from '@commonTypes/user';
import { useNotificationsContext } from '@providers/NotificationsProvider/useNotificationsContext';

const useEventProvider = () => {
  const channelRef = useRef<Channel>();
  const subscriptionChannelRef = useRef<Channel>();
  const messageTemplateChannelRef = useRef<Channel>();
  const globalSettingsChannelRef = useRef<Channel>();
  const personalSettingsChannelRef = useRef<Channel>();
  const massMessageChannelRef = useRef<Channel>();
  const tenantConfigChannelRef = useRef<Channel>();
  const dispatch = useDispatch<AppDispatch>();
  const { session } = useSelector((state: RootState) => state.auth?.data) || {};
  const tenantConfig = useSelector((state: RootState) => state.settings?.tenantConfig?.data);
  const tenantId = tenantConfig?.tenantId || process.env.REACT_APP_TENANT_ID;

  const { addInterest, removeInterest } = useNotificationsContext() || {};

  const messagesChannel = `private-encrypted-${tenantId}-ChannelEntry`;
  const subscribersChannel = `private-encrypted-${tenantId}-ChannelSubscribers`;
  const samsaraChannel = `private-encrypted-${tenantId}-SamsaraDriverState`;
  const scheduledMessagesChannel = `private-encrypted-${tenantId}-ScheduledMessage`;
  const messageTemplateChannel = `private-encrypted-${tenantId}-MessageTemplate`;
  const globalSettingsChannel = `private-encrypted-${tenantId}-AppSettings`;
  const personalSettingsChannel = `private-encrypted-${tenantId}-UserSettings`;
  const massMessageChannel = `private-encrypted-${tenantId}-MassMessage`;
  const permissionsChannel = `private-encrypted-${tenantId}-Permissions`;
  const tenantConfigChannel = `private-encrypted-${tenantId}-TenantConfiguration`;

  const onEventMessageReceived = async (messageData: MessageReceivedEventPayload) => {
    const state = store.getState();
    const activeChannel = state.activity.data.activeChannel;
    const appInstanceId = state.settings?.appInstanceId || '';
    const isCurrentUserSubscriber = isCurrentUserChannelSubscriber(messageData?.message?.channelId);

    const areChannelsEqual = activeChannel?.id === messageData?.message.channelId;
    const areUserEqual = messageData?.message?.sender?.id === session?.userId;
    const areSessionsEqual = appInstanceId === messageData?.conversationId;

    // Push message to local store for other devices
    if (!areSessionsEqual) {
      dispatch(messagesState.actions.addMessage(messageData.message));
      if (messageData?.message?.files) {
        dispatch(
          filesState.actions.addFiles({
            channelId: messageData.message.channelId,
            data: messageData?.message?.files,
          })
        );
      }
      dispatch(channelsState.actions.modifyChannelsByLastEntry(messageData.message));
    }

    // Read message if current channel is the same with arrived message's channelId
    if (areChannelsEqual && isCurrentUserSubscriber && !areUserEqual) {
      dispatch(
        messagesState.actions.postMessageRead({
          messageId: messageData?.message.id,
        })
      );
    }

    // Increase unread messages counter if active channel not equal message's channelId and current user is not the owner of this message
    if (!areChannelsEqual && !areUserEqual && isCurrentUserSubscriber) {
      dispatch(
        departmentsState.actions.increaseDepartmentUnreadCount({
          value: 1,
          departmentId: messageData?.message.departmentId,
        })
      );
      dispatch(
        channelsState.actions.increaseChannelsMessagesCounter({
          channelId: messageData?.message?.channelId,
          value: 1,
        })
      );

      dispatch(
        activityState.actions.increaseActiveChannelMessagesCounter({
          channelId: messageData?.message?.channelId,
          value: 1,
        })
      );

      dispatch(
        activityState.actions.increaseActiveDepartmentMessagesCounter({
          departmentId: messageData?.message?.departmentId,
          value: 1,
        })
      );
    }
  };

  const onEventChannelCreated = async (eventData: ChannelCreatedEventPayload) => {
    try {
      const state = store.getState();
      const activity = state.activity.data;
      const activeDepartmentChannel = eventData?.find(
        (channel) => channel.departmentId === activity.activeDepartment?.id
      );

      if (!activeDepartmentChannel) return;

      const channelsSubscribers = await dispatch(
        subscribersState.actions.getChannelsSubscribers(activeDepartmentChannel.id)
      ).unwrap();
      const isCurrentUserSubscriber = channelsSubscribers?.some(
        (subscriber: User) => subscriber.id === session?.userId
      );
      const channelWithUnverifiedDriver = activeDepartmentChannel.fieldAgent?.isVerified === false;

      if (
        (activity.activeChannelsFilter === ChannelListTypes.new && channelWithUnverifiedDriver) ||
        (activity.activeChannelsFilter === ChannelListTypes.my && isCurrentUserSubscriber) ||
        activity.activeChannelsFilter === ChannelListTypes.all
      ) {
        dispatch(channelsState.actions.addChannelIfNotExist(activeDepartmentChannel));
      }

      if (isCurrentUserSubscriber) {
        dispatch(
          mySubscriptionsState.actions.subscribeCurrentUser({
            channelId: activeDepartmentChannel.id,
            departmentId: activeDepartmentChannel.departmentId,
          })
        );

        addInterest?.(activeDepartmentChannel.id);
      }
    } catch (error) {
      console.log('[Pusher]: failed to handle new channel event');
    }
  };

  const onChannelSubscribersChanged = (eventData: SubscribersChangedEventPayload) => {
    const isCurrentUserSubscribes = eventData.subscribers?.find(
      (subscriber) => subscriber.id === session?.userId
    );

    const payload = {
      channelId: eventData.channelId,
      departmentId: eventData.departmentId,
    };

    if (isCurrentUserSubscribes) {
      dispatch(mySubscriptionsState.actions.subscribeCurrentUser(payload));
      addInterest?.(payload.channelId);
    } else {
      dispatch(mySubscriptionsState.actions.unsubscribeCurrentUser(payload));
      removeInterest?.(payload.channelId);
    }

    dispatch(
      subscribersState.actions.setSubscribersList({
        channelId: eventData.channelId,
        subscribers: eventData.subscribers,
      })
    );
  };

  const onGlobalSettingsStateChanged = (eventData: GlobalSettingsStateChangedPayload[]) => {
    dispatch(globalSettingsState.actions.setGlobalSettings(eventData));
    dispatch(personalSettingsState.actions.updateDefaultValue(eventData));
  };

  const onPersonalSettingsStateChanged = (eventData: PersonalSettingsStateChangedPayload) => {
    if (eventData?.userId === session?.userId) {
      dispatch(personalSettingsState.actions.setPersonalSettings(eventData?.items));
    }
  };

  const onMessageTemplateCreated = (eventData: MessageTemplateCreatedEventPayload) => {
    dispatch(templatesState.actions.addTemplateIfNotExist(eventData));
  };

  const onMessageTemplateUpdated = (eventData: MessageTemplateUpdatedEventPayload) => {
    dispatch(templatesState.actions.modifyTemplate(eventData));
  };

  const onMessageTemplateDeleted = (eventData: MessageTemplateDeletedEventPayload) => {
    dispatch(templatesState.actions.removeTemplate(eventData.messageTemplateId));
  };

  const onMassChannelCreated = (eventData: MassChannelCreatedPayload) => {
    const state = store.getState();
    const activity = state.activity.data;

    if (
      activity.activeChannelsFilter === ChannelListTypes.mass &&
      activity.activeDepartment?.id === eventData.departmentId
    ) {
      dispatch(channelsState.actions.addChannelIfNotExist(eventData));
    }
  };

  const onMassMessageReceived = (eventData: MassMessageReceivedPayload) => {
    const state = store.getState();
    const appInstanceId = state.settings?.appInstanceId;

    if (appInstanceId !== eventData.conversationId) {
      // dispatch(massMessagesState.actions.addMassMessage(eventData.massMessage));
    }
  };

  const onTenantConfigChanged = () => {
    dispatch({ type: 'auth/reset-keep-logged-in' });
    window.location.reload();
  };

  useEffect(() => {
    if (session?.userId && pusherClient) {
      if (!channelRef.current) {
        channelRef.current = pusherClient.subscribe(messagesChannel);
        channelRef.current?.bind(PusherEventTypes.messageReceived, onEventMessageReceived);
        channelRef.current?.bind(PusherEventTypes.channelCreated, onEventChannelCreated);
      }

      if (!subscriptionChannelRef.current) {
        subscriptionChannelRef.current = pusherClient.subscribe(subscribersChannel);
        subscriptionChannelRef.current?.bind(
          PusherEventTypes.channelSubscribersChanged,
          onChannelSubscribersChanged
        );
      }

      if (!globalSettingsChannelRef.current) {
        globalSettingsChannelRef.current = pusherClient.subscribe(globalSettingsChannel);
        globalSettingsChannelRef.current?.bind(
          PusherEventTypes.globalSettingsStateChanged,
          onGlobalSettingsStateChanged
        );
      }

      if (!personalSettingsChannelRef.current) {
        personalSettingsChannelRef.current = pusherClient.subscribe(personalSettingsChannel);
        personalSettingsChannelRef.current?.bind(
          PusherEventTypes.personalSettingsStateChanged,
          onPersonalSettingsStateChanged
        );
      }

      if (!messageTemplateChannelRef.current) {
        messageTemplateChannelRef.current = pusherClient.subscribe(messageTemplateChannel);
        messageTemplateChannelRef.current?.bind(
          PusherEventTypes.messageTemplateCreated,
          onMessageTemplateCreated
        );
        messageTemplateChannelRef.current?.bind(
          PusherEventTypes.messageTemplateUpdated,
          onMessageTemplateUpdated
        );
        messageTemplateChannelRef.current?.bind(
          PusherEventTypes.messageTemplateDeleted,
          onMessageTemplateDeleted
        );
      }

      if (!massMessageChannelRef.current) {
        massMessageChannelRef.current = pusherClient.subscribe(massMessageChannel);

        massMessageChannelRef.current?.bind(
          PusherEventTypes.massMessageChannelCreated,
          onMassChannelCreated
        );
        massMessageChannelRef.current?.bind(
          PusherEventTypes.massMessageReceived,
          onMassMessageReceived
        );
      }

      if (!tenantConfigChannelRef.current) {
        tenantConfigChannelRef.current = pusherClient.subscribe(tenantConfigChannel);

        tenantConfigChannelRef.current?.bind(
          PusherEventTypes.tenantConfigurationChanged,
          onTenantConfigChanged
        );
      }
    }

    return () => {
      if (pusherClient) {
        pusherClient.unsubscribe(messagesChannel);
        pusherClient.unsubscribe(subscribersChannel);
        pusherClient.unsubscribe(samsaraChannel);
        pusherClient.unsubscribe(globalSettingsChannel);
        pusherClient.unsubscribe(personalSettingsChannel);
        pusherClient.unsubscribe(scheduledMessagesChannel);
        pusherClient.unsubscribe(messageTemplateChannel);
        pusherClient.unsubscribe(massMessageChannel);
        pusherClient.unsubscribe(permissionsChannel);
        pusherClient.unsubscribe(tenantConfigChannel);
      }
    };
  }, []);

  return {
    channelRef,
  };
};

export default useEventProvider;
