import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { SignInPayload } from '@state/auth/types';

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ username, password, tenant }: SignInPayload, { fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.post(
        '/sign-in',
        {
          username,
          password,
        },
        {
          params: {
            tenant,
          },
        }
      );

      return data;
    } catch (error) {
      return fulfillWithValue({});
    }
  }
);

export const signOut = createAsyncThunk('auth/signOut', async (_, { fulfillWithValue }) => {
  try {
    const { data } = await apiClient.instance.post('/sign-out');

    return data;
  } catch (error) {
    return fulfillWithValue({});
  }
});
