import React from 'react';
import classNames from 'classnames';
import styles from './Avatar.module.scss';
import { AvatarProps } from './Avatar.types';

const Avatar = ({ size, children, className, ...rest }: AvatarProps) => {
  const trimValue = (value: JSX.Element | string) => {
    if (typeof value === 'string' && value.length > 2) {
      return value.slice(0, 2).toUpperCase();
    }
    return value;
  };

  return (
    <div
      {...rest}
      className={classNames(styles.container, className, {
        [styles.small]: size === 'small',
        [styles.large]: size === 'large',
      })}
    >
      {trimValue(children)}
    </div>
  );
};

export default Avatar;
