import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { LoadingTypes } from '@commonTypes/common';
import settingsState from '@state/settings';
import { setupPusherClient } from '@api/pusherClient';
import { setupPusherBeamsClient } from '@api/beamsClient';
import { TenantConfigType } from '@providers/TenantProvider/TenantProvider.types';

const useTenantProvider = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { session } = useSelector((state: RootState) => state.auth?.data) || {};
  const tenantConfig = useSelector((state: RootState) => state.settings?.tenantConfig?.data);
  const [tenantConfigLoading, setTenantConfigLoading] = useState<LoadingTypes>(LoadingTypes.idle);

  const setupTenantClients = (config?: TenantConfigType) => {
    if (config && !config.fallbackToFrontEndEnvironmentVariables) {
      setupPusherClient(config.pusherAppKey, config.pusherAppCluster);
      setupPusherBeamsClient(config.pusherBeamsInstanceId);
    } else {
      setupPusherClient(null, null);
      setupPusherBeamsClient(null);
    }
  };

  const fetchTenantConfig = async () => {
    try {
      let config = tenantConfig;
      setTenantConfigLoading(LoadingTypes.pending);

      if (!config) {
        config = await dispatch(settingsState.actions.fetchTenantConfiguration()).unwrap();
      }

      setupTenantClients(config);
      setTenantConfigLoading(LoadingTypes.fulfilled);
    } catch (error) {
      setTenantConfigLoading(LoadingTypes.rejected);
    }
  };

  useEffect(() => {
    if (!session?.sessionId) return;

    if (process.env.REACT_APP_MULTITENANCY_SKIPPED === 'true') {
      setupTenantClients(undefined);
    }

    if (
      process.env.REACT_APP_MULTITENANCY_SKIPPED !== 'true' &&
      tenantConfigLoading === LoadingTypes.idle
    ) {
      fetchTenantConfig();
    }
  }, [session, dispatch]);

  useEffect(() => {
    if (tenantConfigLoading === LoadingTypes.rejected) {
      throw new Error('Failed to fetch tenant configuration.');
    }
  }, [tenantConfigLoading]);

  return {
    tenantConfigLoading,
  };
};

export default useTenantProvider;
