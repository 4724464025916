import { generateNumberId } from '@utils';
import {
  Identifier,
  UserAttribute,
  UserAttributesShort,
  UserRoles,
  UserShort,
} from '@commonTypes/user';
import store from '@state/store';
import { FormDataValuesType } from '@commonTypes/forms';

export const getUserInitials = (firstName = '', lastName = ''): string => {
  try {
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  } catch (error) {
    return 'NP';
  }
};

export const generateUserStub = (): UserShort => {
  return {
    id: generateNumberId(),
    type: UserRoles.handler,
    firstName: '',
    lastName: '',
    email: '',
    userName: '',
    phoneNumber: '',
    signature: '',
    isVerified: true,
    // lastActivityAt: new Date().toString(),
    createdAt: new Date().toString(),
    updatedAt: new Date().toString(),
  };
};

export const isCurrentUserChannelSubscriber = (channelId?: number) => {
  try {
    if (!channelId) return false;

    const state = store.getState();
    const subscriptions = state.currentUserSubscriptions.data;

    if (!subscriptions?.length) {
      return false;
    }

    return subscriptions?.some((subscription) => subscription.channelId === channelId);
  } catch (e) {
    return false;
  }
};

export const UNIQUE_DRIVERS_IDENTIFIERS_TEMPORARY = (channels?: Identifier[]): Identifier[] => {
  if (!channels?.length) {
    return [];
  }

  const result: Identifier[] = [];

  channels.forEach((channel) => {
    const isExist = result.find(
      (existingChannel) => existingChannel.identifier === channel.identifier
    );
    if (!isExist) {
      result.push(channel);
    }
  });

  return result;
};

export const extractModifiedUsersAttributes = (
  attributesValues: FormDataValuesType,
  initialAttributes?: UserAttribute[]
) => {
  try {
    const modifiedAttributes: UserAttributesShort[] = [];
    const attributesData = Object.entries(attributesValues);
    if (attributesData?.length) {
      attributesData.forEach(([modifiedAttrTitle, modifiedAttrValue]) => {
        const attribute = initialAttributes?.find((attr) => attr?.title === modifiedAttrTitle);

        if (attribute?.id) {
          modifiedAttributes.push({
            userAttributeId: attribute.id,
            value: modifiedAttrValue || attribute.value || attribute.defaultValue,
          });
        }
      });
    }

    return modifiedAttributes;
  } catch (error) {
    return [];
  }
};

export const sortUsersAttributes = (attributes?: UserAttribute[]) => {
  if (attributes?.length) {
    return [...attributes].sort((a, b) => {
      if (a?.sortOrder > b?.sortOrder) {
        return 1;
      }

      if (a?.sortOrder < b?.sortOrder) {
        return -1;
      }

      return 0;
    });
  }
  return [];
};
