import React from 'react';
import classNames from 'classnames';
import styles from './ConversationFiles.module.scss';
import { ConversationFilesProps } from './ConversationFiles.types';
import useConversationFiles from './useConversationFiles';
import { LoadingTypes } from '@commonTypes/common';
import { FilePreviewDetailed, InfiniteScroll, Loader } from '@components/Common';
import { downloadFile, getFileOwner, uploadingDate } from '@utils';
import { Attachment } from '@commonTypes/attachment';

const ConversationFiles = (props: ConversationFilesProps) => {
  const { files, initLoading, moreLoading, currentUserId, onFilePreview, onFetchMoreFiles } =
    useConversationFiles();

  return (
    <div className={classNames(styles.container, props.className)}>
      {files?.length && initLoading !== LoadingTypes.pending ? (
        <InfiniteScroll
          className={styles.filesContainer}
          dataLength={files.length}
          emptyMessage={'No files uploaded yet'}
          firstElementId={files[0]?.id}
          key="infinite-scroll-files"
          lastElementId={files[files.length - 1]?.id}
          loading={moreLoading}
          onLoadMore={onFetchMoreFiles}
        >
          {files.map((file: Attachment) => (
            <FilePreviewDetailed
              className={styles.filePreview}
              createdAt={uploadingDate(file.createdAt)}
              ext={file.ext}
              fileName={file.fileName}
              key={file.id}
              mimeType={file.mimeType}
              owner={getFileOwner(file, currentUserId)}
              srcLarge={file.paths.preview}
              srcSmall={file.paths.thumb}
              type={file.type}
              onFileDownload={() => downloadFile(file.paths.large, file.fileName)}
              onFilePreview={() => onFilePreview(file)}
            />
          ))}
        </InfiniteScroll>
      ) : null}

      <Loader fullSize visible={LoadingTypes.pending === initLoading} />
    </div>
  );
};

export default ConversationFiles;
