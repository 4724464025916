import React from 'react';
import classNames from 'classnames';
import { Loader, MessageLayout } from '@components/Common';
import { ChannelCard } from '../index';
import { ChannelsListProps } from './ChannelsList.types';
import { LoadingTypes } from '@commonTypes/common';
import { getLastEntryDate, getLastEntryText } from '@utils';
import useChannelsList from './useChannelsList';
import styles from './ChannelsList.module.scss';

const ChannelsList = (props: ChannelsListProps) => {
  const {
    activeChannel,
    channelsList,
    channelsLoading,
    sessionData,
    setActiveChannel,
    extractChannelName,
  } = useChannelsList(props);

  return (
    <div className={classNames(styles.container, props.className)}>
      {channelsLoading !== LoadingTypes.pending && channelsList.length
        ? channelsList.map((channel) => {
            return (
              <ChannelCard
                active={activeChannel?.id === channel.id}
                channelName={extractChannelName(channel)}
                channelType={channel.type}
                className={styles.channel}
                key={channel.id}
                messageDate={getLastEntryDate(channel?.lastEntry)}
                messageOwner={channel.lastEntry?.sender?.id === sessionData?.userId}
                messageText={getLastEntryText(channel?.lastEntry)}
                unreadCount={channel.unreadMessagesCount}
                onClick={() => setActiveChannel(channel)}
              />
            );
          })
        : null}

      {channelsLoading !== LoadingTypes.pending && !channelsList?.length ? (
        <MessageLayout message={'No channels found'} />
      ) : null}

      <Loader fullSize visible={channelsLoading === LoadingTypes.pending} />
    </div>
  );
};

export default ChannelsList;
