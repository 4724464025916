import React, { ChangeEvent, useState } from 'react';
import { SubscribersAreaProps } from './SubscribersAddArea.types';
import { RoundButton, TextArea } from '@components/Common';
import styles from './SubscribersAddArea.module.scss';

const SubscribersAddArea = (props: SubscribersAreaProps) => {
  const [noteValue, setNoteValue] = useState('');

  const onTextValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteValue(event.target.value);
  };

  return (
    <div className={styles.container}>
      <TextArea
        className={styles.textArea}
        label={'Add an additional note (optional)'}
        placeholder={'Add an additional note (optional)'}
        onChange={onTextValueChange}
      />

      <RoundButton
        className={styles.saveButton}
        color="success"
        size="small"
        variant="contained"
        onClick={() => props.onAddButtonClick(noteValue)}
      >
        Add subscribers
      </RoundButton>
    </div>
  );
};

export default SubscribersAddArea;
