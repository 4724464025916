import React from 'react';
import { SignInForm } from '@forms';
import useSignIn from './useSignIn';
import styles from './SignIn.module.scss';

const SignIn = () => {
  const { error, loading, onSignIn } = useSignIn();
  return (
    <div className={styles.container}>
      <SignInForm
        errorMessage={error}
        initialValues={{
          username: '',
          password: '',
          tenant: '',
        }}
        loading={loading}
        onSubmit={({ username, password, tenant }) => {
          onSignIn(username, password, tenant);
        }}
      />
    </div>
  );
};

export default SignIn;
