import apiClient from '@api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeleteChannelsSubscribers,
  PostChannelsSubscribers,
} from '@state/channelsSubscribers/types';

export const getChannelsSubscribers = createAsyncThunk(
  'channelsSubscribers/getChannelsSubscribers',
  async (channelId: number, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get(`/channels/${channelId}/subscribers`);

      return data;
    } catch (error) {
      return rejectWithValue([]);
    }
  }
);

export const postChannelsSubscribers = createAsyncThunk(
  'channelsSubscribers/postChannelSubscribers',
  async ({ channelId, subscriberIds, note }: PostChannelsSubscribers, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.post(`/channels/${channelId}/subscribers`, {
        subscriberIds,
        note,
      });

      return data.subscribers;
    } catch (error) {
      return rejectWithValue([]);
    }
  }
);

export const deleteChannelsSubscribers = createAsyncThunk(
  'channelsSubscribers/deleteChannelsSubscribers',
  async (
    { channelId, subscriberId }: DeleteChannelsSubscribers,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await apiClient.instance.delete(
        `/channels/${channelId}/subscribers/${subscriberId}`
      );

      if (response.status === 200) {
        return fulfillWithValue(subscriberId);
      }

      return rejectWithValue(null);
    } catch (error) {
      return rejectWithValue(null);
    }
  }
);
