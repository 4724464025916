import React, { useState } from 'react';
import { SelectProps } from './Select.types';
import { Dropdown, MenuItem, MenuList, TextField } from '@components/Common';
import { ArrowFilledDownIcon } from '@components/Common/Icons';
import { Option } from '@commonTypes/common';
import styles from './Select.module.scss';

const Select = (props: SelectProps) => {
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const onSelect = (option: Option) => {
    setIsSelectOpen(false);
    props.onSelectChange(option);
  };

  const getLabel = () => {
    return props.options?.find((opt) => opt.value === props.value)?.label || '';
  };

  return (
    <Dropdown
      open={isSelectOpen}
      containerClassName={styles.selectContainer}
      contentClassName={styles.contentContainer}
      onVisibilityChange={setIsSelectOpen}
      offsetVertical={props.dropdownVerticalOffset}
      content={
        <MenuList className={styles.menu}>
          {props.options.length ? (
            props.options?.map((option) => (
              <MenuItem
                active={option.value === props.value}
                key={option.value}
                onClick={(event) => {
                  event.stopPropagation();
                  onSelect(option);
                }}
              >
                {option.label}
              </MenuItem>
            ))
          ) : (
            <p className={styles.emptyMessage}>No options found</p>
          )}
        </MenuList>
      }
    >
      <TextField
        readOnly
        className={styles.selectField}
        disabled={props.disabled}
        iconRight={
          <span className={styles.arrowIcon}>
            <ArrowFilledDownIcon />
          </span>
        }
        inputSize="small"
        value={getLabel()}
      />
    </Dropdown>
  );
};

export default Select;
