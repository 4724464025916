import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import {
  DeleteCurrentUserFromSubscribers,
  PostCurrentUserToSubscribers,
} from '@state/currentUserSubscriptions/types';

export const fetchCurrentUserSubscriptions = createAsyncThunk(
  'users/fetchCurrentUserSubscriptions',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/users/subscriptions');

      return data;
    } catch (error) {
      return rejectWithValue([]);
    }
  }
);

export const postCurrentUserToSubscribers = createAsyncThunk(
  'channelsSubscribers/postCurrentUserToSubscribers',
  async ({ channelId }: PostCurrentUserToSubscribers, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.post(`/channels/${channelId}/subscribe`);
      return data.subscriber;
    } catch (error) {
      return rejectWithValue({});
    }
  }
);

export const deleteCurrentUserFromSubscribers = createAsyncThunk(
  'channels/deleteCurrentUserFromSubscribers',
  async (
    { channelId, subscriberId }: DeleteCurrentUserFromSubscribers,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await apiClient.instance.delete(`/channels/${channelId}/unsubscribe`);

      if (response.status === 200) {
        return fulfillWithValue(subscriberId);
      }

      return rejectWithValue(null);
    } catch (error) {
      return rejectWithValue(null);
    }
  }
);
