import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Department } from '@commonTypes/department';
import activityState from '@state/activity';
import authState from '@state/auth';
import { ROUTES } from '@constants';

const useNavLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const { list: departmentsData } = useSelector((state: RootState) => state.departments.data);
  const { activeDepartment } = useSelector((state: RootState) => state.activity.data);

  const onDepartmentSelect = (department: Department) => {
    dispatch(activityState.actions.setActiveDepartment(department));
    setIsNavigationOpen(false);
    navigate(ROUTES.channels);
  };

  const onSignOut = () => {
    dispatch(authState.actions.signOut());
  };

  const onAppRestart = () => {
    dispatch({ type: 'auth/reset-keep-logged-in' });
    navigate(ROUTES.channels);
    window.location.reload();
  };

  const onActionButtonClick = (action: string) => {
    if (action === 'Sign out') {
      onSignOut();
    }

    if (action === 'Restart app') {
      onAppRestart();
    }
  };

  return {
    activeDepartment,
    departmentsData,
    isNavigationOpen,
    setIsNavigationOpen,
    onDepartmentSelect,
    onActionButtonClick,
  };
};

export default useNavLayout;
