import { useEffect, useState } from 'react';
import { sendMessageToServiceWorker } from '@utils/serviceWorkerMessages';
import { ServiceWorkerMessageTypes } from '@commonTypes/serviceWorkerMessages';
import { RegistrationState } from '@pusher/push-notifications-web';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { pusherBeamsClient } from '@api/beamsClient';
import persistPreferencesState from '@state/persistPreferences';
import { ChannelSubscription } from '@state/currentUserSubscriptions/types';
import { LoadingTypes } from '@commonTypes/common';
import { addToast } from '@providers';

const usePhoneCallsProvider = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { session } = useSelector((state: RootState) => state.auth?.data) || {};
  const { notificationsEnabled } = useSelector(
    (state: RootState) => state.persistPreferences?.data
  );
  const { data: mySubscriptions } = useSelector(
    (state: RootState) => state.currentUserSubscriptions
  );
  const tenantConfig = useSelector((state: RootState) => state.settings?.tenantConfig?.data);
  const [notificationClientStatus, setNotificationClientStatus] = useState<RegistrationState>(
    RegistrationState.PERMISSION_PROMPT_REQUIRED
  );
  const [notificationEnabledStatusLoading, setNotificationEnabledStatusLoading] = useState(
    LoadingTypes.idle
  );
  const notificationsActivated =
    notificationsEnabled &&
    notificationClientStatus === RegistrationState.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS;

  const updateNotificationsEnabledStatus = async () => {
    try {
      if (!pusherBeamsClient) {
        return console.log('[Beams]: Beams client not started.');
      }

      const notificationsState = await pusherBeamsClient.getRegistrationState();
      setNotificationClientStatus(notificationsState);
    } catch (error) {
      console.log('[Beams]: Failed to retrieve registration status');
    }
  };

  const getSubscriptionsAndConvertToInterests = () => {
    try {
      return (
        mySubscriptions?.map(
          (subscription: ChannelSubscription) => `NewEntryInChannel-${subscription.channelId}`
        ) || []
      );
    } catch (error) {
      return [];
    }
  };

  const startBeamsClient = async () => {
    try {
      if (!pusherBeamsClient) {
        return console.log('[Beams]: Beams client not started.');
      }

      setNotificationEnabledStatusLoading(LoadingTypes.pending);
      await pusherBeamsClient.start();

      const interests = getSubscriptionsAndConvertToInterests();

      if (interests.length) {
        await pusherBeamsClient.setDeviceInterests(interests);
      }

      await updateNotificationsEnabledStatus();

      setNotificationEnabledStatusLoading(LoadingTypes.fulfilled);
    } catch (error) {
      setNotificationEnabledStatusLoading(LoadingTypes.rejected);
      await updateNotificationsEnabledStatus();
      console.log('[Beams]: Failed to start beams client: Error:', error);
    }
  };

  const stopBeamsClient = async () => {
    try {
      if (!pusherBeamsClient) {
        return console.log('[Beams]: Beams client not started.');
      }

      setNotificationEnabledStatusLoading(LoadingTypes.pending);
      await pusherBeamsClient.clearDeviceInterests();
      await pusherBeamsClient.stop();

      console.log('[Beams]: Client stopped');

      await updateNotificationsEnabledStatus();
      setNotificationEnabledStatusLoading(LoadingTypes.fulfilled);
    } catch (error) {
      setNotificationEnabledStatusLoading(LoadingTypes.rejected);
      await updateNotificationsEnabledStatus();
      console.log('[Beams]: Failed to stop beams client: Error:', error);
    }
  };

  const enableNotifications = () => {
    dispatch(persistPreferencesState.actions.setNotificationEnabledStatus(true));
    startBeamsClient();
  };

  const disableNotifications = () => {
    dispatch(persistPreferencesState.actions.setNotificationEnabledStatus(false));
    stopBeamsClient();
  };

  const addInterest = async (interestsId: number) => {
    try {
      if (!pusherBeamsClient) {
        return console.log('[Beams]: Beams client not started.');
      }

      if (interestsId) {
        await pusherBeamsClient.addDeviceInterest(`NewEntryInChannel-${interestsId}`);
      }
    } catch (error) {
      console.log('[Beams]: Failed to add interest:', `NewEntryInChannel-${interestsId}`);
    }
  };

  const removeInterest = async (interestsId: number) => {
    try {
      if (!pusherBeamsClient) {
        return console.log('[Beams]: Beams client not started.');
      }

      if (interestsId) {
        await pusherBeamsClient.removeDeviceInterest(`NewEntryInChannel-${interestsId}`);
      }
    } catch (error) {
      console.log('[Beams]: Failed to remove interest', `NewEntryInChannel-${interestsId}`);
    }
  };

  useEffect(() => {
    if (!tenantConfig) return;
    if (session?.userId) {
      sendMessageToServiceWorker(ServiceWorkerMessageTypes.changeCurrentUserId, {
        userId: session.userId,
      });
      if (
        notificationsEnabled &&
        notificationClientStatus !== RegistrationState.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS
      ) {
        startBeamsClient();
      }
    }

    if (!session?.userId) {
      sendMessageToServiceWorker(ServiceWorkerMessageTypes.changeCurrentUserId, {
        userId: undefined,
      });

      if (notificationClientStatus === RegistrationState.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS) {
        stopBeamsClient();
      }
    }
  }, [tenantConfig, session?.userId]);

  useEffect(() => {
    if (notificationsEnabled && notificationClientStatus === RegistrationState.PERMISSION_DENIED) {
      setNotificationClientStatus(RegistrationState.PERMISSION_PROMPT_REQUIRED);
      addToast({
        type: 'warning',
        message: 'To enable notifications, update the settings in your browser.',
      });
    }
  }, [notificationsEnabled, notificationClientStatus]);

  return {
    notificationsActivated,
    notificationEnabledStatusLoading,
    addInterest,
    removeInterest,
    enableNotifications,
    disableNotifications,
  };
};

export default usePhoneCallsProvider;
