import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { DriverNoteProps } from './DriverNote.types';
import notesState from '@state/notes';
import { useEffect, useState } from 'react';
import { LoadingTypes } from '@commonTypes/common';

const useUserNote = (props: DriverNoteProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { data: noteData, loading: noteLoading } =
    useSelector((state: RootState) => props.notesUserId && state.notes[props.notesUserId]) || {};
  const [noteValue, setNoteValue] = useState(noteData?.value || '');

  const fetchNote = (userId: number) => {
    return dispatch(notesState.actions.fetchNote({ userId })).unwrap();
  };

  const updateNote = (value: string) => {
    if (props.notesUserId) {
      dispatch(
        notesState.actions.createOrUpdateNote({
          value,
          userId: props.notesUserId,
          noteId: noteData?.id,
        })
      );
    }
  };

  const onNoteValueChange = (value: string) => {
    setNoteValue(value);
    updateNote(value);
  };

  useEffect(() => {
    if (noteData && noteData.value !== noteValue) {
      setNoteValue(noteData?.value);
    }
  }, [noteData]);

  useEffect(() => {
    if (props.notesUserId && noteLoading !== LoadingTypes.fulfilled) {
      fetchNote(props.notesUserId);
    }
  }, [props.notesUserId]);

  return {
    onNoteValueChange,
    noteValue,
    noteLoading,
  };
};

export default useUserNote;
