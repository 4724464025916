import React from 'react';
import classNames from 'classnames';
import { ButtonProps } from './Button.types';
import styles from './Button.module.scss';
import { LoadingTypes } from '@commonTypes/common';
import { Loader } from '@components/Common';

const Button = ({
  icon,
  children,
  size = 'medium',
  position = 'right',
  variant = 'text',
  active,
  loading,
  disabled,
  className,
  ...rest
}: ButtonProps) => (
  <button
    disabled={disabled || loading === LoadingTypes.pending}
    type="button"
    {...rest}
    className={classNames(
      styles.container,
      {
        [styles.small]: size === 'small',
        [styles.default]: size === 'medium',
        [styles.contained]: variant === 'contained',
        [styles.outlined]: variant === 'outlined',
        [styles.text]: variant === 'text',
        [styles.activated]: !!active,
        [styles.withoutText]: icon && !children,
      },
      className
    )}
  >
    <div
      className={classNames(styles.content, {
        [styles.leftIcon]: position === 'left',
        [styles.rightIcon]: position === 'right',
        [styles.withoutText]: icon && !children,
      })}
    >
      {!(position === 'right' && loading === LoadingTypes.pending) && icon}
      {children && <div className={styles.buttonTitle}>{children}</div>}
    </div>

    <Loader
      background="transparent"
      className={styles.loader}
      visible={loading === LoadingTypes.pending}
    />
  </button>
);

export default Button;
