import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { FetchFiles } from './types';
import { PostFilePayload } from '@state/messages/types';

export const fetchChannelsFiles = createAsyncThunk(
  'channelsFiles/fetchFiles',
  async ({ channelId, beforeFileId, amount }: FetchFiles, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('files', {
        params: {
          ChannelId: channelId,
          BeforeFileId: beforeFileId,
          Take: amount,
        },
      });

      return data.files;
    } catch (error) {
      return rejectWithValue([]);
    }
  }
);

export const postNewFile = createAsyncThunk(
  'channelsFiles/postFile',
  async ({ file }: PostFilePayload, { fulfillWithValue }) => {
    try {
      const response = await apiClient.instance.post('files/upload', file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return response.data?.file;
    } catch (error) {
      return fulfillWithValue(null);
    }
  }
);
