import React from 'react';
import { SubscribersEmptyMessageProps } from './SubscribersEmptyMessage.types';
import { MultipleUsersIcon } from '@components/Common/Icons';
import classNames from 'classnames';
import styles from './SubscribersEmptyMessage.module.scss';

const SubscribersEmptyMessage = (props: SubscribersEmptyMessageProps) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      <MultipleUsersIcon />

      <div className={styles.textContainer}>{props.children}</div>
    </div>
  );
};

export default SubscribersEmptyMessage;
