import React from 'react';
import classNames from 'classnames';
import { MessageLayoutProps } from './MessageLayout.types';
import { TextInfo } from '@components/Common';
import styles from './MessageLayout.module.scss';

const MessageLayout = (props: MessageLayoutProps) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      <TextInfo>{props.message}</TextInfo>
    </div>
  );
};

export default MessageLayout;
