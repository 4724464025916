import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { AxiosError } from 'axios';

export const fetchDepartments = createAsyncThunk(
  'departments/fetchDepartments',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/departments');
      return data;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 401) {
        return fulfillWithValue(null);
      }

      console.log('[Departments]: Failed to fetch departments', error);
      return rejectWithValue(null);
    }
  }
);
