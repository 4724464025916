import { createSlice } from '@reduxjs/toolkit';
import { LoadingTypes } from '@commonTypes/common';
import { UserRoles } from '@commonTypes/user';
import {
  AttributesState,
  RemoveAttributeAction,
  ReorderAttributeAction,
  RevertRemoveAttributeAction,
} from './types';
import {
  createAttribute,
  deleteAttribute,
  fetchAttributesByUserType,
  reorderAttribute,
  updateAttribute,
} from './thunks';

export const initialState: AttributesState = {
  [UserRoles.fieldAgent]: {
    data: [],
    totalCount: 0,
    loading: LoadingTypes.idle,
  },
  [UserRoles.handler]: {
    data: [],
    totalCount: 0,
    loading: LoadingTypes.idle,
  },
  [UserRoles.specialist]: {
    data: [],
    totalCount: 0,
    loading: LoadingTypes.idle,
  },
  [UserRoles.manager]: {
    data: [],
    totalCount: 0,
    loading: LoadingTypes.idle,
  },
  [UserRoles.admin]: {
    data: [],
    totalCount: 0,
    loading: LoadingTypes.idle,
  },
};

export const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    removeAttribute: (state: AttributesState, action: RemoveAttributeAction) => {
      const { id, type } = action.payload;
      state[type].data = state[type].data.filter((attribute) => attribute.id !== id);
      if (state[type].totalCount > 0) {
        state[type].totalCount -= 1;
      }
    },
    revertRemoveAttribute: (state: AttributesState, action: RevertRemoveAttributeAction) => {
      const { attribute, type } = action.payload;
      if (attribute) {
        state[type].data.push(attribute);
        state[type].totalCount += 1;
      }
    },
    reorderAttributeLocally: (state: AttributesState, action: ReorderAttributeAction) => {
      const { firstAttribute, secondAttribute, type } = action.payload;
      const newData = state[type].data;
      const firstIndex = newData.findIndex((attr) => attr.id === firstAttribute.id);
      const secondIndex = newData.findIndex((attr) => attr.id === secondAttribute.id);
      newData[firstIndex].sortOrder = secondAttribute.sortOrder;
      newData[secondIndex].sortOrder = firstAttribute.sortOrder;
      [newData[firstIndex], newData[secondIndex]] = [newData[secondIndex], newData[firstIndex]];
      state[type].data = newData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAttributesByUserType.pending, (state, { meta }) => {
      state[meta.arg.userType].loading = LoadingTypes.pending;
    });

    builder.addCase(fetchAttributesByUserType.rejected, (state, { meta }) => {
      state[meta.arg.userType].loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchAttributesByUserType.fulfilled, (state, { meta, payload }) => {
      const userType = meta.arg.userType;
      state[userType].data = payload.items;
      state[userType].totalCount = payload.totalCount;
      state[userType].loading = LoadingTypes.fulfilled;
    });

    builder.addCase(createAttribute.pending, (state, { meta }) => {
      state[meta.arg.userType].loading = LoadingTypes.pending;
    });

    builder.addCase(createAttribute.rejected, (state, { meta }) => {
      state[meta.arg.userType].loading = LoadingTypes.rejected;
    });

    builder.addCase(createAttribute.fulfilled, (state, { meta, payload }) => {
      const userType = meta.arg.userType;
      state[userType].data.push(payload.userAttribute);
      state[userType].totalCount += 1;
      state[userType].loading = LoadingTypes.fulfilled;
    });

    builder.addCase(updateAttribute.pending, (state, { meta }) => {
      state[meta.arg.userType].loading = LoadingTypes.pending;
    });

    builder.addCase(updateAttribute.rejected, (state, { meta }) => {
      state[meta.arg.userType].loading = LoadingTypes.rejected;
    });

    builder.addCase(updateAttribute.fulfilled, (state, { meta, payload }) => {
      const userType = meta.arg.userType;
      const index = state[userType].data.findIndex((attr) => attr.id === payload.userAttribute.id);
      state[userType].data[index] = payload.userAttribute;
      state[userType].loading = LoadingTypes.fulfilled;
    });

    builder.addCase(deleteAttribute.pending, (state, { meta }) => {
      state[meta.arg.type].loading = LoadingTypes.pending;
    });

    builder.addCase(deleteAttribute.rejected, (state, { meta }) => {
      state[meta.arg.type].loading = LoadingTypes.rejected;
    });

    builder.addCase(deleteAttribute.fulfilled, (state, { meta }) => {
      state[meta.arg.type].loading = LoadingTypes.fulfilled;
    });

    builder.addCase(reorderAttribute.pending, (state, { meta }) => {
      state[meta.arg.type].loading = LoadingTypes.pending;
    });

    builder.addCase(reorderAttribute.rejected, (state, { meta }) => {
      state[meta.arg.type].loading = LoadingTypes.rejected;
    });

    builder.addCase(reorderAttribute.fulfilled, (state, { meta }) => {
      state[meta.arg.type].loading = LoadingTypes.fulfilled;
    });
  },
});

export const actions = attributesSlice.actions;
export default attributesSlice.reducer;
