import React from 'react';
import classNames from 'classnames';
import { StarIcon } from '@components/Common/Icons';
import styles from './StarredChannelEntry.module.scss';
import { StarredMessageProps } from './StarredChannelEntry.types';
import MessageAttachment from '@components/Common/MessageItem/MessageAttachment';

const StarredChannelEntry = ({
  owner,
  senderInitials,
  sentAt,
  text,
  attachments,
  className,
  ...rest
}: StarredMessageProps) => {
  return (
    <div {...rest} className={classNames(styles.container, className)}>
      <div className={styles.message}>
        <span className={styles.starIcon}>
          <StarIcon />
        </span>

        <div>
          {text && <p>{text}</p>}

          {attachments?.map((file) => (
            <div className={styles.attachment} key={`starred-message-attachment-${file.id}`}>
              <MessageAttachment {...file} />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.dateTime}>
        <p>
          By {owner ? 'You' : senderInitials} · {sentAt}
        </p>
      </div>
    </div>
  );
};

export default StarredChannelEntry;
