import { Dayjs } from 'dayjs';

export enum LoadingTypes {
  idle = 'idle',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export interface Option {
  label: string;
  value: string | number | any;
  icon?: JSX.Element;
}

export type DateType = Date | Dayjs | string;

export interface ToastType {
  id: string;
  message: string;
  type: ToastTypes;
  duration?: number;
  actionComponent?: JSX.Element;
}

export type ToastTypes = 'success' | 'error' | 'warning' | 'info';
