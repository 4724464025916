import React from 'react';
import classNames from 'classnames';
import { formatPhoneNumberToLocalUS } from '@utils';
import { RoundButton, H4, H5, Loader } from '@components/Common';
import { ChannelsHeaderProps } from './ChannelsHeader.types';
import { MessageAddIcon } from '@components/Common/Icons';
import useChannelsHeader from './useChannelsHeader';
import { LoadingTypes } from '@commonTypes/common';
import styles from './ChannelsHeader.module.scss';

const ChannelsHeader = (props: ChannelsHeaderProps) => {
  const { profileData, activeDepartment, profileLoading } = useChannelsHeader();

  return (
    <div className={classNames(styles.container, props.className)}>
      <Loader fullSize visible={profileLoading === LoadingTypes.pending} />

      {profileLoading !== LoadingTypes.pending && (
        <>
          <div className={styles.nameContainer}>
            {profileData && (
              <H4>
                {profileData.firstName} {profileData.lastName}
              </H4>
            )}
            {activeDepartment?.phoneNumber && (
              <H5 weight="normal">{formatPhoneNumberToLocalUS(activeDepartment.phoneNumber)}</H5>
            )}
          </div>

          <RoundButton
            className={styles.newChatButton}
            color="success"
            icon={<MessageAddIcon />}
            size="default"
            variant="contained"
            onClick={props.onNewChatClick}
          />
        </>
      )}
    </div>
  );
};

export default ChannelsHeader;
