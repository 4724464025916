import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import {
  FetchAttributesPayload,
  CreateAttributePayload,
  EditAttributePayload,
  DeleteAttributePayload,
  ReorderAttributePayload,
} from './types';

export const fetchAttributesByUserType = createAsyncThunk(
  'attributes/fetchAttributesByUserType',
  async ({ fieldType, userType, skip, take }: FetchAttributesPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/user-attributes', {
        params: {
          fieldType,
          userType,
          skip,
          take,
        },
      });

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch custom attributes. Please try again later or contact support for assistance.'
      );
    }
  }
);

export const createAttribute = createAsyncThunk(
  'attributes/createAttribute',
  async (attributePayload: CreateAttributePayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.post('/user-attributes', attributePayload);

      if (data?.errors?.length) {
        return rejectWithValue(data?.errors?.join('\n'));
      }

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to create custom attribute. Please check that all fields are filled in correctly and try again.'
      );
    }
  }
);

export const updateAttribute = createAsyncThunk(
  'attributes/updateAttribute',
  async ({ id, ...rest }: EditAttributePayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.put(`/user-attributes/${id}`, { ...rest });

      if (data?.errors?.length) {
        return rejectWithValue(data?.errors?.join('\n'));
      }

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to update custom attribute. Please check that all fields are filled in correctly and try again.'
      );
    }
  }
);

export const deleteAttribute = createAsyncThunk(
  'attributes/deleteAttribute',
  async ({ id }: DeleteAttributePayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.delete(`/user-attributes/${id}`);

      if (data?.errors?.length) {
        return rejectWithValue(data?.errors?.join('\n'));
      }

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to delete custom attribute. Please try again later or contact support for assistance.'
      );
    }
  }
);

export const reorderAttribute = createAsyncThunk(
  'attributes/reorderAttribute',
  async ({ id, direction }: ReorderAttributePayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.put(`/user-attributes/${id}/reorder`, {
        orderDirection: direction,
      });

      if (data?.errors?.length) {
        return rejectWithValue(data?.errors?.join('\n'));
      }

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to reorder custom attribute. Please try again later or contact support for assistance.'
      );
    }
  }
);
