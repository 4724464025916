import React from 'react';
import { NavLayoutProps } from './NavLayout.types';
import { NavBar, NavMenu } from '../index';
import { RoundButton } from '@components/Common';
import useNavLayout from './useNavLayout';
import { DotsIcon, ArrowUpNotFilledIcon } from '@components/Common/Icons';
import classNames from 'classnames';
import styles from './NavLayout.module.scss';

const NavLayout = (props: NavLayoutProps) => {
  const {
    activeDepartment,
    departmentsData,
    isNavigationOpen,
    setIsNavigationOpen,
    onDepartmentSelect,
    onActionButtonClick,
  } = useNavLayout();

  return (
    <div className={styles.container}>
      <div
        className={classNames({
          [styles.openedMenu]: isNavigationOpen,
        })}
      >
        <NavBar
          className={styles.navBar}
          title={activeDepartment?.name}
          leftControl={<>{props.backButton && !isNavigationOpen ? props.backButton : null}</>}
          rightControl={
            <>
              {isNavigationOpen ? (
                <RoundButton
                  variant="text"
                  icon={<ArrowUpNotFilledIcon />}
                  onClick={() => setIsNavigationOpen(false)}
                />
              ) : (
                <RoundButton
                  variant="text"
                  icon={<DotsIcon />}
                  onClick={() => setIsNavigationOpen(true)}
                />
              )}
            </>
          }
        />

        {isNavigationOpen && (
          <NavMenu
            departments={departmentsData}
            actionButtons={['Restart app', 'Sign out']}
            activeDepartmentId={activeDepartment?.id}
            onDepartmentSelect={onDepartmentSelect}
            onActionButtonClick={onActionButtonClick}
          />
        )}
      </div>

      <div className={classNames(styles.content, props.className)}>{props.children}</div>
    </div>
  );
};

export default NavLayout;
