import React, { Component, ErrorInfo } from 'react';
import { connect } from 'react-redux';
import { Button, H2 } from '@components/Common';
import { AppDispatch } from '@state/types';
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types';
import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Crash error information could be send to alerts system
    console.error('Error boundary. Error:', error, errorInfo);
  }

  onAppReloadClick = () => {
    // reload the app if critical error happen
    this.props.resetState();
    window.location.reload();
  };

  public render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <H2 weight="normal">An error has occurred</H2>

          <Button variant="outlined" onClick={this.onAppReloadClick}>
            Reload
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetState: () => dispatch({ type: 'auth/reset-keep-logged-in' }),
  };
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
