import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';
import { DropdownProps } from './Dropdown.types';
import './Dropdown.scss';

const Dropdown = (props: DropdownProps) => {
  const [isVisible, setIsVisible] = useState(props.open);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(
    {
      visible: isVisible,
      interactive: true,
      delayShow: 0,
      delayHide: 0,
      closeOnTriggerHidden: true,
      placement: 'top-start',
      trigger: 'click',
      onVisibleChange: (visibilityValue) => {
        if (props.open === undefined) {
          setIsVisible(visibilityValue);
        }
        props.onVisibilityChange?.(visibilityValue);
      },
    },
    {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [props.offsetHorizontal, props.offsetVertical],
          },
        },
      ],
    }
  );

  useEffect(() => {
    setIsVisible(props.open);
  }, [props.open]);

  return (
    <div className={classNames('dropdownContainer', props.containerClassName)}>
      <div className={classNames({ disabled: !!props.disabled })} ref={setTriggerRef}>
        {props.children}
      </div>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: classNames('dropdown-container', props.contentClassName),
          })}
        >
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
