import React, { Children, MutableRefObject, useEffect, useRef, useState } from 'react';
import { InfiniteScrollProps, ScrollBehaviorTypes } from './InfiniteScroll.types';
import { TextInfo } from '@components/Common';
import styles from './InfiniteScroll.module.scss';
import classNames from 'classnames';
import { LoadingTypes } from '@commonTypes/common';

const InfiniteScroll = (props: InfiniteScrollProps) => {
  const {
    children,
    lastElementId,
    firstElementId,
    emptyMessage,
    loaderComponent,
    loading,
    direction = 'start',
    dataLength,
    className,
    onLoadMore,
  } = props;
  const containerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [prevScrollPosition, setPrevScrollPosition] = useState<number>();
  const showEmptyMessage = emptyMessage && loading !== LoadingTypes.pending && !dataLength;

  const scrollToBottomPosition = (behavior: ScrollBehaviorTypes) => {
    if (containerRef.current) {
      const top: number = containerRef.current.scrollHeight;
      containerRef.current.scrollTo({
        behavior: behavior || 'auto',
        top: top,
      });
    }
  };

  const scrollToPreviousPosition = (behavior: ScrollBehaviorTypes) => {
    if (containerRef.current && prevScrollPosition) {
      const currentScroll = containerRef.current.scrollHeight - prevScrollPosition;
      containerRef.current.scrollTo({
        top: currentScroll,
        behavior: behavior || 'auto',
      });
    }
  };

  const onScroll = () => {
    if (!containerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    const scrollPositionIsTop = scrollTop === 0;
    const scrollPositionIsBottom = scrollHeight - scrollTop === clientHeight;

    if (direction === 'start' && scrollPositionIsTop) {
      setPrevScrollPosition(scrollHeight);
      onLoadMore();
    }

    if (direction === 'end' && scrollPositionIsBottom) {
      onLoadMore();
    }
  };

  useEffect(() => {
    if (direction === 'start') {
      scrollToPreviousPosition('auto');
    }
  }, [firstElementId]);

  useEffect(() => {
    if (direction === 'start') {
      scrollToBottomPosition('auto');
    }
  }, [lastElementId]);

  return (
    <div className={classNames(styles.container, className)} ref={containerRef} onScroll={onScroll}>
      {!!(loaderComponent && direction === 'start' && loading === LoadingTypes.pending) &&
        loaderComponent}

      {Children?.map(children, (child) => child)}

      {!!(loaderComponent && direction === 'end' && loading === LoadingTypes.pending) &&
        loaderComponent}

      {showEmptyMessage ? (
        <div className={styles.emptyMessage}>
          <TextInfo>{emptyMessage}</TextInfo>
        </div>
      ) : null}
    </div>
  );
};

InfiniteScroll.displayName = 'InfiniteScroll';
export default InfiniteScroll;
