import { useSelector } from 'react-redux';
import { RootState } from '@state/types';

const useChannelsList = () => {
  const { data: profileData, loading: profileLoading } = useSelector(
    (state: RootState) => state.currentUser.profile
  );

  const { activeDepartment } = useSelector((state: RootState) => state.activity.data);

  return {
    profileData,
    profileLoading,
    activeDepartment,
  };
};

export default useChannelsList;
