import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';

export const fetchUserById = createAsyncThunk(
  'users/fetchUserById',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get(`/users/${id}`);

      return data;
    } catch (error) {
      return rejectWithValue({});
    }
  }
);
