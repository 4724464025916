export interface GlobalSetting {
  id: number;
  type?: string;
  name?: string;
  title?: string;
  value: string | number;
  description?: string;
  options?: string[];
  isOverridable: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface PersonalSetting {
  appSettingId: number;
  type?: string;
  name?: string;
  title?: string;
  value?: string | number | null;
  defaultValue: string | number;
  description?: string;
  options?: string[];
  isOverridable: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface SettingShort {
  id: number;
  value?: string | number | null;
}

export interface UpdatedAppSetting {
  key: SettingNameMap;
  value: string | number | null;
}

export enum SettingNameMap {
  sendOnEnter = 'SendOnEnter',
  useSignature = 'UseSignatureWhenSendMessage',
  defaultTimezone = 'TimeZone',
  defaultLanguage = 'Language',
}

export interface AppSettings {
  [SettingNameMap.sendOnEnter]: boolean;
  [SettingNameMap.useSignature]: boolean;
  [SettingNameMap.defaultTimezone]: string;
  [SettingNameMap.defaultLanguage]: string;
}

export enum SettingTypes {
  boolean = 'Boolean',
  text = 'Text',
  multiLineText = 'MultiLineText',
  number = 'Number',
  dropdown = 'Enum',
}

export enum Language {
  en = 'en',
  ru = 'ru',
  de = 'de',
  fr = 'fr',
  es = 'es',
  it = 'it',
  pt = 'pt',
  nl = 'nl',
  tr = 'tr',
  pl = 'pl',
  cs = 'cs',
  da = 'da',
  fi = 'fi',
  sv = 'sv',
  no = 'no',
  hu = 'hu',
  ro = 'ro',
  bg = 'bg',
  sr = 'sr',
  uk = 'uk',
  hr = 'hr',
}

export const LanguageLabels = {
  [Language.en]: 'English',
  [Language.ru]: 'Русский',
  [Language.de]: 'Deutsch',
  [Language.fr]: 'Français',
  [Language.es]: 'Español',
  [Language.it]: 'Italiano',
  [Language.pt]: 'Português',
  [Language.nl]: 'Nederlands',
  [Language.tr]: 'Türkçe',
  [Language.pl]: 'Polski',
  [Language.cs]: 'Čeština',
  [Language.da]: 'Dansk',
  [Language.fi]: 'Suomi',
  [Language.sv]: 'Svenska',
  [Language.no]: 'Norsk',
  [Language.hu]: 'Magyar',
  [Language.ro]: 'Română',
  [Language.bg]: 'Български',
  [Language.sr]: 'Српски',
  [Language.uk]: 'Українська',
  [Language.hr]: 'Hrvatski',
};
