import { createSlice } from '@reduxjs/toolkit';
import { LoadingTypes } from '@commonTypes/common';
import { TagsState } from './types';
import { fetchTags, createTag, updateTag, deleteTag } from './thunks';

export const initialState: TagsState = {
  data: [],
  totalTags: 0,
  loading: LoadingTypes.idle,
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    removeTag: (state: TagsState, action) => {
      const { id } = action.payload;
      state.data = state.data.filter((tag) => tag.id !== id);

      if (state.totalTags > 0) {
        state.totalTags -= 1;
      }
    },
    revertRemoveTag: (state: TagsState, action) => {
      const tag = action.payload;
      state.data.unshift(tag);
      state.totalTags += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTags.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchTags.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchTags.fulfilled, (state, { payload, meta }) => {
      const prevList = meta.arg.skip > 0 ? state.data : [];
      const newList = payload.items || [];

      state.data = [...prevList, ...newList];
      state.totalTags = payload.totalCount;
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(createTag.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(createTag.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(createTag.fulfilled, (state, { payload }) => {
      state.data.unshift(payload.tag);
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(updateTag.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(updateTag.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(updateTag.fulfilled, (state, { payload }) => {
      state.data = state.data.map((tag) => (tag.id === payload.tag.id ? payload.tag : tag));
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(deleteTag.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(deleteTag.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(deleteTag.fulfilled, (state) => {
      state.loading = LoadingTypes.fulfilled;
    });
  },
});

export const actions = tagsSlice.actions;
export default tagsSlice.reducer;
