import {
  ServiceWorkerMessageData,
  ServiceWorkerMessageTypes,
} from '@commonTypes/serviceWorkerMessages';

export const sendMessageToServiceWorker = (
  type: ServiceWorkerMessageTypes,
  data: ServiceWorkerMessageData
) => {
  if (!('serviceWorker' in navigator)) {
    return console.warn('No registered service workers found');
  }

  navigator.serviceWorker?.controller?.postMessage({
    type,
    data,
  });
};
