import { useNavigate } from 'react-router-dom';
import authState from '@state/auth';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { ROUTES } from '@constants';
import { LoadingTypes } from '@commonTypes/common';

const useSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { session } = useSelector((state: RootState) => state.auth?.data) || {};
  const [loading, setLoading] = useState(LoadingTypes.idle);
  const [error, setError] = useState('');

  useEffect(() => {
    if (loading === LoadingTypes.fulfilled && session?.sessionId) {
      navigate(ROUTES.channels);
    }

    if (loading === LoadingTypes.rejected) {
      setError('Username or password is incorrect.');
    }
  }, [session, loading, navigate]);

  const onSignIn = async (username: string, password: string, tenant?: string) => {
    setLoading(LoadingTypes.pending);

    const authData = await dispatch(
      authState.actions.signIn({
        username,
        password,
        tenant,
      })
    ).unwrap();

    setLoading(authData?.sessionId ? LoadingTypes.fulfilled : LoadingTypes.rejected);
  };

  return {
    error,
    loading,
    onSignIn,
  };
};

export default useSignIn;
