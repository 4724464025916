export enum MessageRecipientType {
  fieldAgent = 'FieldAgent',
  tag = 'Tag',
}

export interface MassMessageRecipient {
  id: number;
  name: string;
  type: MessageRecipientType;
  channelId?: number;
  phoneNumber?: string;
  numberOfRecipients?: number;
}

export interface MassMessageRecipientSTagShort {
  id: number;
  name: string;
  fieldAgentsCount: number;
}

export interface FieldAgentRecipient {
  id: number;
  name: string;
}

export interface MassMessagesChannelRecipientsDetails {
  id: number;
  departmentId: number;
  name: string;
  recipientsCount: number;
  fieldAgents: FieldAgentRecipient[];
  tags: MassMessageRecipientSTagShort[];
}
