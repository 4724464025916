import React from 'react';
import classNames from 'classnames';
import { CloseIcon } from '@components/Common/Icons';
import { TagProps } from './Tag.types';
import styles from './Tag.module.scss';
import { Button } from '@components/Common';

const Tag = ({ icon, value, label, onTagRemove, onTagClick, className, ...rest }: TagProps) => {
  const onRemoveHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onTagRemove?.(value);
  };

  const onClickHandler = () => {
    onTagClick?.(value);
  };

  return (
    <div {...rest} className={classNames(styles.container, className)} onClick={onClickHandler}>
      {icon && <span className={styles.icon}>{icon}</span>}

      <div
        className={classNames(styles.content, {
          [styles.withIcon]: !!icon,
          [styles.withRemoveButton]: !!onTagRemove,
        })}
      >
        {label}
      </div>

      {onTagRemove && (
        <Button
          className={styles.remove}
          icon={<CloseIcon />}
          size="small"
          variant="text"
          onClickCapture={onRemoveHandler}
        />
      )}
    </div>
  );
};

export default Tag;
