import { createSlice } from '@reduxjs/toolkit';
import { fetchChannels } from './thunks';
import { LoadingTypes } from '@commonTypes/common';
import { sortChannelsByLastEntry } from '@utils';
import { ChannelsState } from './types';

export const initialState: ChannelsState = {
  data: {
    list: [],
    loading: LoadingTypes.idle,
  },
};

export const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    removeChannelByFieldAgentId: (state, { payload }) => {
      state.data.list = state.data.list?.filter((channel) => channel.fieldAgent?.id !== payload.id);
    },
    modifyChannelsByLastEntry: (state, action) => {
      const lastEntry = action.payload;
      state.data.list = sortChannelsByLastEntry(state.data.list, lastEntry);
    },
    modifyChannelFieldAgent: (state, { payload }) => {
      state.data.list = state.data.list?.map((channel) => {
        if (channel.fieldAgent?.id === payload.id) {
          channel.fieldAgent = {
            ...channel.fieldAgent,
            ...payload,
          };
        }

        return channel;
      });
    },
    addChannelIfNotExist: (state, action) => {
      const prevList = [...state.data.list];
      const isExist = prevList?.some((channel) => channel?.id === action.payload?.id);

      if (!isExist) {
        state.data.list = [action.payload, ...state.data.list];
      }
    },
    increaseChannelsMessagesCounter: (state, { payload }) => {
      const { channelId, value } = payload;
      state.data.list = state.data.list?.map((channel) => {
        if (channel.id === channelId) {
          channel.unreadMessagesCount += value;
        }

        return channel;
      });
    },
    decreaseChannelsMessagesCounter: (state, { payload }) => {
      const { channelId, value } = payload;
      state.data.list = state.data.list?.map((channel) => {
        if (channel.id === channelId) {
          channel.unreadMessagesCount -= value;
        }

        return channel;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChannels.pending, (state) => {
      state.data.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchChannels.rejected, (state) => {
      state.data.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchChannels.fulfilled, (state, action) => {
      state.data.loading = LoadingTypes.fulfilled;
      state.data.list = action.payload;
    });
  },
});

export const actions = channelsSlice.actions;
export default channelsSlice.reducer;
