import subscribersState from '@state/channelsSubscribers';
import currentUserSubscriptionsState from '@state/currentUserSubscriptions';
import {
  DeleteChannelsSubscribers,
  PostChannelsSubscribers,
} from '@state/channelsSubscribers/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { filterFieldAgentFromSubscriber, isCurrentUserChannelSubscriber } from '@utils';

const useConversationToolbar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { activeChannel } = useSelector((state: RootState) => state.activity.data);
  const currentUser = useSelector((state: RootState) => state.currentUser.profile?.data);

  const channelsSubscribersData = useSelector((state: RootState) =>
    activeChannel?.id ? state.channelSubscribers.data[activeChannel?.id] : undefined
  );

  const isCurrentUserSubscriber = isCurrentUserChannelSubscriber(activeChannel?.id);
  const filteredSubscribers = filterFieldAgentFromSubscriber(channelsSubscribersData?.data);

  const getSubscribersPlaceholder = () => {
    const count = filteredSubscribers?.length;
    if (!count) {
      return 'No Subscribers';
    }

    return count > 1 ? `Subscribers` : `Subscriber`;
  };

  const addCurrentUserToSubscriber = () => {
    if (activeChannel) {
      dispatch(
        currentUserSubscriptionsState.actions.postCurrentUserToSubscribers({
          channelId: activeChannel.id,
        })
      ).unwrap();
    }
  };

  const deleteCurrentUserFromSubscribers = () => {
    if (activeChannel && currentUser) {
      dispatch(
        currentUserSubscriptionsState.actions.deleteCurrentUserFromSubscribers({
          channelId: activeChannel.id,
          subscriberId: currentUser.id,
        })
      );
    }
  };

  const addChannelsSubscribers = (params: PostChannelsSubscribers) => {
    dispatch(subscribersState.actions.postChannelsSubscribers(params));
  };

  const deleteChannelsSubscriber = (params: DeleteChannelsSubscribers) => {
    dispatch(subscribersState.actions.deleteChannelsSubscribers(params));
  };

  return {
    addCurrentUserToSubscriber,
    deleteCurrentUserFromSubscribers,
    addChannelsSubscribers,
    deleteChannelsSubscriber,
    getSubscribersPlaceholder,
    isCurrentUserSubscriber,
    filteredSubscribers,
  };
};

export default useConversationToolbar;
