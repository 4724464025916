import apiClient from '@api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import starredMessagesState from '@state/starredMessages';
import messagesState from '@state/messages';
import { FetchStarredMessagesPayload, PostMessageStarPayload } from '@state/starredMessages/types';

export const fetchStarredMessages = createAsyncThunk(
  'starredMessages/fetchStarredMessages',
  async ({ skip, amount, search }: FetchStarredMessagesPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('messages/starred', {
        params: {
          Skip: skip,
          Take: amount,
          Query: search,
        },
      });

      return data;
    } catch (error) {
      return rejectWithValue(undefined);
    }
  }
);

export const fetchMessagesStarredCount = createAsyncThunk(
  'starredMessages/fetchMessagesStarredCount',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('messages/starred-count');

      return data?.starredMessagesCount;
    } catch (error) {
      return rejectWithValue(0);
    }
  }
);

export const postMessageStarRemove = createAsyncThunk(
  'starredMessages/postMessageUnstar',
  async ({ message }: PostMessageStarPayload, { fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.post(`messages/unstar/${message.id}`);
      return data;
    } catch (error) {
      return fulfillWithValue(undefined);
    }
  }
);

export const postMessageStarAdd = createAsyncThunk(
  'starredMessages/postMessageStarAdd',
  async ({ message }: PostMessageStarPayload, { fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.post(`messages/star/${message.id}`);

      return data;
    } catch (error) {
      return fulfillWithValue(undefined);
    }
  }
);

export const postMessageStar = createAsyncThunk(
  'starredMessages/postMessageStar',
  async ({ message }: PostMessageStarPayload, { dispatch, fulfillWithValue }) => {
    try {
      const starred = !message.isStarred;

      let response = null;

      dispatch(
        messagesState.actions.modifyMessage({
          messageId: message.id,
          data: { ...message, isStarred: starred },
        })
      );

      if (starred) {
        response = await dispatch(postMessageStarAdd({ message })).unwrap();
      } else {
        response = await dispatch(postMessageStarRemove({ message })).unwrap();
      }

      if (response && starred) {
        dispatch(starredMessagesState.actions.increaseStarredCount());
        return response.data;
      }

      if (response && !starred) {
        dispatch(starredMessagesState.actions.decreaseStarredCount());
        return response;
      }

      dispatch(
        messagesState.actions.modifyMessage({
          messageId: message.id,
          data: { ...message, isStarred: !starred },
        })
      );

      return fulfillWithValue(undefined);
    } catch (error) {
      return fulfillWithValue(undefined);
    }
  }
);
