import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { LoaderProps } from './Loader.types';
import { flushSync } from 'react-dom';
import { LoaderIcon } from '@components/Common/Icons';
import styles from './Loader.module.scss';

const Loader = (props: LoaderProps) => {
  const { visible, background, fullSize, loadingMessage, className } = props;
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  useEffect(() => {
    if (visible && !isLoaderVisible) {
      setIsLoaderVisible(true);
    }

    if (!visible && isLoaderVisible) {
      setIsRemoving(true);
    }
  }, [isLoaderVisible, visible]);

  const hideLoaderWithAnimation = () => {
    if (isRemoving) {
      flushSync(() => {
        setIsRemoving(false);
        setIsLoaderVisible(false);
      });
    }
  };

  return (
    <>
      {isLoaderVisible && (
        <div
          className={classNames(styles.container, className, {
            [styles.removing]: isRemoving,
            [styles.fullSize]: !!fullSize,
          })}
          style={{
            background,
          }}
          onAnimationEnd={hideLoaderWithAnimation}
        >
          <div className={styles.loader}>
            <LoaderIcon />
            {loadingMessage && <p>{loadingMessage}</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
