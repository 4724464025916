import { createSlice } from '@reduxjs/toolkit';
import { ActivityState } from './types';
import { ChannelListTypes } from '@commonTypes/channel';

export const initialState: ActivityState = {
  data: {
    activeChannel: undefined,
    activeDepartment: undefined,
    activeChannelsFilter: ChannelListTypes.my,
  },
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setActiveDepartment: (state, action) => {
      state.data.activeDepartment = action.payload;
    },
    setActiveChannel: (state, action) => {
      state.data.activeChannel = action.payload;
    },
    setActiveChannelsFilter: (state, action) => {
      state.data.activeChannelsFilter = action.payload;
    },
    increaseActiveChannelMessagesCounter: (state, { payload }) => {
      const { channelId, value } = payload;
      if (state.data.activeChannel && state.data.activeChannel?.id === channelId) {
        state.data.activeChannel.unreadMessagesCount += value;
      }
    },
    decreaseActiveChannelMessagesCounter: (state, { payload }) => {
      const { channelId, value } = payload;

      if (state.data.activeChannel && state.data.activeChannel?.id === channelId) {
        state.data.activeChannel.unreadMessagesCount -= value;
      }
    },
    increaseActiveDepartmentMessagesCounter: (state, { payload }) => {
      const { departmentId, value } = payload;
      if (state.data.activeDepartment && state.data.activeDepartment?.id === departmentId) {
        state.data.activeDepartment.unreadChannelEntryCount += value;
      }
    },
    decreaseActiveDepartmentMessagesCounter: (state, { payload }) => {
      const { departmentId, value } = payload;

      if (state.data.activeDepartment && state.data.activeDepartment?.id === departmentId) {
        state.data.activeDepartment.unreadChannelEntryCount -= value;
      }
    },
    modifyActiveChannelFieldAgent: (state, { payload }) => {
      if (state.data.activeChannel) {
        const prevData = state.data.activeChannel.fieldAgent;
        state.data.activeChannel.fieldAgent = {
          ...prevData,
          ...payload,
        };
      }
    },
  },
});

export const actions = activitySlice.actions;
export default activitySlice.reducer;
