import {
  GlobalSetting,
  SettingShort,
  PersonalSetting,
  AppSettings,
  SettingNameMap,
} from '@commonTypes/settings';

export const getChangedGlobalSettings = (
  newSettings: GlobalSetting[],
  oldSettings: GlobalSetting[]
): SettingShort[] => {
  const changedSettings = newSettings.filter((newSetting) => {
    const oldSetting = oldSettings.find((setting) => setting.id === newSetting.id);
    return oldSetting?.value !== newSetting.value;
  });
  return changedSettings.map((setting) => ({
    id: setting.id,
    value: setting.value,
  }));
};

const handlePersonalSettingComparison = (
  newSetting: PersonalSetting,
  oldSetting?: PersonalSetting
): boolean => {
  if (oldSetting?.value === null && newSetting.value !== null) {
    return oldSetting?.defaultValue !== newSetting.value;
  }
  if (oldSetting?.value !== null && newSetting.value === null) {
    return true;
  }
  if (oldSetting?.value !== null && newSetting.value !== null) {
    return oldSetting?.value !== newSetting.value;
  }
  return false;
};

export const getChangedPersonalSettings = (
  newSettings: PersonalSetting[],
  oldSettings: PersonalSetting[]
): SettingShort[] => {
  const changedSettings = newSettings.filter((newSetting) => {
    const oldSetting = oldSettings.find(
      (setting) => setting.appSettingId === newSetting.appSettingId
    );
    return handlePersonalSettingComparison(newSetting, oldSetting);
  });
  return changedSettings.map((setting) => ({
    id: setting.appSettingId,
    value: setting.value,
  }));
};

const getTimezoneSetting = (settings: GlobalSetting[]): string =>
  settings.find((setting) => setting.name === SettingNameMap.defaultTimezone)?.value as string;

const getPersonalSettingValue = (
  id: number,
  personalSettings: PersonalSetting[]
): string | number | null | undefined =>
  personalSettings.find((setting) => setting.appSettingId === id)?.value;

const mergeAppSettings = (
  globalSettings: GlobalSetting[],
  personalSettings: PersonalSetting[]
): GlobalSetting[] => {
  const mergerSettings = globalSettings.map((globalSetting) => ({
    ...globalSetting,
    value: getPersonalSettingValue(globalSetting.id, personalSettings) || globalSetting.value,
  }));
  return mergerSettings;
};

export const getAppSettings = (
  globalSettings: GlobalSetting[],
  personalSettings: PersonalSetting[]
): AppSettings => {
  const appSettings = mergeAppSettings(globalSettings, personalSettings);
  return {
    [SettingNameMap.sendOnEnter]:
      appSettings.find((setting) => setting.name === SettingNameMap.sendOnEnter)?.value === 'true',
    [SettingNameMap.useSignature]:
      appSettings.find((setting) => setting.name === SettingNameMap.useSignature)?.value === 'true',
    [SettingNameMap.defaultTimezone]:
      getTimezoneSetting(appSettings) || (process.env.REACT_APP_DEFAULT_TIMEZONE as string),
    [SettingNameMap.defaultLanguage]: appSettings.find(
      (setting) => setting.name === SettingNameMap.defaultLanguage
    )?.value as string,
  };
};
