import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@state/types';
import store from '@state/store';
import toastState from '@state/toasts';
import { ToastType } from '@commonTypes/common';
import Toast from '@components/Common/Toast';
import { v4 } from 'uuid';
import styles from './ToastProvider.module.scss';
import { ToastParams, ToastProviderProps } from './ToastProvider.type';

export const addToast = (toast: ToastParams) => {
  store.dispatch(
    toastState.actions.addToastMessage({
      ...toast,
      id: v4(),
    })
  );
};

export const removeToast = (id: string) => {
  store.dispatch(toastState.actions.removeToastMessage(id));
};

const ToastProvider = ({ children }: ToastProviderProps) => {
  const toasts = useSelector((state: RootState) => state.toasts.toastsList || []);

  return (
    <>
      {children}
      <div className={styles.container}>
        {!!toasts?.length &&
          toasts.map((toast: ToastType) => {
            return (
              <Toast
                actionComponent={toast.actionComponent}
                className={styles.toast}
                duration={toast.duration}
                id={toast.id}
                key={toast.id}
                message={toast.message}
                type={toast.type}
                onClose={removeToast}
              />
            );
          })}
      </div>
    </>
  );
};

export default ToastProvider;
