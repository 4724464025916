import React, { ReactElement, createContext, useState } from 'react';
import { Attachment } from '@commonTypes/attachment';
import { FullImagePreview } from '@components/Common';

export interface FilePreviewProviderProps {
  children: ReactElement;
}

export interface FilePreviewContextProps {
  openFileInModalWindow: (file: Attachment) => void;
}

export const FilePreviewContext = createContext<FilePreviewContextProps | null>(null);

export const FilePreviewProvider = ({ children }: FilePreviewProviderProps) => {
  const [filePreview, setCurrentPreview] = useState<Attachment>();

  const openFileInModalWindow = (file: Attachment) => {
    setCurrentPreview(file);
  };

  return (
    <FilePreviewContext.Provider value={{ openFileInModalWindow }}>
      {children}

      {!!filePreview && (
        <FullImagePreview
          isOpen
          src={filePreview.paths.preview}
          name={filePreview.fileName}
          onClose={() => setCurrentPreview(undefined)}
        />
      )}
    </FilePreviewContext.Provider>
  );
};

export default FilePreviewProvider;
