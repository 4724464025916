import React, { MouseEvent, useRef, useState } from 'react';
import classNames from 'classnames';
import { RoundButtonProps } from './RoundButton.types';
import { ArrowFilledDownIcon } from '@components/Common/Icons';
import useOutsideClick from '@hooks/useOutsideClick';
import styles from './RoundButton.module.scss';
import { Loader } from '@components/Common';
import { LoadingTypes } from '@commonTypes/common';

const RoundButton = ({
  icon,
  disabled,
  children,
  dropdown,
  fullWidth,
  loading,
  size = 'default',
  variant = 'text',
  color = 'primary',
  dropdownHorizontalPosition,
  dropdownVerticalPosition,
  className,
  ...rest
}: RoundButtonProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => {
    if (isDropdownOpened) {
      setIsDropdownOpened(false);
    }
  });

  return (
    <div
      className={classNames(styles.container, {
        [styles.fullWidth]: !!fullWidth,
        [styles.dropdownOpened]: isDropdownOpened,
      })}
    >
      <button
        disabled={disabled || loading === LoadingTypes.pending}
        type="button"
        {...rest}
        className={classNames(
          styles.button,
          {
            [styles.buttonSmall]: size === 'small',
            [styles.buttonDefault]: size === 'default',
            [styles.buttonLarge]: size === 'large',
            [styles.buttonText]: variant === 'text',
            [styles.buttonContained]: variant === 'contained',
            [styles.buttonOutlined]: variant === 'outlined',
            [styles.buttonPrimary]: color === 'primary',
            [styles.buttonSuccess]: color === 'success',
            [styles.buttonDanger]: color === 'danger',
            [styles.buttonWithIcon]: icon && !children,
            [styles.buttonWithoutIcons]: !icon && loading !== LoadingTypes.pending,
          },
          className
        )}
      >
        <div className={styles.content}>
          {!(loading === LoadingTypes.pending && !children) && icon}

          <div className={styles.buttonTitle}>{children}</div>

          <Loader
            background="transparent"
            className={styles.loader}
            visible={loading === LoadingTypes.pending}
          />
        </div>

        {dropdown && loading !== LoadingTypes.pending && (
          <div
            className={styles.buttonDropdown}
            onClick={(event: MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
              setIsDropdownOpened(!isDropdownOpened);
            }}
          >
            <span className={styles.iconContainer}>
              <ArrowFilledDownIcon />
            </span>
          </div>
        )}
      </button>

      {dropdown && (
        <div
          className={classNames(styles.dropdownContent, {
            [styles.bottom]: dropdownVerticalPosition === 'bottom',
            [styles.top]: dropdownVerticalPosition === 'top',
            [styles.left]: dropdownHorizontalPosition === 'left',
            [styles.right]: dropdownHorizontalPosition === 'right',
          })}
          ref={dropdownRef}
        >
          {dropdown}
        </div>
      )}
    </div>
  );
};

export default RoundButton;
