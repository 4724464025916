import React from 'react';
import classNames from 'classnames';
import styles from './Typography.module.scss';
import { TextProps } from './Typography.types';

export const Text = ({ weight = 'normal', children, className, ...rest }: TextProps) => {
  return (
    <p className={classNames(styles.text, className, styles[weight])} {...rest}>
      {children}
    </p>
  );
};

export const TextLink = ({ weight = 'normal', children, className, ...rest }: TextProps) => {
  return (
    <p className={classNames(styles.text, styles.link, className, styles[weight])} {...rest}>
      {children}
    </p>
  );
};

export const TextInsertion = ({ weight = 'normal', children, className, ...rest }: TextProps) => {
  return (
    <span className={classNames(styles.text, className, styles[weight])} {...rest}>
      {children}
    </span>
  );
};

export const TextInfo = ({ weight = 'normal', children, className, ...rest }: TextProps) => {
  return (
    <p className={classNames(styles.text, styles.info, className, styles[weight])} {...rest}>
      {children}
    </p>
  );
};

export const H1 = ({ weight = 'bold', children, className, ...rest }: TextProps) => {
  return (
    <h1 className={classNames(styles.h1, className, styles[weight])} {...rest}>
      {children}
    </h1>
  );
};

export const H2 = ({ weight = 'bold', children, className, ...rest }: TextProps) => {
  return (
    <h2 className={classNames(styles.h2, className, styles[weight])} {...rest}>
      {children}
    </h2>
  );
};

export const H3 = ({ weight = 'bold', children, className, ...rest }: TextProps) => {
  return (
    <h3 className={classNames(styles.h3, className, styles[weight])} {...rest}>
      {children}
    </h3>
  );
};

export const H4 = ({ weight = 'bold', children, className, ...rest }: TextProps) => {
  return (
    <h4 className={classNames(styles.h4, className, styles[weight])} {...rest}>
      {children}
    </h4>
  );
};

export const H5 = ({ weight = 'bold', children, className, ...rest }: TextProps) => {
  return (
    <h5 className={classNames(styles.h5, className, styles[weight])} {...rest}>
      {children}
    </h5>
  );
};

export const H6 = ({ weight = 'bold', children, className, ...rest }: TextProps) => {
  return (
    <h6 className={classNames(styles.h6, className, styles[weight])} {...rest}>
      {children}
    </h6>
  );
};
