import React, { ReactElement } from 'react';
import { LoadingTypes } from '@commonTypes/common';
import { Loader } from '@components/Common';
import { TenantProviderProps } from './TenantProvider.types';
import useTenantProvider from './useTenantProvider';

const TenantProvider = ({ children }: TenantProviderProps): ReactElement => {
  const { tenantConfigLoading } = useTenantProvider();

  return (
    <>{tenantConfigLoading === LoadingTypes.pending ? <Loader fullSize visible /> : children}</>
  );
};

export default TenantProvider;
