import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { FetchNotePayload, PostNotePayload, CreateOrUpdateNotePayload } from './types';
import notesState from '@state/notes';

export const fetchNote = createAsyncThunk(
  'notes/fetchNote',
  async ({ userId }: FetchNotePayload, { fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.get(`users/${userId}/notes`);

      return data.note;
    } catch (error) {
      return fulfillWithValue(undefined);
    }
  }
);

export const postNewNote = createAsyncThunk(
  'notes/postNewNote',
  async ({ userId, value }: PostNotePayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.post(`users/${userId}/notes`, {
        userId,
        value,
      });

      return data.note;
    } catch (error) {
      return rejectWithValue({});
    }
  }
);

export const updateNote = createAsyncThunk(
  'notes/updateNote',
  async ({ userId, value }: PostNotePayload, { rejectWithValue }) => {
    try {
      const data = await apiClient.instance.put(`users/${userId}/notes`, {
        userId,
        value,
      });

      return data;
    } catch (error) {
      return rejectWithValue({});
    }
  }
);

export const createOrUpdateNote = createAsyncThunk(
  'notes/createOrUpdateNote',
  async ({ userId, value, noteId }: CreateOrUpdateNotePayload, { dispatch, rejectWithValue }) => {
    try {
      let data = null;

      if (noteId) {
        data = await dispatch(notesState.actions.updateNote({ value, userId })).unwrap();
      }

      if (!noteId) {
        data = dispatch(notesState.actions.postNewNote({ value, userId })).unwrap();
      }

      if (!data) {
        return rejectWithValue({});
      }
    } catch (error) {
      return rejectWithValue({});
    }
  }
);
