import { createAsyncThunk } from '@reduxjs/toolkit';
import messagesState from '@state/messages';
import filesState from '@state/channelsFiles';
import channelsState from '@state/channels';
import departmentsState from '@state/departments';
import activityState from '@state/activity';

import {
  FetchAIMessagesSuggestionsPayload,
  FetchMessagesPayload,
  PostMessagePayload,
  PostMessageReadPayload,
  PostNewRecipientMessagePayload,
  PostRetryMessagePayload,
  ReadMessageAndUpdateCounterPayload,
} from './types';
import apiClient from '@api';
import { Attachment } from '@commonTypes/attachment';
import { LoadingTypes } from '@commonTypes/common';

export const fetchMessages = createAsyncThunk(
  'messages/fetchMessages',
  async ({ channelId, beforeMessageId, amount }: FetchMessagesPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('channel-entries', {
        params: {
          ChannelId: channelId,
          BeforeMessageId: beforeMessageId,
          Take: amount,
        },
      });

      return data.channelEntries;
    } catch (error) {
      return rejectWithValue([]);
    }
  }
);

export const postNewMessage = createAsyncThunk(
  'messages/postMessage',
  async (
    { channelId, body, fileIds, isSigned, conversationId }: PostMessagePayload,
    { fulfillWithValue }
  ) => {
    try {
      const { data } = await apiClient.instance.post('messages', {
        channelId,
        body,
        fileIds,
        isSigned,
        conversationId,
      });

      return data;
    } catch (error) {
      return fulfillWithValue({});
    }
  }
);

export const postMessageToNewRecipient = createAsyncThunk(
  'messages/postMessageToNewRecipient',
  async (
    {
      channelType,
      body,
      fileIds,
      isSigned,
      departmentId,
      fieldAgentIdentifier,
    }: PostNewRecipientMessagePayload,
    { fulfillWithValue }
  ) => {
    try {
      const { data } = await apiClient.instance.post('messages/new-recipient', {
        channelType,
        body,
        fileIds,
        isSigned,
        departmentId,
        fieldAgentIdentifier,
      });

      return data;
    } catch (error) {
      return fulfillWithValue({});
    }
  }
);

export const postSendNewMessage = createAsyncThunk(
  'messages/postSendNewMessage',
  async ({ message, conversationId }: PostRetryMessagePayload, { dispatch, rejectWithValue }) => {
    try {
      const fileIds = message?.files?.map(({ id }: Attachment) => id) || [];
      await dispatch(messagesState.actions.addMessage(message));

      const data = await dispatch(
        messagesState.actions.postNewMessage({
          body: message.body || '',
          channelId: message.channelId,
          conversationId,
          isSigned: message.isSigned,
          fileIds,
        })
      ).unwrap();

      if (data?.message) {
        dispatch(
          messagesState.actions.modifyMessage({
            messageId: message.id,
            data: { ...data.message, loading: LoadingTypes.fulfilled },
          })
        );
        dispatch(channelsState.actions.modifyChannelsByLastEntry(data.message));

        if (data?.message?.files) {
          dispatch(
            filesState.actions.addFiles({
              channelId: message.channelId,
              data: data?.message?.files,
            })
          );
        }
      } else {
        dispatch(
          messagesState.actions.modifyMessage({
            messageId: message.id,
            data: { ...message, loading: LoadingTypes.rejected },
          })
        );
        dispatch(channelsState.actions.modifyChannelsByLastEntry(message));
      }
    } catch (error) {
      return rejectWithValue({});
    }
  }
);

export const postRetryExistingMessage = createAsyncThunk(
  'messages/postRetryFailedMessage',
  async ({ message }: PostRetryMessagePayload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(
        messagesState.actions.modifyMessage({
          messageId: message.id,
          data: { ...message, loading: LoadingTypes.pending },
        })
      );

      const { data } = await apiClient.instance.put(`messages/resend/${message.id}`);

      if (data) {
        dispatch(
          messagesState.actions.modifyMessage({
            messageId: message.id,
            data: { ...data, loading: LoadingTypes.fulfilled },
          })
        );
      }

      return data;
    } catch (error) {
      dispatch(
        messagesState.actions.modifyMessage({
          messageId: message.id,
          data: { ...message, loading: LoadingTypes.rejected },
        })
      );
      return rejectWithValue({});
    }
  }
);

export const postRetryLocalMessage = createAsyncThunk(
  'messages/postRetryMessage',
  async ({ message, conversationId }: PostRetryMessagePayload, { dispatch, rejectWithValue }) => {
    try {
      const messageData = {
        conversationId,
        channelId: message.channelId,
        fileIds: message?.files?.map(({ id }: Attachment) => id) || [],
        body: message.body || '',
        isSigned: message.isSigned,
      };

      dispatch(
        messagesState.actions.modifyMessage({
          messageId: message.id,
          data: { ...message, loading: LoadingTypes.pending },
        })
      );

      const data = await dispatch(messagesState.actions.postNewMessage(messageData)).unwrap();

      if (data?.message) {
        await dispatch(
          messagesState.actions.removeMessage({
            messageId: message.id,
            channelId: message.channelId,
          })
        );

        dispatch(messagesState.actions.addMessage(data.message));
        dispatch(channelsState.actions.modifyChannelsByLastEntry(data.message));
      } else {
        dispatch(
          messagesState.actions.modifyMessage({
            messageId: message.id,
            data: { ...message, loading: LoadingTypes.rejected },
          })
        );

        return rejectWithValue(
          'Failed to send message. Please check your internet connection and try again.'
        );
      }
    } catch (error) {
      return rejectWithValue(
        'Failed to send message. Please check your internet connection and try again.'
      );
    }
  }
);

export const postMessageRead = createAsyncThunk(
  'messages/postMessageRead',
  async ({ messageId }: PostMessageReadPayload, { fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.post(`channel-entries/read/${messageId}`);
      return data;
    } catch (error) {
      return fulfillWithValue(undefined);
    }
  }
);

export const readMessageAndUpdateCounter = createAsyncThunk(
  'messages/readMessageAndUpdateCounter',
  async (
    {
      departmentId,
      messageId,
      activeChannelId,
      unreadMessagesCount,
    }: ReadMessageAndUpdateCounterPayload,
    { dispatch, fulfillWithValue }
  ) => {
    try {
      const response = await dispatch(postMessageRead({ messageId })).unwrap();
      if (response) {
        dispatch(
          departmentsState.actions.decreaseDepartmentUnreadCount({
            value: unreadMessagesCount,
            departmentId,
          })
        );
        dispatch(
          channelsState.actions.decreaseChannelsMessagesCounter({
            channelId: activeChannelId,
            value: unreadMessagesCount,
          })
        );

        dispatch(
          activityState.actions.decreaseActiveChannelMessagesCounter({
            channelId: activeChannelId,
            value: unreadMessagesCount,
          })
        );

        dispatch(
          activityState.actions.decreaseActiveDepartmentMessagesCounter({
            value: unreadMessagesCount,
            departmentId,
          })
        );
        return response;
      }
      return fulfillWithValue(undefined);
    } catch (error) {
      return fulfillWithValue(undefined);
    }
  }
);

export const fetchAIMessagesSuggestions = createAsyncThunk(
  'messages/fetchAIMessagesSuggestions',
  async ({ channelId }: FetchAIMessagesSuggestionsPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.post('ai-generated-messages', {
        channelId,
      });

      return data;
    } catch (error) {
      // return rejectWithValue(AIMessages.AIMessagesFetchFailed);
      return rejectWithValue('Failed to fetch AI suggestions. Please try again');
    }
  }
);
