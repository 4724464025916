import React, { MouseEvent, useState } from 'react';
import { Button, FileUploader, Dropdown, EmojiPicker, TextInfo } from '@components/Common';
import {
  EmojiIcon,
  ImageIcon,
  PencilIcon,
  RobotIcon,
  TemplateIcon,
} from '@components/Common/Icons';
import { MessageAreaControlsProps } from './MessageAreaControls.types';
import styles from './MessageAreaControls.module.scss';

const MessageAreaControls = ({
  withEmojiControl,
  withTemplateControl,
  withSignControl,
  withAISuggestionControl,
  withFileUploadingControl,
  filesAccept,
  templates,
  isMessageSigned,
  onEmojiSelect,
  onTemplateSelect,
  onFileSelect,
  onAISuggestionClicked,
  onMessageSign,
  ...rest
}: MessageAreaControlsProps) => {
  const [isEmojisOpen, setIsEmojisOpen] = useState(false);
  const [isMessageTemplatesOpen, setIsMessageTemplatesOpen] = useState(false);

  return (
    <div
      {...rest}
      className={styles.container}
      onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      {withFileUploadingControl && (
        <FileUploader acceptFiles={filesAccept} onFileUpload={onFileSelect}>
          <Button className={styles.controlsButton} icon={<ImageIcon />} />
        </FileUploader>
      )}

      <Dropdown
        open={isEmojisOpen}
        offsetVertical={16}
        contentClassName={styles.emojiPickerContainer}
        containerClassName={styles.dropdownContainer}
        content={
          <EmojiPicker
            onEmojiSelect={(emoji) => {
              onEmojiSelect(emoji);
              setIsEmojisOpen(!isEmojisOpen);
            }}
          />
        }
        onVisibilityChange={setIsEmojisOpen}
      >
        <Button active={isEmojisOpen} className={styles.controlsButton} icon={<EmojiIcon />} />
      </Dropdown>

      <Dropdown
        offsetVertical={16}
        contentClassName={styles.templatesContainer}
        containerClassName={styles.dropdownContainer}
        content={
          <>
            {!!templates?.length ? (
              templates?.map((template) => (
                <div
                  className={styles.templateButton}
                  key={template.id}
                  onClick={() => {
                    onTemplateSelect(template);
                    setIsMessageTemplatesOpen(!isMessageTemplatesOpen);
                  }}
                >
                  {template.title}
                </div>
              ))
            ) : (
              <TextInfo>No messages templates found</TextInfo>
            )}
          </>
        }
        onVisibilityChange={setIsMessageTemplatesOpen}
      >
        <Button
          active={isMessageTemplatesOpen}
          className={styles.controlsButton}
          icon={<TemplateIcon />}
        />
      </Dropdown>

      {process.env.REACT_APP_MESSAGES_AI_SUGGESTIONS === 'true' && (
        <Button
          className={styles.controlsButton}
          icon={<RobotIcon />}
          onClick={onAISuggestionClicked}
        />
      )}

      <Button
        active={isMessageSigned}
        className={styles.controlsButton}
        icon={<PencilIcon />}
        onClick={() => onMessageSign(!isMessageSigned)}
      />
    </div>
  );
};

export default MessageAreaControls;
