import { useState } from 'react';

const useDriverBottomControls = () => {
  const [isNoteControlOpen, setIsNoteControlOpen] = useState<boolean>(false);
  const [isSamsaraControlOpen, setIsSamsaraControlOpen] = useState<boolean>(false);
  const [isLocationControlOpen, setIsLocationControlOpen] = useState<boolean>(false);

  return {
    isNoteControlOpen,
    isSamsaraControlOpen,
    isLocationControlOpen,
    setIsNoteControlOpen,
    setIsSamsaraControlOpen,
    setIsLocationControlOpen,
  };
};

export default useDriverBottomControls;
