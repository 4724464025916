export const validators = {
  username: {
    required: 'Username is required',
  },
  password: {
    required: 'Password is required',
  },
  tenant: {
    required: 'Company ID is required',
  },
};
