import React from 'react';
import classNames from 'classnames';
import { MessageItemProps } from './MessageItem.types';
import MessageAttachment from '@components/Common/MessageItem/MessageAttachment';
import { MultipleUsersIcon } from '@components/Common/Icons';
import styles from './MessageItem.module.scss';

const MessageItem = (props: MessageItemProps) => {
  return (
    <div
      className={classNames(styles.container, props.className, {
        [styles.messageOwner]: props.isOwner,
        [styles.error]: !!props.error,
      })}
    >
      <div
        className={classNames(styles.text, {
          [styles.hasIcon]: props.isMass,
        })}
      >
        {props.body && <p>{props.body}</p>}

        {props.files?.map((file) => (
          <div className={styles.attachmentContainer} key={`message-attachment-${file.id}`}>
            <MessageAttachment {...file} onFilePreview={() => props.onFilePreview?.(file)} />
          </div>
        ))}
      </div>
      {props.isMass && (
        <div className={styles.massIcon}>
          <MultipleUsersIcon />
        </div>
      )}
    </div>
  );
};

export default MessageItem;
