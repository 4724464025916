// This is a temporary state for storing user preferences, which must be persisted upon login/logout.
// It will be replaced with BE implementation (/users-settings).
import { createSlice } from '@reduxjs/toolkit';
import { ActivityState } from './types';

export const initialState: ActivityState = {
  data: {
    notificationsEnabled: true,
  },
};

export const persistPreferencesSlice = createSlice({
  name: 'persistPreferences',
  initialState,
  reducers: {
    setNotificationEnabledStatus: (state, { payload }) => {
      state.data.notificationsEnabled = payload;
    },
  },
});

export const actions = persistPreferencesSlice.actions;
export default persistPreferencesSlice.reducer;
