import React, { useState, useRef, useEffect } from 'react';
import { flushSync } from 'react-dom';
import classNames from 'classnames';
import { Portal, RenderIf } from '@components/Common';
import { ModalProps } from './Modal.types';
import styles from './Modal.module.scss';

const Modal = ({ children, visible, className }: ModalProps) => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const hideModalWithAnimation = () => {
    if (isRemoving) {
      flushSync(() => {
        setIsRemoving(false);
        setIsModalOpened(false);
      });
    }
  };

  useEffect(() => {
    if (visible && !isModalOpened) {
      setIsModalOpened(true);
    }

    if (!visible && isModalOpened) {
      setIsRemoving(true);
    }
  }, [isModalOpened, visible]);

  useEffect(() => {
    if (!isRemoving && isModalOpened) {
      setIsModalOpened(false);
    }
  }, [isRemoving]);

  return <RenderIf isTrue={isModalOpened}>
    <Portal>
      <div
        className={
          classNames(styles.overlay, {
            [styles.removing]: isRemoving,
          })
        }
        onAnimationEnd={hideModalWithAnimation}
      >
        <div className={classNames(styles.container, className)} ref={modalRef}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </Portal>
  </RenderIf>;
};

export default Modal;
