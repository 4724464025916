import * as thunks from './thunks';
import reducer, { actions, initialState } from './reducer';

const allActions = {
  ...thunks,
  ...actions,
};

export default {
  actions: allActions,
  reducer,
  initialState,
};
