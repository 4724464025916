import React from 'react';
import classNames from 'classnames';
import styles from './Audio.module.scss';
import { AudioProps } from './Audio.types';

const Audio = (props: AudioProps) => {
  return (
    <div onClick={props.onClick} className={classNames(styles.container, props.className)}>
      <audio controls src={props.srcLarge}>
        Audio player not supported by your browser
      </audio>
    </div>
  );
};

export default Audio;
