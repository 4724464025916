import React from 'react';
import { NavBarProps } from './NavBar.types';
import { H6 } from '@components/Common';
import classNames from 'classnames';
import styles from './NavBar.module.scss';

const NavBar = (props: NavBarProps) => {
  return (
    <div className={classNames(props.className, styles.container)}>
      {props.leftControl && props.leftControl}

      <H6 className={styles.name} weight="bold">
        {props.title}
      </H6>

      {props.rightControl && props.rightControl}
    </div>
  );
};

export default NavBar;
