import React from 'react';
import classNames from 'classnames';
import { H4 } from '@components/Common';
import { ActionButtonLinkProps } from './ActionButtonLink.types';
import styles from './ActionButtonLink.module.scss';

const ActionButtonLink = (props: ActionButtonLinkProps) => {
  return (
    <button {...props} className={classNames(styles.container, props.className)}>
      <H4>{props.title}</H4>
    </button>
  );
};

export default ActionButtonLink;
