import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { PutPersonalSettingsPayload } from './types';

export const fetchPersonalSettings = createAsyncThunk(
  'personalSettings/getPersonalSettings',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('user-settings', {
        params: {
          OverridableOnly: true,
        },
      });

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch your preferences. Please try again later or contact support for assistance.'
      );
    }
  }
);

export const updatePersonalSettings = createAsyncThunk(
  'personalSettings/updatePersonalSettings',
  async (updatedPersonalSettings: PutPersonalSettingsPayload[], { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.put('user-settings', updatedPersonalSettings);

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to update your preferences. Please check that all fields are filled in correctly and try again.'
      );
    }
  }
);

export const resetPersonalSetting = createAsyncThunk(
  'personalSettings/resetPersonalSetting',
  async (id: number, { rejectWithValue }) => {
    try {
      await apiClient.instance.delete(`user-settings/${id}`);

      return id;
    } catch (error) {
      return rejectWithValue('Failed to reset your preferences. Please try again later.');
    }
  }
);
