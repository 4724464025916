import React from 'react';
import { Text } from '@components/Common';
import { FileDocumentProps } from './FileDocument.types';
import styles from './FileDocument.module.scss';

const FileDocument = (props: FileDocumentProps) => {
  return (
    <div className={styles.container}>
      <Text>{props.ext}</Text>
    </div>
  );
};

export default FileDocument;
