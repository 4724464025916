import React from 'react';
import classNames from 'classnames';
import { MenuDetailedItemProps } from './MenuDetailedItem.types';
import { Avatar, H4, H6 } from '@components/Common';
import styles from './MenuDetailedItem.module.scss';

const MenuDetailedItem = (props: MenuDetailedItemProps) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      <Avatar>{props.initials}</Avatar>

      <div className={styles.titleContainer}>
        <H4>{props.title}</H4>
        {props.subTitle && <H6 weight="normal">{props.subTitle}</H6>}
      </div>

      {props.actionButton && props.actionButton}
    </div>
  );
};

export default MenuDetailedItem;
