import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { generateMessageStub } from '@utils';
import { MessageAreaValues } from '@components/Conversation/MessageArea/MessageArea.types';
import messagesState from '@state/messages';
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@constants';
import activityState from '@state/activity';
import { Attachment } from '@commonTypes/attachment';
import { FilePreviewContext } from '@providers/FilePreviewProvider';

const useConversation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const filesModalContext = useContext(FilePreviewContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { activeChannel } = useSelector((state: RootState) => state.activity.data);
  const appInstanceId = useSelector((state: RootState) => state.settings?.appInstanceId || '');
  const [isDriverDetailsOpen, setIsDriverDetailsOpen] = useState<boolean>(false);

  const onRedirectToChannelsList = () => {
    navigate(ROUTES.channels);
    dispatch(activityState.actions.setActiveChannel(undefined));
  };

  const onMessageSend = ({ attachments, textValue, isSigned }: MessageAreaValues) => {
    if (activeChannel) {
      const message = generateMessageStub({
        body: textValue,
        channelId: activeChannel.id,
        isSigned,
        files: attachments,
      });

      if (message) {
        dispatch(
          messagesState.actions.postSendNewMessage({ message, conversationId: appInstanceId })
        );
      }
    }
  };

  const onFilePreview = (file: Attachment) => {
    if (file.mimeType.includes('image')) {
      filesModalContext?.openFileInModalWindow(file);
    }
  };

  useEffect(() => {
    const isDriverDetailsActive = location.state?.isDriverDetailsActive;

    if (isDriverDetailsActive) {
      setIsDriverDetailsOpen(true);
    }
  }, [location]);

  return {
    activeChannel,
    isDriverDetailsOpen,
    setIsDriverDetailsOpen,
    onFilePreview,
    onMessageSend,
    onRedirectToChannelsList,
  };
};

export default useConversation;
