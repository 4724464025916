import { Channel as PusherChannel } from 'pusher-js';
import {
  MassChannelEntry,
  MessageText,
  ScheduledMessage,
  MessageTemplate,
} from '@commonTypes/message';
import { Channel } from '@commonTypes/channel';
import { User, UserShort } from '@commonTypes/user';
import { SamsaraData } from '@commonTypes/samsaraData';
import { SettingShort } from '@commonTypes/settings';
// import { PermissionGroup } from '@commonTypes/permissionGroup';
// import { Permission } from '@commonTypes/permission';

export type PusherContextTypes = PusherChannel | undefined;

export interface EventsProviderProps {
  children: JSX.Element;
}

export enum PusherEventTypes {
  subscriptionSuccess = 'pusher:subscription_succeeded',
  subscriptionError = 'pusher:subscription_error',
  messageReceived = 'MESSAGE_CREATED',
  channelCreated = 'NEW_CHANNELS_CREATED',
  phoneCallInProgress = 'PHONE_CALL_IN_PROGRESS',
  channelSubscribersChanged = 'CHANNEL_SUBSCRIBERS_CHANGED',
  samsaraDriverStateChanged = 'SAMSARA_DRIVER_STATE_UPDATED',
  globalSettingsStateChanged = 'APP_SETTINGS_UPDATED',
  personalSettingsStateChanged = 'USER_SETTINGS_UPDATED',
  scheduledMessageCreated = 'SCHEDULED_MESSAGE_CREATED',
  scheduledMessageUpdated = 'SCHEDULED_MESSAGE_UPDATED',
  scheduledMessageDeleted = 'SCHEDULED_MESSAGE_DELETED',
  scheduledMessageSent = 'SCHEDULED_MESSAGE_SENT',
  messageTemplateCreated = 'MESSAGE_TEMPLATE_CREATED',
  messageTemplateUpdated = 'MESSAGE_TEMPLATE_UPDATED',
  messageTemplateDeleted = 'MESSAGE_TEMPLATE_DELETED',
  massMessageReceived = 'MASS_MESSAGE_CREATED',
  massMessageChannelCreated = 'MASS_MESSAGE_CHANNEL_CREATED',
  permissionGroupChanged = 'PERMISSION_GROUP_EVENT',
  permissionGroupPermissionsChanged = 'PERMISSION_GROUP_PERMISSIONS_CHANGED',
  permissionGroupUsersChanged = 'PERMISSION_GROUP_USERS_CHANGED',
  tenantConfigurationChanged = 'TENANT_CONFIGURATION_CHANGED',
}

export interface MessageReceivedEventPayload {
  conversationId: string;
  message: MessageText;
}

export interface ChannelCreatedEventPayload extends Array<Channel> {}

export interface SubscribersChangedEventPayload {
  channelId: number;
  departmentId: number;
  subscribers: User[];
}

export interface ScheduledMessageSentEventPayload {
  message: ScheduledMessage;
  scheduledMessageId: number;
}

export interface ScheduledMessageCreatedEventPayload extends ScheduledMessage {}

export interface MassMessageReceivedPayload {
  conversationId: string;
  massMessage: MassChannelEntry;
}

export interface MassChannelCreatedPayload extends Channel {}

export interface ScheduledMessageDeletedEventPayload {
  scheduledMessageId: number;
  channelId: number;
}

export interface SamsaraDriverStateChangedPayload extends SamsaraData {}

export interface GlobalSettingsStateChangedPayload extends SettingShort {}

export interface PersonalSettingsStateChangedPayload {
  userId: number;
  items: SettingShort[];
}

export interface MessageTemplateCreatedEventPayload extends MessageTemplate {}

export interface MessageTemplateUpdatedEventPayload extends MessageTemplate {}

export interface MessageTemplateDeletedEventPayload {
  messageTemplateId: number;
}

export interface PermissionGroupChangedPayload {
  permissionGroupEventType: number;
}

// export interface PermissionGroupChangedPayload extends PermissionGroup {
//   permissionGroupEventType: number;
// }

export interface PermissionGroupPermissionsChangedPayload {
  permissionGroupId: number;
  // permissionsInGroup: Permission[];
}

export interface PermissionGroupUsersChangedPayload {
  permissionGroupId: number;
  usersInGroup: UserShort[];
}

export interface TenantConfigChangedPayload {
  EventId: string;
  TenantShortName: string;
}

export enum PermissionGroupEventType {
  Created = 0,
  Updated = 1,
  Deleted = 2,
}
