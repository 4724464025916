import { Client } from '@pusher/push-notifications-web';

export let pusherBeamsClient: Client | undefined;

export const setupPusherBeamsClient = (pusherBeamsInstanceId?: string | null) => {
  try {
    let instanceId = pusherBeamsInstanceId;

    if (!instanceId) {
      instanceId = process.env.REACT_APP_BEAMS_INSTANCE_ID as string;
    }

    pusherBeamsClient = new Client({
      instanceId,
    });
  } catch (error) {
    console.log('[Beams]: Failed to set beams client. Error:', error);
  }
};
