import { createSlice } from '@reduxjs/toolkit';
import { MassMessagesState } from './types';
import { LoadingTypes } from '@commonTypes/common';
import {
  fetchMassMessages,
  getMassMessageChannelFiles,
  getMassMessageChannelRecipients,
} from './thunks';

export const initialState: MassMessagesState = {
  messages: {},
  recipients: {},
  files: {},
};

export const messagesSlice = createSlice({
  name: 'massMessages',
  initialState,
  reducers: {
    addMassMessage: (state, action) => {
      const { massMessageChannelId } = action.payload;
      const prevState = state.messages[massMessageChannelId] || {};
      prevState?.data?.push(action.payload);

      state.messages[massMessageChannelId] = {
        ...prevState,
      };
    },
    modifyMassMessage: (state, action) => {
      const { messageId, data } = action.payload;
      const { massMessageChannelId } = data;

      const prevState = state.messages[massMessageChannelId] || {};
      const updatedData = (prevState?.data || []).map((message) =>
        message.id === messageId ? { ...message, ...data } : message
      );

      state.messages[massMessageChannelId] = {
        ...prevState,
        data: updatedData,
      };
    },
    addMassFiles: (state, action) => {
      const { massMessageChannelId, data } = action.payload;
      const prevData = state.files[massMessageChannelId]?.data || [];
      const newData = data || [];

      state.files[massMessageChannelId] = {
        data: [...prevData, ...newData],
        loading: LoadingTypes.fulfilled,
      };
    },
    modifyTagRecipientAssigneesCount: (state, action) => {
      const { massMessageChannelId, tagId, recipientsCount } = action.payload;
      const prevData = state.recipients[massMessageChannelId]?.data;

      if (prevData?.tags.length) {
        const modifiedTags = prevData.tags.map((tag) => {
          if (tag.id === tagId && tag.fieldAgentsCount !== recipientsCount) {
            tag.fieldAgentsCount = recipientsCount;
          }
          return tag;
        });

        state.recipients[massMessageChannelId].data = {
          ...prevData,
          tags: modifiedTags,
        };
      }
    },
    removeMassMessage: (state, action) => {
      const { messageId, massMessageChannelId } = action.payload;

      const prevState = state.messages[massMessageChannelId];

      if (prevState.data) {
        prevState.data = prevState.data.filter((message) => message.id !== messageId);

        state.messages[massMessageChannelId] = {
          ...prevState,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMassMessages.pending, (state, { meta }) => {
      const massMessageChannelId = meta.arg.massMessageChannelId;
      const prevData = state.messages[massMessageChannelId] || {};

      state.messages[massMessageChannelId] = {
        ...prevData,
        loading: LoadingTypes.pending,
      };
    });

    builder.addCase(fetchMassMessages.fulfilled, (state, { payload, meta }) => {
      const massMessageChannelId = meta.arg.massMessageChannelId;
      const prevData = state.messages[massMessageChannelId] || {};

      state.messages[massMessageChannelId] = {
        data: [...payload, ...(prevData?.data || [])],
        loading: LoadingTypes.fulfilled,
      };
    });

    builder.addCase(fetchMassMessages.rejected, (state, { meta }) => {
      const massMessageChannelId = meta.arg.massMessageChannelId;
      const prevData = state.messages[massMessageChannelId] || {};

      state.messages[massMessageChannelId] = {
        ...prevData,
        loading: LoadingTypes.rejected,
      };
    });

    builder.addCase(getMassMessageChannelRecipients.pending, (state, { meta }) => {
      const massMessageChannelId = meta.arg.massMessagesChannelId;
      const prevData = state.recipients[massMessageChannelId] || {};

      state.recipients[massMessageChannelId] = {
        ...prevData,
        loading: LoadingTypes.pending,
      };
    });

    builder.addCase(getMassMessageChannelRecipients.fulfilled, (state, { payload, meta }) => {
      const massMessageChannelId = meta.arg.massMessagesChannelId;

      state.recipients[massMessageChannelId] = {
        data: payload,
        loading: LoadingTypes.fulfilled,
      };
    });

    builder.addCase(getMassMessageChannelRecipients.rejected, (state, { meta }) => {
      const massMessageChannelId = meta.arg.massMessagesChannelId;
      const prevData = state.recipients[massMessageChannelId] || {};

      state.recipients[massMessageChannelId] = {
        ...prevData,
        loading: LoadingTypes.rejected,
      };
    });

    builder.addCase(getMassMessageChannelFiles.pending, (state, { meta }) => {
      const massMessageChannelId = meta.arg.massMessageChannelId;

      state.files[massMessageChannelId] = {
        ...(state.files[massMessageChannelId] || {}),
        loading: LoadingTypes.pending,
      };
    });

    builder.addCase(getMassMessageChannelFiles.fulfilled, (state, { payload, meta }) => {
      const massMessageChannelId = meta.arg.massMessageChannelId;
      const prevFiles = state.files?.[massMessageChannelId]?.data || [];

      state.files[massMessageChannelId] = {
        data: [...payload, ...prevFiles],
        loading: LoadingTypes.fulfilled,
      };
    });

    builder.addCase(getMassMessageChannelFiles.rejected, (state, { meta }) => {
      const massMessageChannelId = meta.arg.massMessageChannelId;

      state.files[massMessageChannelId] = {
        ...(state.files[massMessageChannelId] || {}),
        loading: LoadingTypes.rejected,
      };
    });
  },
});

export const actions = messagesSlice.actions;
export default messagesSlice.reducer;
