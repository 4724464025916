import { v4 } from 'uuid';

export const generateNumberId = () => {
  return Date.now() + Math.random();
};

export const generateStringId = () => {
  return v4();
};

export const getInitials = (value: string) => {
  try {
    const initials = value.split(' ');

    if (initials?.length === 1) {
      return `${initials[0][0].toUpperCase()}`;
    } else {
      return `${initials[0][0].toUpperCase()}${initials[1][0].toUpperCase()}`;
    }
  } catch (error) {
    return 'NP';
  }
};

export const iOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
