import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextField, RoundButton, MultiSelect, Checkbox } from '@components/Common';
import { UserAttributeField } from '@components/User';
import { UserEditFormProps } from './UserEditForm.types';
import FormContainer from '../FormContainer';
import useUserEditForm from './useUserEditForm';
import { Option } from 'commonTypes/common';
import { validators } from './UserEditForm.data';
import { InfoIcon } from '@components/Common/Icons';
import styles from './UserEditForm.module.scss';

const UserEditForm = (props: UserEditFormProps) => {
  const {
    loading,
    saving,
    tags,
    errorMessage,
    attributes,
    initialValues,
    tagsOptions,
    onTagsFilter,
    tagsLoading,
    onError,
    onCancel,
    onSubmit,
    resetErrorState,
  } = props;

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isDirty, isValidating },
  } = useForm();

  const { groupValuesBeforeSubmit } = useUserEditForm({
    onSubmit,
    tagsOptions,
    attributes,
  });

  useEffect(() => {
    if (!!errorMessage) {
      resetErrorState?.();
    }
  }, [isValidating]);

  return (
    <FormContainer
      className={styles.container}
      {...{
        loading,
        saving,
      }}
    >
      <div className={styles.content}>
        <TextField
          className={styles.field}
          defaultValue={initialValues.firstName}
          error={errors?.firstName?.message?.toString()}
          inputSize="small"
          label={'First name'}
          placeholder={'Enter first name...'}
          {...register('firstName', validators.firstName)}
        />

        <TextField
          className={styles.field}
          defaultValue={initialValues.lastName}
          error={errors?.lastName?.message?.toString()}
          inputSize="small"
          label={'Last name'}
          placeholder={'Enter last name...'}
          {...register('lastName', validators.lastName)}
        />

        {!!attributes?.length &&
          attributes?.map((customAttribute) => (
            <Controller
              control={control}
              defaultValue={customAttribute.value || customAttribute.defaultValue}
              key={`attributes-${customAttribute.title}`}
              name={`customAttributes.${customAttribute.title}`}
              render={({ field, fieldState: { error } }) => (
                <UserAttributeField
                  className={styles.field}
                  defaultValue={customAttribute.value || customAttribute.defaultValue}
                  error={error?.message || !!errorMessage}
                  inputSize="small"
                  label={customAttribute.title}
                  subLabel={!customAttribute.isRequired ? 'optional' : ''}
                  type={customAttribute.type}
                  value={field.value}
                  onNumberValueChange={field.onChange}
                  onTextValueChange={field.onChange}
                />
              )}
              rules={{
                required:
                  (customAttribute.isRequired && validators.customAttribute.required) ||
                  !!errorMessage,
              }}
            />
          ))}

        <Controller
          control={control}
          defaultValue={tags}
          name="tagsValues"
          render={({ field, fieldState: { error } }) => (
            <MultiSelect
              className={styles.field}
              emptyMessage={'No tags found'}
              // error={error?.message}
              initLoading={tagsLoading}
              label={<p>Tags</p>}
              loadMoreMessage={'Loading more tags'}
              options={tagsOptions}
              placeholder={'Add tags.'}
              size="small"
              value={field.value}
              onFilter={onTagsFilter}
              onSelectChange={(option) => field.onChange((field.value || []).concat(option))}
              onSelectRemove={(option) =>
                field.onChange(field.value?.filter((tag: Option) => tag.value !== option.value))
              }
            />
          )}
        />

        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      </div>

      <div className={styles.controls}>
        {!initialValues.isVerified && (
          <div className={styles.verifying}>
            <Controller
              control={control}
              defaultValue={initialValues.isVerified}
              name="isVerified"
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  label={'This is a verified driver'}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                />
              )}
            />
            <div className={styles.infoIcon} title={'This driver will be marked as verified'}>
              <InfoIcon />
            </div>
          </div>
        )}
        <div className={styles.buttons}>
          <RoundButton onClick={onCancel}>Cancel</RoundButton>
          <RoundButton
            color="success"
            disabled={!isDirty}
            size="small"
            type="button"
            variant="contained"
            onClick={() => handleSubmit(groupValuesBeforeSubmit, onError)()}
          >
            Save
          </RoundButton>
        </div>
      </div>
    </FormContainer>
  );
};

export default UserEditForm;
