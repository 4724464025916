import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { FetchTagsPayload, CreateTagPayload, EditTagPayload, GetMassChannelTag } from './types';

export const fetchTags = createAsyncThunk(
  'tags/fetchTags',
  async (params: FetchTagsPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/tags', {
        params: params,
      });

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch tags. Please try again later or contact support for assistance.'
      );
    }
  }
);

export const createTag = createAsyncThunk(
  'tags/createTag',
  async (tagPayload: CreateTagPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.post('/tags', tagPayload);

      if (data?.errors?.length) {
        return rejectWithValue(data?.errors?.join('\n'));
      }

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to create tag. Please check that all fields are filled in correctly and try again.'
      );
    }
  }
);

export const updateTag = createAsyncThunk(
  'tags/updateTag',
  async ({ id, title }: EditTagPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.put(`/tags/${id}`, { title: title });

      if (data?.errors?.length) {
        return rejectWithValue(data?.errors?.join('\n'));
      }

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to update tag. Please check that all fields are filled in correctly and try again.'
      );
    }
  }
);

export const deleteTag = createAsyncThunk(
  'tags/deleteTag',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.delete(`/tags/${id}`);

      if (data?.errors?.length) {
        return rejectWithValue(data?.errors?.join('\n'));
      }

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to delete tag. Please try again later or contact support for assistance.'
      );
    }
  }
);

export const verifyMassChannelTag = createAsyncThunk(
  'tags/verifyMassChannelTag',
  async ({ id }: GetMassChannelTag, { fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.get(`mass-message-channels/tags/${id}`);
      return data;
    } catch (error) {
      return fulfillWithValue(null);
    }
  }
);
