import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { AxiosError } from 'axios';

export const fetchEnabledChannelTypes = createAsyncThunk(
  'settings/fetchEnabledChannelTypes',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('channels/enabled-channel-types');

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 401) {
        return fulfillWithValue([]);
      }

      console.log('[Settings: Enabled channels ]: Failed to fetch enabled channel types', error);
      return rejectWithValue([]);
    }
  }
);

export const fetchPhoneCallsOperationStatus = createAsyncThunk(
  'settings/fetchPhoneCallsOperationStatus',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('phone-calls/operational-status');

      return data?.operationalStatus === 'Enabled';
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 401) {
        return fulfillWithValue(false);
      }

      console.log('[Settings: Phone calls status]: Failed to fetch phone calls status', error);
      return rejectWithValue(false);
    }
  }
);

export const fetchTwilioToken = createAsyncThunk(
  'settings/fetchTwilioToken',
  async (_, { fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/phone-calls/access-token');

      return data;
    } catch (error) {
      return fulfillWithValue({});
    }
  }
);

export const fetchFilesLimitations = createAsyncThunk(
  'channelsFiles/fetchFilesLimitations',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('files/limits');

      return data.channelTypesFileOptions;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 401) {
        return fulfillWithValue([]);
      }
      console.log('[Settings: Files limits]: Failed to fetch channelsFiles limits ', error);
      return rejectWithValue([]);
    }
  }
);

export const fetchTenantConfiguration = createAsyncThunk(
  'auth/fetchTenantConfiguration',
  async (_, { fulfillWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/tenant-configuration/front-end-specific');
      return data;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 401) {
        return fulfillWithValue(undefined);
      }

      return fulfillWithValue(undefined);
    }
  }
);
