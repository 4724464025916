import React from 'react';
import { NavLayout } from '@components/Navigation';
import {
  ChannelsEntriesList,
  MessageArea,
  ConversationsDetails,
  DriverHeader,
} from '@components/Conversation';
import useConversation from './useConversation';
import { MenuIcon } from '@components/Common/Icons';
import { RoundButton } from '@components/Common';
import styles from './Conversation.module.scss';

const Conversation = () => {
  const {
    isDriverDetailsOpen,
    setIsDriverDetailsOpen,
    activeChannel,
    onFilePreview,
    onMessageSend,
    onRedirectToChannelsList,
  } = useConversation();
  return (
    <NavLayout
      className={styles.container}
      backButton={
        <RoundButton variant="text" icon={<MenuIcon />} onClick={onRedirectToChannelsList} />
      }
    >
      {activeChannel && (
        <>
          {activeChannel?.fieldAgent && (
            <DriverHeader
              isUserDetailsButtonActive={isDriverDetailsOpen}
              driverFullName={`${activeChannel.fieldAgent.firstName} ${activeChannel.fieldAgent.lastName}`}
              driverPhoneNumber={activeChannel.fieldAgent.phoneNumber}
              onOpenDriverDetails={() => setIsDriverDetailsOpen(!isDriverDetailsOpen)}
            />
          )}

          {!isDriverDetailsOpen && (
            <div className={styles.conversationContainer}>
              <div className={styles.entriesContainer}>
                <ChannelsEntriesList />
              </div>

              <div className={styles.messageArea}>
                <MessageArea
                  className={styles.messageArea}
                  initialRows={3}
                  controls={['send-button', 'file-uploading']}
                  currentChannelType={activeChannel?.type}
                  onMessageSend={onMessageSend}
                  onFilePreview={onFilePreview}
                />
              </div>
            </div>
          )}

          {isDriverDetailsOpen && <ConversationsDetails />}
        </>
      )}
    </NavLayout>
  );
};

export default Conversation;
