import React from 'react';
import { ConversationsDetailsProps, TabsValuesTypes } from './ConversationsDetails.types';
import ConversationToolbar from './ConversationToolbar';
import classNames from 'classnames';
import { Tab, TabsGroup } from '@components/Common';
import { FilesDoubleIcon, NeutralProfileIcon } from '@components/Common/Icons';
import { UserInfo } from '@components/User';
import ConversationFiles from './ConversationFiles';
import DriverBottomControls from './DriverBottomControls';
import useConversationDetails from './useConversationDetails';
import styles from './ConversationsDetails.module.scss';

const ConversationsDetails = (props: ConversationsDetailsProps) => {
  const {
    active,
    setActive,
    activeChannel,
    isDriverEdit,
    setIsDriverEdit,
    onRedirectToSubscribers,
  } = useConversationDetails();
  return (
    <div className={classNames(styles.container, props.className)}>
      <ConversationToolbar
        className={styles.toolbar}
        onEditButtonClick={() => setIsDriverEdit(!isDriverEdit)}
        onSubscribersButtonClick={onRedirectToSubscribers}
      />

      <TabsGroup>
        <Tab
          active={active === TabsValuesTypes.details}
          icon={<NeutralProfileIcon />}
          onClick={() => setActive(TabsValuesTypes.details)}
        >
          Details
        </Tab>
        <Tab
          active={active === TabsValuesTypes.files}
          icon={<FilesDoubleIcon />}
          onClick={() => setActive(TabsValuesTypes.files)}
        >
          Files
        </Tab>
      </TabsGroup>

      {active === TabsValuesTypes.details && (
        <>
          {activeChannel?.fieldAgent?.id && (
            <UserInfo
              userId={activeChannel.fieldAgent.id}
              isUserEdit={isDriverEdit}
              onEditClose={() => setIsDriverEdit(false)}
            />
          )}
        </>
      )}

      {active === TabsValuesTypes.files && <ConversationFiles />}

      {activeChannel?.fieldAgent?.id && (
        <DriverBottomControls driverId={activeChannel?.fieldAgent?.id} />
      )}
    </div>
  );
};

export default ConversationsDetails;
