import { LoadingTypes } from '@commonTypes/common';
import { createSlice } from '@reduxjs/toolkit';
import { GlobalSetting, SettingShort } from '@commonTypes/settings';
import { fetchPersonalSettings, resetPersonalSetting, updatePersonalSettings } from './thunks';
import { PersonalSettingsState } from './types';

export const initialState: PersonalSettingsState = {
  data: [],
  loading: LoadingTypes.idle,
};

export const personalSettingsSlice = createSlice({
  name: 'personalSettings',
  initialState,
  reducers: {
    updateDefaultValue: (state, { payload }) => {
      const newSettings = state.data.map((oldSetting) => {
        const newSetting = payload.find(
          (setting: GlobalSetting) => setting.id === oldSetting.appSettingId
        );
        return newSetting ? { ...oldSetting, defaultValue: newSetting?.value } : oldSetting;
      });
      state.data = newSettings;
    },
    setPersonalSettings: (state, { payload }) => {
      const newState = state.data.map((setting) => {
        const updatedSetting = payload.find(
          (newSetting: SettingShort) => newSetting.id === setting.appSettingId
        );
        return updatedSetting ? { ...setting, value: updatedSetting.value } : setting;
      });
      state.data = newState;
    },
    updatePersonalSettingsState: (state, { payload }) => {
      state.data = payload;
    },
    resetPersonalSettingValue: (state, { payload }) => {
      const id = payload;
      const newState = state.data.map((setting) =>
        setting.appSettingId === id ? { ...setting, value: null } : setting
      );
      state.data = newState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPersonalSettings.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchPersonalSettings.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchPersonalSettings.fulfilled, (state, { payload }) => {
      state.data = payload || [];
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(updatePersonalSettings.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(updatePersonalSettings.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(updatePersonalSettings.fulfilled, (state) => {
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(resetPersonalSetting.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(resetPersonalSetting.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(resetPersonalSetting.fulfilled, (state) => {
      state.loading = LoadingTypes.fulfilled;
    });
  },
});

export const actions = personalSettingsSlice.actions;
export default personalSettingsSlice.reducer;
