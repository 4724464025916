import React from 'react';
import { Text } from '@components/Common';
import { UserDetailsPreviewItemProps } from './UserInfoView.types';
import styles from './UserInfoView.module.scss';

const UserInfoViewItem = ({ id, label, children }: UserDetailsPreviewItemProps) => {
  return (
    <div className={styles.detailsItem} id={id}>
      <Text className={styles.detailsItemHeader}>{label}</Text>
      {children || 'None'}
    </div>
  );
};

export default UserInfoViewItem;
