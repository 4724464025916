import React from 'react';
import styles from './MessagePhoneCall.module.scss';
import classNames from 'classnames';
import { MessagePhoneCallProps } from './MessagePhoneCall.types';
import { TextInfo } from '@components/Common';
import { dt } from '@utils';

const MessagePhoneCall = ({ caller, ringingAt, className }: MessagePhoneCallProps) => {
  // This component will be updated
  return (
    <div className={classNames(styles.container, className)}>
      <TextInfo>
        <>
          Phone call from {caller?.firstName} ${caller?.lastName}
          {ringingAt && <span>· {dt(ringingAt)}</span>}
        </>
      </TextInfo>
    </div>
  );
};

export default MessagePhoneCall;
