import React from 'react';
import { UserEditProps } from './UserEdit.types';
import { UserEditForm } from '@forms';
import useUserEdit from './useUserEdit';
import styles from './UserEdit.module.scss';

const UserEdit = (props: UserEditProps) => {
  const {
    PAGE_SIZE,
    tags,
    tagsLoading,
    currentUserAttributes,
    currentUserDetails,
    editUserFormLoading,
    errorMessage,
    setErrorMessage,
    onFetchTags,
    onUserUpdate,
  } = useUserEdit(props);

  return (
    <div className={styles.container}>
      <UserEditForm
        attributes={currentUserAttributes}
        errorMessage={errorMessage}
        initialValues={{
          type: currentUserDetails?.type,
          isVerified: currentUserDetails?.isVerified,
          firstName: currentUserDetails?.firstName || '',
          lastName: currentUserDetails?.lastName || '',
        }}
        loading={editUserFormLoading}
        resetErrorState={() => setErrorMessage('')}
        tags={currentUserDetails?.tags?.map((tag) => ({
          value: tag.id,
          label: tag.title,
        }))}
        tagsLoading={tagsLoading}
        tagsOptions={tags?.map((tag) => ({
          value: tag.id,
          label: tag.title,
        }))}
        onCancel={props.onClose}
        onSubmit={onUserUpdate}
        onTagsFilter={(searchValue) =>
          onFetchTags({ skip: 0, take: PAGE_SIZE, query: searchValue })
        }
      />
    </div>
  );
};

export default UserEdit;
