import React from 'react';
import classNames from 'classnames';
import { Avatar, Button, TextLink } from '@components/Common';
import { MessageItemControlsProps } from './MessageItemControls.types';
import { StarDefaultIcon } from '@components/Common/Icons';
import styles from './MessageItemControls.module.scss';

const MessageItemControls = ({
  children,
  senderInitials,
  errorMessage = '',
  isStarred,
  onStar,
  onRetry,
  onRemove,
  withStarring,
  messagePosition = 'left',
  withSenderAvatar,
  withErrorState,
  withDelete,
  className,
  bottomControlsComponent,
}: MessageItemControlsProps) => {
  return (
    <div
      className={classNames(styles.container, className, {
        [styles.right]: messagePosition === 'right',
        [styles.left]: messagePosition === 'left',
      })}
    >
      {withSenderAvatar && (
        <Avatar className={styles.avatar} size="small">
          {senderInitials}
        </Avatar>
      )}

      <div>
        <div className={styles.textContainer}>
          {children}

          {withStarring && (
            <Button
              className={classNames(styles.starButton, {
                [styles.starred]: !!isStarred,
              })}
              icon={<StarDefaultIcon />}
              onClick={onStar}
            />
          )}
        </div>

        <div className={styles.dateTime}>
          {withErrorState && (
            <>
              <span className={styles.transparentText}>You · </span>
              <span className={styles.redText}>Error {errorMessage} </span>
              <TextLink className={styles.retryLink} onClick={onRetry}>
                Retry
              </TextLink>

              {withDelete && (
                <TextLink className={styles.retryLink} onClick={onRemove}>
                  Delete
                </TextLink>
              )}
            </>
          )}

          {bottomControlsComponent}
        </div>
      </div>
    </div>
  );
};

export default MessageItemControls;
