import React from 'react';
import classNames from 'classnames';
import styles from './UserInfoView.module.scss';
import { UserDetailsPreviewProps } from './UserInfoView.types';
import UserInfoViewItem from './UserInfoViewItem';
import { Tag } from '@components/Common';
import { UserRoles, UserTypeLabels } from '@commonTypes/user';

const UserInfoView = ({ className, user, attributes }: UserDetailsPreviewProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      {user?.type !== UserRoles.fieldAgent && (
        <>
          <UserInfoViewItem label={'Type'}>{UserTypeLabels[user.type]}</UserInfoViewItem>

          <UserInfoViewItem label={'Email'}>{user?.email}</UserInfoViewItem>
          <UserInfoViewItem label="Email">{user?.email}</UserInfoViewItem>

          <UserInfoViewItem label={'Username'}>{user?.userName}</UserInfoViewItem>

          <UserInfoViewItem label={'Signature'}>{user?.signature}</UserInfoViewItem>
        </>
      )}

      {!!attributes?.length &&
        attributes.map((attribute) => (
          <UserInfoViewItem key={`user-custom-attribute-${attribute.id}`} label={attribute.title}>
            {attribute?.value || attribute?.defaultValue}
          </UserInfoViewItem>
        ))}

      {user.type === UserRoles.fieldAgent && (
        <UserInfoViewItem className={styles.tags} label="Tags">
          <div className={styles.tags}>
            {user?.tags?.length
              ? user?.tags?.map((tag) => (
                  <Tag key={`users-tag-${tag.id}`} label={tag.title} value={tag.id} />
                ))
              : 'None'}
          </div>
        </UserInfoViewItem>
      )}
    </div>
  );
};

export default UserInfoView;
