import React from 'react';
import classNames from 'classnames';
import { formatPhoneNumberToLocalUS } from '@utils';
import { RoundButton, H4, H5, Button } from '@components/Common';
import { DriverHeaderProps } from './DriverHeader.types';
import { UserIcon, CallIcon } from '@components/Common/Icons';
import styles from './DriverHeader.module.scss';

const DriverHeader = (props: DriverHeaderProps) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      <div className={styles.nameContainer}>
        <H4>{props.driverFullName}</H4>
        <H5 weight="normal">{formatPhoneNumberToLocalUS(props.driverPhoneNumber)}</H5>
      </div>

      <div className={styles.controls}>
        {props.withCalls && (
          <RoundButton
            color="success"
            icon={<CallIcon />}
            size="default"
            variant="contained"
            onClick={props.onCallToDriver}
          />
        )}

        <Button
          variant="text"
          size="medium"
          active={props.isUserDetailsButtonActive}
          className={styles.userButton}
          icon={<UserIcon />}
          onClick={props.onOpenDriverDetails}
        />
      </div>
    </div>
  );
};

export default DriverHeader;
