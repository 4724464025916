import { createSlice } from '@reduxjs/toolkit';
import { LoadingTypes } from '@commonTypes/common';
import { MySubscriptionsStateState } from './types';
import { fetchCurrentUserSubscriptions } from './thunks';

export const initialState: MySubscriptionsStateState = {
  data: [],
  loading: LoadingTypes.idle,
};

export const currentUserSubscriptionsSlice = createSlice({
  name: 'currentUserSubscriptions',
  initialState,
  reducers: {
    subscribeCurrentUser: (state, { payload }) => {
      const prevData = [...(state.data || [])];
      const subscriptionExist = prevData?.find(
        (subscription) => subscription.channelId === payload.channelId
      );

      if (!subscriptionExist) {
        prevData.push(payload);
        state.data = prevData;
      }
    },
    unsubscribeCurrentUser: (state, { payload }) => {
      const newData = state.data?.filter(
        (subscription) => subscription.channelId !== payload.channelId
      );
      state.data = newData || [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUserSubscriptions.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchCurrentUserSubscriptions.rejected, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchCurrentUserSubscriptions.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.loading = LoadingTypes.fulfilled;
    });
  },
});

export const actions = currentUserSubscriptionsSlice.actions;
export default currentUserSubscriptionsSlice.reducer;
