import axios from 'axios';
import authState from '@state/auth';

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL as string,
});

// type should be updated to correct
const setupApiClient = (store: any) => {
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(authState.actions.reset());
      }

      return Promise.reject(error);
    }
  );
};

export default {
  instance,
  setupApiClient,
};
