import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { PutGlobalSettingsPayload } from './types';

export const fetchGlobalSettings = createAsyncThunk(
  'globalSettings/getGlobalSettings',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('app-settings');

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to fetch global settings. Please try again later or contact support for assistance.'
      );
    }
  }
);

export const updateGlobalSettings = createAsyncThunk(
  'globalSettings/updateGlobalSettings',
  async ({ updatedGlobalSettings }: PutGlobalSettingsPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.put('app-settings', updatedGlobalSettings);

      return data;
    } catch (error) {
      return rejectWithValue(
        'Failed to update global settings. Please check that all fields have correct format and try again.'
      );
    }
  }
);
