import React from 'react';
import classNames from 'classnames';
import { UserInfoProps } from './UserInfo.types';
import UserInfoView from '../UserInfoView';
import useUsersInfo from './useUserInfo';
import styles from './UserInfo.module.scss';
import { LoadingTypes } from '@commonTypes/common';
import { Loader } from '@components/Common';
import { UserEdit } from '@components/User';

const UserInfo = (props: UserInfoProps) => {
  const { userDetails, userDetailsLoading } = useUsersInfo(props);

  return (
    <div className={classNames(styles.container, props.className)}>
      {userDetailsLoading === LoadingTypes.pending && <Loader fullSize visible />}

      {userDetailsLoading !== LoadingTypes.pending && userDetails && (
        <>
          {props.isUserEdit ? (
            <UserEdit editUserId={props.userId} onClose={props.onEditClose} />
          ) : (
            <UserInfoView user={userDetails} />
          )}
        </>
      )}
    </div>
  );
};

export default UserInfo;
