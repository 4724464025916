import store from '@state/store';
import dayjs from 'dayjs';
import { dt, generateNumberId } from '@utils';
import { LoadingTypes } from '@commonTypes/common';
import { ChannelEntry, MessageSendStatuses, MessageTypes } from '@commonTypes/message';
import { Attachment } from '@commonTypes/attachment';
import { User, UserRoles, UserShort } from '@commonTypes/user';
import { useSelector } from 'react-redux';
import { RootState } from '@state/types';

interface GenerateMessageType {
  body: string;
  channelId: number;
  isSigned: boolean;
  files: Attachment[];
}

export const generateMessageStub = (params: GenerateMessageType): ChannelEntry | undefined => {
  try {
    const userData = store.getState().currentUser.profile?.data as UserShort;
    const activeDepartment = store.getState().activity?.data?.activeDepartment;

    return {
      id: generateNumberId(),
      departmentId: activeDepartment?.id as number,
      body: params.body,
      files: params.files,
      channelId: params.channelId,
      sentAt: dt(new Date(), ''),
      createdAt: dt(new Date(), ''),
      updatedAt: dt(new Date(), ''),
      type: MessageTypes.text,
      isStarred: false,
      isSigned: params.isSigned,
      loading: LoadingTypes.pending,
      status: MessageSendStatuses.sent,
      isPartOfMassMessage: false,
      sender: {
        id: userData.id,
        type: UserRoles.handler,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        userName: userData.userName,
        phoneNumber: userData.phoneNumber,
        signature: userData.signature,
        isVerified: true,
        updatedAt: userData.updatedAt,
        createdAt: userData.createdAt,
      },
    };
  } catch (error) {
    return undefined;
  }
};

export const isUserMessageOwner = (message: ChannelEntry, currentUserId?: number) => {
  if (message.type === MessageTypes.text) {
    return currentUserId === message?.sender?.id;
  }

  if (message.type === MessageTypes.phoneCall) {
    return currentUserId === message?.caller?.id;
  }

  return false;
};

export const formatCardLastMessageDate = (date?: string) => {
  const weeksSame = dayjs().isSame(date, 'week');
  const daysSame = dayjs().isSame(date, 'day');
  const yearSame = dayjs().isSame(date, 'year');

  if (daysSame) {
    return 'Today';
  }

  if (weeksSame) {
    return dt(date, 'ddd');
  }

  if (yearSame) {
    return dt(date, 'D MMM');
  }

  return dt(date, 'MM/DD/YYYY');
};
