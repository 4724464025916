import React, { ChangeEvent } from 'react';
import { AttributesTypes } from '@commonTypes/user';
import { TextArea, TextField } from '@components/Common';
import { UserAttributeFieldProps } from './UserAttributeField.types';

const UserAttributeField = ({
  id,
  value,
  error,
  label,
  subLabel,
  defaultValue,
  className,
  ...rest
}: UserAttributeFieldProps) => {
  switch (rest.type) {
    case AttributesTypes.text:
      return (
        <TextField
          className={className}
          defaultValue={defaultValue}
          error={error}
          id={`attribute-${id}`}
          inputSize="small"
          label={label}
          subLabel={subLabel}
          value={value}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            rest?.onTextValueChange(target.value)
          }
        />
      );

    case AttributesTypes.longText:
      return (
        <TextArea
          className={className}
          defaultValue={defaultValue}
          error={error}
          id={`attribute-${id}`}
          inputSize="small"
          label={label}
          subLabel={subLabel}
          value={value}
          onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) =>
            rest?.onTextValueChange(target.value)
          }
        />
      );

    case AttributesTypes.number:
      return (
        <TextField
          className={className}
          defaultValue={defaultValue}
          error={error}
          id={`attribute-${id}`}
          inputSize="small"
          label={label}
          min={0}
          subLabel={subLabel}
          type="number"
          value={value}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            rest?.onNumberValueChange(+target.value)
          }
        />
      );

    default:
      return (
        <TextField
          className={className}
          defaultValue={defaultValue}
          error={error}
          id={`attribute-${id}`}
          inputSize="small"
          label={label}
          subLabel={subLabel}
          value={value}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            rest?.onTextValueChange(target.value)
          }
        />
      );
  }
};

export default UserAttributeField;
