import React from 'react';
import { EmojiPickerProps } from './EmojiPicker.types';
import EmojiPickerComponent from 'emoji-picker-react';
import './EmojiPicker.styles.scss';

const EmojiPicker = (props: EmojiPickerProps) => {
  return (
    <EmojiPickerComponent
      width="100%"
      searchDisabled
      onEmojiClick={({ emoji }) => props.onEmojiSelect(emoji)}
    />
  );
};

export default EmojiPicker;
