import { createSlice } from '@reduxjs/toolkit';
import {
  fetchDepartments,
  // fetchDepartmentUnreadCounter,
} from './thunks';
import { LoadingTypes } from '@commonTypes/common';
import { DepartmentsState } from './types';

export const initialState: DepartmentsState = {
  data: {
    list: [],
    loading: LoadingTypes.idle,
  },
};

export const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    increaseDepartmentUnreadCount: (state, { payload }) => {
      const { departmentId, value } = payload;

      state.data.list = state.data.list?.map((department) => {
        if (department.id === departmentId) {
          department.unreadChannelEntryCount += value;
        }

        return department;
      });
    },
    decreaseDepartmentUnreadCount: (state, { payload }) => {
      const { departmentId, value } = payload;

      state.data.list = state.data.list?.map((department) => {
        if (department.id === departmentId && department.unreadChannelEntryCount > 0) {
          department.unreadChannelEntryCount -= value;
        }

        return department;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDepartments.pending, (state) => {
      state.data.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchDepartments.rejected, (state) => {
      state.data.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchDepartments.fulfilled, (state, { payload }) => {
      state.data.list = payload;
      state.data.loading = LoadingTypes.fulfilled;
    });
  },
});

export const actions = departmentsSlice.actions;
export default departmentsSlice.reducer;
