import { createSlice } from '@reduxjs/toolkit';
import { LoadingTypes } from '@commonTypes/common';
import { CustomAttributesState } from './types';
import { fetchUserAttributesValues } from './thunks';
import { putUser } from '@state/users/thunks';

export const initialState: CustomAttributesState = {
  data: {},
  loading: LoadingTypes.idle,
  total: 0,
};

export const usersAttributesSlice = createSlice({
  name: 'usersAttributes',
  initialState,
  reducers: {
    updateUserAttributesLocally: (state, { payload }) => {
      const userId = payload.id;
      const newAttributes = payload.attributes;

      state.data[userId] = newAttributes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserAttributesValues.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchUserAttributesValues.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchUserAttributesValues.fulfilled, (state, { meta, payload }) => {
      const userId = meta.arg.id;

      state.data[userId] = payload || [];
      state.total = payload.totalCount;
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(putUser.fulfilled, (state, { meta }) => {
      const userId = meta.arg.userId;
      const newAttrs = meta.arg.params?.attributes;

      const updatedAttrs = state.data[userId]?.map((oldAttr) => {
        const updated = newAttrs?.find((newAttr) => newAttr.userAttributeId === oldAttr.id);
        if (updated) {
          oldAttr.value = updated.value;
        }

        return oldAttr;
      });

      state.data[userId] = updatedAttrs;
    });
  },
});

export const actions = usersAttributesSlice.actions;
export default usersAttributesSlice.reducer;
