import { LoadingTypes } from '@commonTypes/common';
import { generateNumberId, generateUserStub } from '@utils';
import { Attachment, AttachmentPaths } from '@commonTypes/attachment';
import { ChannelTypes } from '@commonTypes/channel';
import { ChannelFilesLimit, ChannelFilesLimitOptions } from '@commonTypes/attachment';
import { UserRoles, UserShort } from '@commonTypes/user';

export const generateImageStub = (file: File, loading = LoadingTypes.pending): Attachment => {
  return {
    id: generateNumberId(),
    type: file.type,
    mimeType: file.type,
    fileSize: file.size,
    fileName: file.name,
    storageFileName: '',
    paths: {
      thumb: '',
      preview: URL.createObjectURL(file),
      large: '',
    },
    ext: '',
    sender: generateUserStub(),
    createdAt: new Date().toString(),
    updatedAt: '',
    loading,
  };
};

export const getFileOwner = (file: Attachment, currentUserId?: number) => {
  const isOwner = currentUserId === file.sender.id;

  return `${isOwner ? 'You' : file.sender.firstName + ' ' + file.sender.lastName} `;
};

export const downloadFile = (url: string, name: string) => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const urls = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = urls;
      link.download = name;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    });
};

export const getChannelFilesLimits = (
  channelType?: ChannelTypes,
  filesLimitsList?: ChannelFilesLimit[]
) => {
  try {
    return filesLimitsList?.find((fileLimit) => fileLimit.type === channelType)?.options;
  } catch (error) {
    return undefined;
  }
};

export const convertBytesToMb = (bytes: number) => {
  try {
    const fileSizeInMb = bytes / Math.pow(1024, 2);
    return fileSizeInMb.toFixed(1);
  } catch (error) {
    return bytes;
  }
};

export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const transformChannelsFilesLimitsToList = (
  filesLimits?: ChannelFilesLimitOptions['allowedContentTypes']
) => {
  try {
    if (!filesLimits) return [];

    const keys = Object.values(filesLimits);
    return keys.reduce((contentList: string[], contentValue: string[]) => {
      return [...(contentList || []), ...(contentValue || [])];
    }, []);
  } catch (error) {
    return [];
  }
};

export const getChannelAllowedFiles = (
  channelType?: ChannelTypes,
  filesLimitsList?: ChannelFilesLimit[]
) => {
  try {
    const channelLimits = getChannelFilesLimits(channelType, filesLimitsList);
    return transformChannelsFilesLimitsToList(channelLimits?.allowedContentTypes);
  } catch (error) {
    return undefined;
  }
};

export const getChannelMaxFilesSize = (
  channelType?: ChannelTypes,
  filesLimitsList?: ChannelFilesLimit[]
) => {
  try {
    return getChannelFilesLimits(channelType, filesLimitsList)?.maxFileSizeInMb;
  } catch (error) {
    return undefined;
  }
};
