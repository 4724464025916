import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DateType } from '@commonTypes/common';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(localizedFormat);

export const getTzDate = (date?: DateType) => {
  return dayjs.utc(date).tz();
};

export const dt = (date?: DateType, format = 'h:mm A') => {
  return getTzDate(date).format(format);
};

export const uploadingDate = (date: DateType) => {
  return `${dt(date, 'MMM DD')} at ${dt(date, 'h:mm A')}`;
};

export const setDefaultTimezone = (tz?: string) => {
  if (tz) {
    dayjs.tz.setDefault(tz);
  }
};

export const toMinutesAndSeconds = (date: DateType) => {
  return dt(date, 's');
};

export const formatDisplayDate = (date?: string) => {
  const weekSame = getTzDate().isSame(date, 'week');
  const daySame = getTzDate().isSame(date, 'day');

  if (daySame) {
    return dt(date, 'h:mm A');
  }

  if (weekSame) {
    return dt(date, 'ddd h:mm A');
  }

  return dt(date, 'MMM D, h:mm A');
};

export const dateToTimePickerValue = (value?: string | Date) => {
  const d = getTzDate(value);
  const formatted12 = d.format('hh:mm A');
  const hour = d.get('hours');
  const minute = d.get('minutes');

  return {
    hour,
    minute,
    formatted12,
  };
};

export const getTzDateAsJsDateObject = (date?: string | DateType) => {
  const d = getTzDate(date);
  return new Date(
    d.get('years'),
    d.get('months'),
    d.get('date'),
    d.get('hours'),
    d.get('minutes'),
    d.get('seconds'),
    d.get('milliseconds')
  );
};
