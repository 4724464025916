import React from 'react';
import classNames from 'classnames';
import { DriverBottomControlsProps } from './DriverBottomControls.types';
import { Dropdown, RoundButton } from '@components/Common';
import { FileIcon } from '@components/Common/Icons';
import useDriverBottomControls from './useDriverBottomControls';
import styles from './DriverBottomControls.module.scss';
import DriverNote from './DriverNote';

const DriverBottomControls = (props: DriverBottomControlsProps) => {
  const { isNoteControlOpen, setIsNoteControlOpen } = useDriverBottomControls();
  return (
    <div className={classNames(styles.container, props.className)}>
      <Dropdown
        open={isNoteControlOpen}
        offsetVertical={11}
        contentClassName={styles.dropdownContent}
        containerClassName={styles.dropdownContainer}
        content={
          <DriverNote onClose={() => setIsNoteControlOpen(false)} notesUserId={props.driverId} />
        }
        onVisibilityChange={setIsNoteControlOpen}
      >
        <RoundButton
          icon={<FileIcon />}
          size="default"
          variant="outlined"
          onClick={() => setIsNoteControlOpen(!isNoteControlOpen)}
        />
      </Dropdown>
    </div>
  );
};

export default DriverBottomControls;
