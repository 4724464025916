import React, { ReactElement } from 'react';
import { AppVisibilityStateProviderProps } from './AppVisibilityStateProvider.types';
import useAppVisibilityStateProvider from './useAppVisibilityStateProvider';

const AppVisibilityStateProvider = ({
  children,
}: AppVisibilityStateProviderProps): ReactElement => {
  useAppVisibilityStateProvider();

  return children;
};

export default AppVisibilityStateProvider;
