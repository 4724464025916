import React, { useEffect, useRef, useState } from 'react';
import { SingleRecipientSearchProps } from './SingleRecipientSearch.types';
import { DebouncedTextField, Dropdown, Loader } from '@components/Common';
import { CheckIcon } from '@components/Common/Icons';
import { LoadingTypes } from '@commonTypes/common';
import { MassMessageRecipient, MessageRecipientType } from '@commonTypes/massMessage';
import styles from './SingleRecipientSearch.module.scss';
import SearchItem from './SearchItem';

const SingleRecipientSearch = (props: SingleRecipientSearchProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const getRecipientItemKey = (recipient: MassMessageRecipient) => {
    if (recipient.type === MessageRecipientType.tag) {
      return `recipient-tag-${recipient.id}`;
    }
    if (recipient.type === MessageRecipientType.fieldAgent) {
      return `recipient-driver-${recipient.id}-channel-${recipient.channelId}`;
    }
  };

  const onDropdownVisibilityChange = (value: boolean) => {
    if (props.recipients?.length) {
      setIsDropdownOpen(value);
    }
  };

  useEffect(() => {
    if (!isDropdownOpen && (props.recipients.length || props.loading === LoadingTypes.pending)) {
      setIsDropdownOpen(true);
    }

    if (isDropdownOpen && !props.recipients.length && props.loading !== LoadingTypes.pending) {
      setIsDropdownOpen(false);
    }
  }, [props.loading, props.recipients]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Dropdown
      contentClassName={styles.contentContainer}
      containerClassName={styles.triggerContainer}
      offsetVertical={13}
      content={
        <>
          <Loader
            fullSize
            className={styles.loader}
            visible={props.loading === LoadingTypes.pending}
          />

          {props.recipients.map((channel) => (
            <SearchItem
              key={getRecipientItemKey(channel)}
              channel={channel}
              onClick={() => {
                setIsDropdownOpen(false);
                props.onRecipientSelect(channel);
                inputRef.current?.focus();
              }}
            />
          ))}
        </>
      }
      open={isDropdownOpen}
      onVisibilityChange={onDropdownVisibilityChange}
    >
      <DebouncedTextField
        className={styles.searchField}
        delay={350}
        iconRight={
          props.isPhoneNumberValid ? (
            <div className={styles.checkIcon}>
              <CheckIcon />
            </div>
          ) : undefined
        }
        placeholder={'Type in number or name...'}
        ref={inputRef}
        success={props.isPhoneNumberValid}
        value={props.phoneNumberValue || ''}
        onSearch={props.onSearch}
      />
    </Dropdown>
  );
};

export default SingleRecipientSearch;
