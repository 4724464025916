import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import departmentsState from '@state/departments';
import currentUserState from '@state/currentUser';
import settingsState from '@state/settings';
import globalSettingsState from '@state/globalSettings';
import personalSettingsState from '@state/personalSettings';
import currentUserSubscriptionsState from '@state/currentUserSubscriptions';
import { Loader } from '@components/Common';
import { LoadingTypes } from '@commonTypes/common';

export interface RequiredDtaProviderProps {
  children: ReactElement;
}

const RequiredDataProvider = ({ children }: RequiredDtaProviderProps): ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { session } = useSelector((state: RootState) => state.auth?.data) || {};
  const filesLimits = useSelector((state: RootState) => state.settings.filesLimits);
  const departments = useSelector((state: RootState) => state.departments);

  useEffect(() => {
    if (session?.sessionId) {
      dispatch(settingsState.actions.setAppInstanceId());
      dispatch(departmentsState.actions.fetchDepartments());
      dispatch(currentUserState.actions.fetchUser());
      dispatch(globalSettingsState.actions.fetchGlobalSettings());
      dispatch(personalSettingsState.actions.fetchPersonalSettings());
      dispatch(currentUserSubscriptionsState.actions.fetchCurrentUserSubscriptions());

      if (filesLimits?.loading === LoadingTypes.idle)
        dispatch(settingsState.actions.fetchFilesLimitations());
    }
  }, [session, dispatch]);

  useEffect(() => {
    if (departments.data.loading === LoadingTypes.rejected) {
      throw new Error('Failed to fetch required data.');
    }
  }, [departments.data.loading]);

  return (
    <>
      {departments.data.loading === LoadingTypes.pending ? <Loader fullSize visible /> : children}
    </>
  );
};

export default RequiredDataProvider;
