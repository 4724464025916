export const validators = {
  firstName: {
    required: 'Firstname is required',
    minLength: {
      value: 2,
      message: 'Firstname is too short',
    },
  },
  lastName: {
    required: 'Lastname is required',
    minLength: {
      value: 2,
      message: 'Lastname is too short',
    },
  },
  customAttribute: {
    required: 'This field is required',
  },
};
