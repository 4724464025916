import { createSlice } from '@reduxjs/toolkit';
import { signIn, signOut } from './thunks';
import { AuthState } from './types';

export const initialState: AuthState = {
  data: {
    session: undefined,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.data = {
        session: action.payload,
      };
    });

    builder.addCase(signOut.fulfilled, (state) => {
      state.data = undefined;
    });
  },
});

export const actions = authSlice.actions;
export default authSlice.reducer;
