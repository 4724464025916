import React from 'react';
import { ChannelsCardProps } from './ChannelsCard.types';
import { CallIcon, WhatsAppIcon } from '@components/Common/Icons';
import { ChannelTypes } from '@commonTypes/channel';

const ChannelsCardIcon = ({ channelType }: Partial<ChannelsCardProps>) => {
  switch (channelType) {
    case ChannelTypes.fieldAgentPhone:
      return <CallIcon />;

    case ChannelTypes.fieldAgentWhatsApp:
      return <WhatsAppIcon />;

    default:
      return <CallIcon />;
  }
};

export default ChannelsCardIcon;
