import { ChannelTypes } from '@commonTypes/channel';

export interface Tag {
  id: number;
  title: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export enum UserRoles {
  fieldAgent = 'FieldAgent',
  handler = 'Handler',
  specialist = 'Specialist',
  manager = 'Manager',
  admin = 'Admin',
}

export enum UserOriginTypes {
  nonApplicable = 'NonApplicable',
  autoGenerated = 'AutoGenerated',
  user = 'User',
  phone = 'Phone',
}

export interface UserShort {
  id: number;
  type: UserRoles;
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  phoneNumber: string;
  signature: string;
  isVerified: boolean;
  createdAt: string;
  updatedAt: string;
}

// export interface UserShort extends Pick<User, 'id' | 'firstName' | 'lastName'> {}
export interface User {
  id: number;
  type: UserRoles;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  userName: string;
  createdAt: string;
  updatedAt: string;
  tags?: Tag[];
  isVerified?: boolean;
  identifiers?: Identifier[];
  signature: string;
  lastActivityAt: string;
  createdBy?: string;
  createdById?: number;
  origin?: UserOriginTypes;
  lastUpdatedById?: number;
  lastUpdatedBy?: string;
}

export interface Identifier {
  identifier: string;
  departmentId: number;
  channelType: ChannelTypes;
  channelId: number;
}

export interface CurrentUserDetails {
  accessToken: string;
  email: string;
  expiresIn: number;
  firstName: string;
  jti: string;
  lastName: string;
  phoneNumber: string;
  userId: number;
  userName: string;
}

export const UserTypeLabels = {
  [UserRoles.fieldAgent]: 'Driver',
  [UserRoles.handler]: 'Dispatcher',
  [UserRoles.specialist]: 'Specialist',
  [UserRoles.manager]: 'Manager',
  [UserRoles.admin]: 'Admin',
};

export enum AttributesTypes {
  text = 'Text',
  longText = 'LongText',
  number = 'Number',
  date = 'Date',
  dateTime = 'DateTime',
  dateRange = 'DateRange',
  address = 'Address',
}

export const AttributesTypesLabels = {
  [AttributesTypes.text]: 'common.text',
  [AttributesTypes.longText]: 'common.longText',
  [AttributesTypes.number]: 'common.number',
  [AttributesTypes.date]: 'common.date',
  [AttributesTypes.dateTime]: 'common.dateTime',
  [AttributesTypes.dateRange]: 'common.dateRange',
  [AttributesTypes.address]: 'common.address',
};

export interface UserAttributesShort {
  userAttributeId: number;
  value: string | number; // could be added more types
}

export interface UserAttribute {
  id: number;
  sortOrder: number;
  isRequired: boolean;
  type: AttributesTypes;
  defaultValue?: string | number;
  value?: string | number;
  name: string;
  title: string;
  icon?: string;
  userType?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface DriverNote {
  id: number;
  value: string;
  authorId: string;
  author: User;
  notable: string;
  notableId: number;
  createdAt: string;
  updatedAt: string;
}
