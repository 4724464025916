import React from 'react';
import classNames from 'classnames';
import { DepartmentLinkProps } from './DepartmentLink.types';
import styles from './DepartmentLink.module.scss';

const DepartmentLink = ({
  departmentName,
  badge,
  departmentInitials,
  active,
  onClick,
}: DepartmentLinkProps) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.active]: active,
      })}
      onClick={onClick}
    >
      <div className={styles.logo}>
        {badge && <div className={styles.badge} />}
        {departmentInitials}
      </div>

      <p className={styles.title}>{departmentName}</p>
    </div>
  );
};

export default DepartmentLink;
