import React from 'react';
import { LoadingTypes } from '@commonTypes/common';
import classNames from 'classnames';
import { Loader } from '@components/Common';
import { FileUploadingPreviewProps } from './FileUploadingPreview.types';
import { CloseIcon } from '@components/Common/Icons';
import styles from './FileUploadingPreview.module.scss';
import FileIcon from '@components/Common/Files/FileIcon';

const FileUploadingPreview = ({
  srcSmall,
  srcLarge,
  mimeType,
  ext,
  fileName,
  type,
  loading,
  onRemove,
  onClick,
}: FileUploadingPreviewProps) => {
  return (
    <div className={classNames(styles.container)}>
      {
        <div className={styles.preview}>
          <FileIcon
            ext={ext}
            fileName={fileName}
            mimeType={mimeType}
            srcLarge={srcSmall}
            srcSmall={srcLarge}
            type={type}
            onClick={onClick}
          />

          {
            <Loader
              fullSize
              background="none"
              className={styles.loader}
              visible={loading === LoadingTypes.pending}
            />
          }

          <button
            className={styles.removeButton}
            disabled={loading === LoadingTypes.pending}
            onClickCapture={onRemove}
          >
            <CloseIcon />
          </button>
        </div>
      }
    </div>
  );
};

export default FileUploadingPreview;
