import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TabsValuesTypes } from '@components/Conversation/ConversationsDetails/ConversationsDetails.types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { ROUTES } from '@constants';
import subscribersState from '@state/channelsSubscribers';

const useConversationDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [active, setActive] = useState<TabsValuesTypes>(TabsValuesTypes.details);
  const [isDriverEdit, setIsDriverEdit] = useState<boolean>(false);
  const { activeChannel } = useSelector((state: RootState) => state.activity.data);

  const onRedirectToSubscribers = () => {
    navigate(ROUTES.subscribers);
  };

  const onFetchSubscribers = async () => {
    if (activeChannel) {
      return dispatch(subscribersState.actions.getChannelsSubscribers(activeChannel.id)).unwrap();
    }
  };

  useEffect(() => {
    onFetchSubscribers();
  }, []);

  return {
    active,
    setActive,
    activeChannel,
    isDriverEdit,
    setIsDriverEdit,
    onFetchSubscribers,
    onRedirectToSubscribers,
  };
};

export default useConversationDetails;
