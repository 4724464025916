const ROUTES = {
  signIn: '/sign-in',
  channels: '/channels',
  conversation: '/conversations',
  newConversations: '/new-conversations',
  subscribers: '/subscribers',
  //will be removed
  development: '/components-preview',
};

export default ROUTES;
