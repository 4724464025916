import React from 'react';
import classNames from 'classnames';
import { DriverInfoContentProps } from './DriverInfoContent.types';
import { RoundButton, Text } from '@components/Common';
import { CloseIcon, FileIcon } from '@components/Common/Icons';
import styles from './DriverInfoContent.module.scss';

const DriverInfoContent = (props: DriverInfoContentProps) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      <div className={styles.header}>
        <div className={styles.title}>
          <FileIcon />
          <Text>{props.title}</Text>
        </div>

        <RoundButton
          variant="text"
          className={styles.closeButton}
          onClick={props.onClose}
          icon={<CloseIcon />}
        />
      </div>
      {props.children}
    </div>
  );
};

export default DriverInfoContent;
