import React, { forwardRef } from 'react';
import InputContainer from '../InputContainer';
import { InputProps, TRef } from './TextField.types';

const TextField = forwardRef(
  (
    {
      inputSize,
      error,
      success,
      subLabel,
      label,
      iconLeft,
      iconRight,
      className,
      labelIcon,
      type = 'text',
      onIconRightClick,
      onIconLeftClick,
      ...rest
    }: InputProps,
    ref: TRef
  ) => {
    return (
      <InputContainer
        {...{
          inputSize,
          error,
          success,
          subLabel,
          label,
          iconLeft,
          iconRight,
          labelIcon,
          onIconRightClick,
          onIconLeftClick,
        }}
        className={className}
      >
        <input ref={ref} type={type} {...rest} />
      </InputContainer>
    );
  }
);

TextField.displayName = 'TextField';
export default TextField;
