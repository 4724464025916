import React from 'react';
import { MessageAttachmentProps } from './MessageAttachment.types';
import { FileMedia, FilePreviewDetailed } from '@components/Common';
import { downloadFile, formatFileSize } from '@utils';
import classNames from 'classnames';
import styles from './MessageAttachment.module.scss';

const MessageAttachment = (props: MessageAttachmentProps) => {
  return (
    <div className={styles.container}>
      {props.type === 'Document' || props.type === 'Other' ? (
        <div>
          <FilePreviewDetailed
            ext={props.ext}
            fileName={props.fileName}
            fileSize={formatFileSize(props.fileSize)}
            mimeType={props.mimeType}
            srcLarge={props.paths.large}
            srcSmall={props.paths.preview}
            type={props.type}
            onFileDownload={() => downloadFile(props.paths.large, props.fileName)}
          />
        </div>
      ) : (
        <div className={classNames(styles.mediaPreview)}>
          <FileMedia
            ext={props.ext}
            fileName={props.fileName}
            mimeType={props.mimeType}
            srcLarge={props.paths.large}
            srcSmall={props.paths.preview}
            type={props.type}
            onClick={props.onFilePreview}
          />
        </div>
      )}
    </div>
  );
};

export default MessageAttachment;
