import React, { ReactElement } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '@state/store';
import { Provider } from 'react-redux';

export interface StateProviderProps {
  children: ReactElement;
}

const StateProvider = ({ children }: StateProviderProps): ReactElement => {
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>{children}</Provider>
    </PersistGate>
  );
};

export default StateProvider;
