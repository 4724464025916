export enum ServiceWorkerMessageTypes {
  changeCurrentUserId = 'CHANGE_CURRENT_USER_ID',
}

export interface ServiceWorkerMessageData {
  [key: string]: any;
}

export interface NewChannelEntryNotificationPayload {
  topic: string;
  notification: {
    title: string;
    body: string;
    hide_notification_if_site_has_focus: boolean;
  };
  data: {
    channelId: number;
    departmentId: number;
    hasFiles: boolean;
    senderFirstName: string;
    senderLastName: string;
    senderId: number;
  };
}

export interface NotificationEvent {
  pushEvent: ExtendableEvent;
  payload: NewChannelEntryNotificationPayload;
}
