import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './Image.module.scss';
import { ImageProps } from './Image.types';
import classNames from 'classnames';

const Image = ({ src, title, className, onClick }: ImageProps) => {
  // const [bgColor, setBgColor] = useState('white');
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  // const imageRef = useRef<HTMLImageElement>(null);

  // const getAverageColor = useCallback(() => {
  //   const image = imageRef.current;
  //   if (!image) return;
  //
  //   const canvas = document.createElement('canvas');
  //   const context = canvas?.getContext('2d');
  //
  //   context?.drawImage(image, 0, 0, image.width, image.height);
  //   const data = context?.getImageData(0, 0, image.width, image.height)?.data;
  //
  //   if (data) {
  //     const colors = [];
  //     for (let i = 0; i < data.length; i += 4) {
  //       colors.push([data[i], data[i + 1], data[i + 2], data[i + 3]]);
  //     }
  //     const avgColor = colors.reduce((acc, curr) => [
  //       acc[0] + curr[0],
  //       acc[1] + curr[1],
  //       acc[2] + curr[2],
  //       acc[3] + curr[3],
  //     ]);
  //     return avgColor.map((c) => Math.round(c / colors.length));
  //   }
  //
  //   return undefined;
  // }, []);

  // useEffect(() => {
  //   const avgColor = getAverageColor();
  //   if (avgColor) {
  //     setBgColor(`rgba(${avgColor[0]}, ${avgColor[1]}, ${avgColor[2]}, ${avgColor[3] / 255})`);
  //   }
  // }, [getAverageColor]);

  return (
    <div
      className={classNames(styles.container, className)}
      // style={{ backgroundColor: bgColor }}
      onClick={onClick}
    >
      <img
        // ref={imageRef}
        src={src}
        alt={title}
        aria-label={title}
        style={{
          visibility: isImageLoaded ? 'visible' : 'hidden',
        }}
        onLoad={() => setIsImageLoaded(true)}
      />
    </div>
  );
};

export default Image;
