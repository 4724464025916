import React from 'react';
import classNames from 'classnames';
import { MenuItemProps } from './MenuItem.types';
import { H5 } from '@components/Common';
import styles from './MenuItem.module.scss';

const MenuItem = ({
  children,
  active,
  subTitle = false,
  divider = true,
  ...props
}: MenuItemProps) => {
  return (
    <div
      {...props}
      className={classNames(styles.container, {
        [styles.active]: !!active,
        [styles.divider]: !!divider,
        [styles.includesTitle]: !!subTitle,
      })}
    >
      {subTitle ? (
        <H5 className={styles.subTitle} weight="normal">
          {children}
        </H5>
      ) : (
        children
      )}
    </div>
  );
};

export default MenuItem;
