import { LoadingTypes } from '@commonTypes/common';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { FetchStarredMessagesPayload } from '@state/starredMessages/types';
import { useEffect, useState } from 'react';
import starredMessagesState from '@state/starredMessages';

const useStarredChannelEntries = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    data: starredMessages,
    total: starredMessagesCount,
    loading: starredMessagesLoading,
  } = useSelector((state: RootState) => state.starredMessages);

  const currentUser = useSelector((state: RootState) => state.currentUser.profile?.data);

  const [initLoading, setInitLoading] = useState(LoadingTypes.idle);
  const [moreLoading, setMoreInitLoading] = useState(LoadingTypes.idle);

  const fetchMoreStarredMessages = async () => {
    if (
      starredMessagesCount &&
      starredMessages.length < starredMessagesCount &&
      moreLoading !== LoadingTypes.pending
    ) {
      setMoreInitLoading(LoadingTypes.pending);

      await dispatch(
        starredMessagesState.actions.fetchStarredMessages({
          amount: 50,
          skip: starredMessages.length,
          // search: searchValue,
          search: '',
        })
      ).unwrap();

      setMoreInitLoading(LoadingTypes.fulfilled);
    }
  };

  const fetchInitStarredMessages = async () => {
    try {
      setInitLoading(LoadingTypes.pending);
      await dispatch(
        starredMessagesState.actions.fetchStarredMessages({
          amount: 50,
          search: undefined,
          skip: 0,
        })
      ).unwrap();
      setInitLoading(LoadingTypes.fulfilled);
    } catch (error) {
      setInitLoading(LoadingTypes.rejected);
    }
  };

  useEffect(() => {
    if (initLoading !== LoadingTypes.pending) {
      fetchInitStarredMessages();
    }
  }, []);

  return {
    currentUser,
    initLoading,
    moreLoading,
    starredMessages,
    starredMessagesCount,
    starredMessagesLoading,
    fetchMoreStarredMessages,
  };
};

export default useStarredChannelEntries;
