import React from 'react';
import classNames from 'classnames';
import Video from './Video';
import Audio from './Audio';
import styles from './FileMedia.module.scss';
import { FileMediaProps } from './FileMedia.types';
import { Image } from '@components/Common';

const FileMedia = (props: FileMediaProps) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      {props.type === 'Image' && <Image src={props.srcSmall} onClick={props.onClick} />}

      {props.type === 'Audio' && <Audio {...props} />}

      {props.type === 'Video' && <Video {...props} />}
    </div>
  );
};

export default FileMedia;
