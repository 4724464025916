import React from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer } from '@forms';
import { RoundButton, TextField } from '@components/Common';
import { SignInFormProps } from './SignInForm.types';
import styles from './SignInForm.module.scss';
import { validators } from './SignInForm.data';
import { H2 } from '@components/Common/Typography';

const SignInForm = (props: SignInFormProps) => {
  const { loading, saving, errorMessage, initialValues, onError, onSubmit } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  return (
    <FormContainer
      {...{
        loading,
        saving,
      }}
      className={styles.container}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <H2 weight="normal">Sign in</H2>
        </div>

        <TextField
          className={styles.field}
          defaultValue={initialValues?.username}
          error={errors?.username?.message?.toString()}
          label={'Username'}
          placeholder={'Enter username...'}
          {...register('username', validators.username)}
        />

        <TextField
          autoComplete="current-password"
          className={styles.field}
          defaultValue={initialValues?.password}
          error={errors?.password?.message?.toString()}
          label={'Password'}
          placeholder={'Enter password'}
          type="password"
          {...register('password', validators.password)}
        />

        {process.env.REACT_APP_MULTITENANCY_SKIPPED !== 'true' && (
          <TextField
            className={styles.field}
            defaultValue={initialValues?.tenant}
            error={errors?.tenant?.message?.toString()}
            label={'Company ID'}
            placeholder={'Enter company ID'}
            {...register('tenant', validators.tenant)}
          />
        )}

        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

        <RoundButton
          fullWidth
          color="success"
          disabled={!isDirty}
          type="submit"
          variant="contained"
          onClick={() => handleSubmit(onSubmit, onError)()}
        >
          Sign in
        </RoundButton>
      </div>
    </FormContainer>
  );
};

export default SignInForm;
