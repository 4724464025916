import Pusher from 'pusher-js/with-encryption';
import Ajax from 'pusher-js/types/src/core/http/ajax';

export let pusherClient: Pusher | undefined;

export const setupPusherClient = (key?: string | null, cluster?: string | null) => {
  try {
    let pusherKey = key;
    let pusherCluster = cluster;

    if (!pusherKey || !pusherCluster) {
      pusherKey = process.env.REACT_APP_PUSHER_KEY as string;
      pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER as string;
    }

    pusherClient = new Pusher(pusherKey, {
      cluster: pusherCluster,
      channelAuthorization: {
        endpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
        transport: 'ajax',
      },
    });

    // Attach cookies to pusher
    Pusher.Runtime.createXHR = () => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      return xhr as Ajax;
    };
  } catch (error) {
    console.log('[Pusher]: Failed to start pusher client. Error:', error);
  }
};
