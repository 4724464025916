import { useEffect, useState } from 'react';
import { ChannelListTypes } from '@commonTypes/channel';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import activityState from '@state/activity';
import { findChannelById, findDepartmentById } from '@utils';

const useChannels = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isStarredEntriesOpen, setIsStarredEntriesOpen] = useState<boolean>(false);
  const [channelsSearchValue, setChannelsSearchValue] = useState<string | undefined>(undefined);
  const [selectedChannelFilter, setSelectedChannelFilter] = useState<ChannelListTypes>(
    ChannelListTypes.my
  );

  const { list: departments } = useSelector((state: RootState) => state.departments.data);
  const { list: channels } = useSelector((state: RootState) => state.channels.data);

  const onRedirectToNewConversation = () => {
    navigate(ROUTES.newConversations);
  };

  const onSetActiveDepartment = (departmentId: number) => {
    const department = findDepartmentById(departments, departmentId);
    if (department) {
      dispatch(activityState.actions.setActiveDepartment(department));
    }
  };

  const onSetActiveChannel = (channelId: number) => {
    const channel = findChannelById(channels, channelId);
    if (channel) {
      dispatch(activityState.actions.setActiveChannel(channel));
    }
  };

  useEffect(() => {
    if (channels?.length && departments?.length) {
      const urlParams = new URLSearchParams(location.search);
      const channelId = Number(urlParams.get('channelId'));
      const departmentId = Number(urlParams.get('departmentId'));

      if (channelId && departmentId) {
        onSetActiveChannel(channelId);
        onSetActiveDepartment(departmentId);

        searchParams.delete('channelId');
        searchParams.delete('departmentId');
        setSearchParams(searchParams);

        navigate(ROUTES.conversation);
      }
    }
  }, [departments, channels, location]);

  return {
    isStarredEntriesOpen,
    setIsStarredEntriesOpen,
    channelsSearchValue,
    setChannelsSearchValue,
    selectedChannelFilter,
    setSelectedChannelFilter,
    onRedirectToNewConversation,
  };
};

export default useChannels;
