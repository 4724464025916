import React from 'react';
import FileDocument from '../FileDocument';
import { FileIconProps } from './FileIcon.types';
import styles from './FileIcon.module.scss';
import classNames from 'classnames';
import { Image } from '@components/Common';

const FileIcon = (props: FileIconProps) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      {props.type === 'Image' ? <Image src={props.srcSmall} onClick={props.onClick} /> : <FileDocument ext={props.ext} />}
    </div>
  );
};

export default FileIcon;
