import { createSlice } from '@reduxjs/toolkit';
import { LoadingTypes } from '@commonTypes/common';
import { TemplateState } from './types';
import { fetchTemplates, createTemplate, updateTemplate, deleteTemplate } from './thunks';
import { MessageTemplate } from '@commonTypes/message';

export const initialState: TemplateState = {
  data: [],
  loading: LoadingTypes.idle,
};

export const templatesSlice = createSlice({
  name: 'messagesTemplates',
  initialState,
  reducers: {
    addTemplateIfNotExist: (state, { payload }) => {
      if (!state.data.find((template: MessageTemplate) => template.id === payload.id)) {
        state.data.unshift(payload);
      }
    },
    modifyTemplate: (state, { payload }) => {
      state.data = state.data.map((template: MessageTemplate) => {
        if (template.id === payload.id) {
          return payload;
        }
        return template;
      });
    },
    removeTemplate: (state, { payload }) => {
      const id = state.data.find((template: MessageTemplate) => template.id === payload)?.id;
      if (id) {
        state.data = state.data.filter((template: MessageTemplate) => template.id !== id);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplates.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchTemplates.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchTemplates.fulfilled, (state, { payload }) => {
      state.data = payload || [];
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(createTemplate.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(createTemplate.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(createTemplate.fulfilled, (state) => {
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(updateTemplate.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(updateTemplate.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(updateTemplate.fulfilled, (state) => {
      state.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(deleteTemplate.pending, (state) => {
      state.loading = LoadingTypes.pending;
    });

    builder.addCase(deleteTemplate.rejected, (state) => {
      state.loading = LoadingTypes.rejected;
    });

    builder.addCase(deleteTemplate.fulfilled, (state) => {
      state.loading = LoadingTypes.fulfilled;
    });
  },
});

export const actions = templatesSlice.actions;
export default templatesSlice.reducer;
