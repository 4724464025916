import React from 'react';
import { NavMenuProps } from './NavMenu.types';
import styles from './NavMenu.module.scss';
import { DepartmentLink, ActionButtonLink } from '@components/Navigation';
import { getInitials } from '@utils';
import classNames from 'classnames';

const NavMenu = (props: NavMenuProps) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      <div>
        {!!props.departments?.length && (
          <div className={styles.departmentsContainer}>
            {props.departments?.map((department) => (
              <DepartmentLink
                key={`nav-department-${department.id}`}
                departmentName={department.name}
                badge={!!department.unreadChannelEntryCount}
                departmentInitials={getInitials(department.name)}
                onClick={() => props.onDepartmentSelect(department)}
                active={props.activeDepartmentId === department.id}
              />
            ))}
          </div>
        )}

        {!!props.actionButtons?.length && (
          <div className={styles.actionButtonsContainer}>
            {props.actionButtons?.map((title) => (
              <ActionButtonLink
                className={styles.menuItem}
                key={`nav-action-button-${title}`}
                title={title}
                onClick={() => props.onActionButtonClick(title)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavMenu;
