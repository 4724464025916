import React from 'react';
import { isUserMessageOwner } from '@utils';
import { ChannelEntry } from '../../index';
import { InfiniteScroll, Loader } from '@components/Common';
import userChannelsEntriesList from './useChannelsEntriesList';
import styles from './ChannelsEntriesList.module.scss';
import { LoadingTypes } from '@commonTypes/common';

const ChannelsEntriesList = () => {
  const {
    PAGE_SIZE,
    messages,
    currentUserId,
    activeChannel,
    messagesInitLoading,
    messagesMoreLoading,
    onFilePreview,
    fetchMoreMessages,
    onMessageRemove,
    onMessageRetry,
    onMessageStar,
  } = userChannelsEntriesList();

  return (
    <div className={styles.container}>
      {messagesInitLoading !== LoadingTypes.pending && (
        <InfiniteScroll
          className={styles.entriesContainer}
          firstElementId={messages?.[0]?.id}
          lastElementId={messages?.[messages.length - 1]?.id}
          dataLength={messages?.length || 0}
          direction={'start'}
          loading={messagesMoreLoading}
          loaderComponent={<Loader visible className={styles.loader} />}
          emptyMessage={'No messages yet'}
          onLoadMore={() => {
            if (activeChannel) {
              fetchMoreMessages({
                channelId: activeChannel.id,
                amount: PAGE_SIZE,
                beforeMessageId: messages?.[0]?.id,
              });
            }
          }}
        >
          {messages?.map((message) => (
            <ChannelEntry
              {...message}
              className={styles.message}
              isOwner={isUserMessageOwner(message, currentUserId)}
              key={`entry-${message.id}`}
              sentAt={message.createdAt}
              onFilePreview={onFilePreview}
              onRemove={() => onMessageRemove(message.id, message.channelId)}
              onRetry={() => onMessageRetry(message)}
              onStar={() => onMessageStar(message)}
            />
          ))}
        </InfiniteScroll>
      )}
      {messagesInitLoading === LoadingTypes.pending && <Loader visible fullSize />}
    </div>
  );
};

export default ChannelsEntriesList;
