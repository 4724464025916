import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import { useEffect, useState } from 'react';
import { LoadingTypes } from '@commonTypes/common';
import { UserEditProps } from './UserEdit.types';
import attributesState from '@state/attributes';
import { User, UserRoles } from '@commonTypes/user';
import userState from '@state/users';
import usersAttributesState from '@state/usersAttributes';
import { FetchTagsPayload } from '@state/tags/types';
import tagsState from '@state/tags';
import { sortUsersAttributes } from '@utils';
import activityState from '@state/activity';
import channelsState from '@state/channels';
import { ChannelListTypes } from '@commonTypes/channel';
import { PutUserParams } from '@state/users/types';

const useUserEdit = ({ editUserId, onClose }: UserEditProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const PAGE_SIZE = 100;
  const [errorMessage, setErrorMessage] = useState('');
  const { data: activityData } = useSelector((state: RootState) => state.activity);
  const { list: channels } = useSelector((state: RootState) => state.channels.data);
  const { data: usersAttributes, loading: allAttributesLoading } = useSelector(
    (state: RootState) => state.usersAttributes
  );
  const { data: tags, loading: tagsLoading } = useSelector((state: RootState) => state.tags);

  const currentUserDetails = useSelector((state: RootState) =>
    editUserId ? state.usersDetails[editUserId]?.data : undefined
  );
  const currentUserAttributes = sortUsersAttributes(usersAttributes[editUserId]);
  const editUserFormLoading =
    allAttributesLoading === LoadingTypes.pending ? LoadingTypes.pending : LoadingTypes.fulfilled;

  const onFetchAttributesByType = (type: string) => {
    dispatch(
      attributesState.actions.fetchAttributesByUserType({
        userType: type as UserRoles,
      })
    );
  };

  const onFetchUserAttributesValues = () => {
    dispatch(usersAttributesState.actions.fetchUserAttributesValues({ id: +editUserId }));
  };

  const onUserVerified = (originalValues?: User, newValues?: User) => {
    if (activityData.activeChannelsFilter !== ChannelListTypes.new || !channels?.length) {
      return;
    }

    if (originalValues?.isVerified === false && newValues?.isVerified === true) {
      dispatch(activityState.actions.setActiveChannel(undefined));
      dispatch(channelsState.actions.removeChannelByFieldAgentId({ id: newValues.id }));
    }
  };

  const onUserUpdate = async (values: PutUserParams) => {
    try {
      const params = { ...values };

      if (currentUserDetails?.type === UserRoles.fieldAgent) {
        params.userName = currentUserDetails.userName;
        params.email = currentUserDetails.email;
        params.isVerified = params?.isVerified !== undefined ? params?.isVerified : true;
      }

      // update
      const user = (await dispatch(
        userState.actions.putUser({ params, userId: +editUserId })
      ).unwrap()) as User;
      onUserVerified(currentUserDetails, user);

      onClose();
    } catch (error) {
      // update
      setErrorMessage(error as string);
    }
  };

  const onFetchTags = async (params: FetchTagsPayload) => {
    await dispatch(tagsState.actions.fetchTags(params));
  };

  useEffect(() => {
    if (!currentUserAttributes?.length) {
      onFetchUserAttributesValues();
    }

    if (tagsLoading !== LoadingTypes.fulfilled) {
      onFetchTags({ skip: 0, take: PAGE_SIZE });
    }
  }, [editUserId]);

  return {
    PAGE_SIZE,
    tags,
    tagsLoading,
    currentUserAttributes,
    currentUserDetails,
    editUserFormLoading,
    errorMessage,
    setErrorMessage,
    onFetchTags,
    onUserUpdate,
    onFetchAttributesByType,
  };
};

export default useUserEdit;
