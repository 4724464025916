import { createSlice } from '@reduxjs/toolkit';
import {
  fetchEnabledChannelTypes,
  fetchFilesLimitations,
  fetchPhoneCallsOperationStatus,
  fetchTenantConfiguration,
  fetchTwilioToken,
} from './thunks';
import { LoadingTypes } from '@commonTypes/common';
import { SettingsState } from './types';
import { v4 } from 'uuid';

export const initialState: SettingsState = {
  appInstanceId: undefined,
  twilio: {
    data: undefined,
    loading: LoadingTypes.idle,
  },
  enabledChannelTypes: {
    data: undefined,
    loading: LoadingTypes.idle,
  },
  isPhoneCallsEnabled: {
    data: undefined,
    loading: LoadingTypes.idle,
  },
  filesLimits: {
    data: [],
    loading: LoadingTypes.idle,
  },
  tenantConfig: {
    data: undefined,
    loading: LoadingTypes.idle,
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setAppInstanceId: (state) => {
      if (state.appInstanceId) return;
      state.appInstanceId = v4();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEnabledChannelTypes.pending, (state) => {
      state.enabledChannelTypes.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchEnabledChannelTypes.fulfilled, (state, action) => {
      state.enabledChannelTypes.data = action.payload;
      state.enabledChannelTypes.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(fetchEnabledChannelTypes.rejected, (state) => {
      state.enabledChannelTypes.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchTwilioToken.fulfilled, (state, action) => {
      state.twilio.data = action.payload;
      state.twilio.loading = LoadingTypes.fulfilled;
    });

    // builder.addCase(fetchPhoneCallsOperationStatus.pending, (state) => {
    //   state.isPhoneCallsEnabled.loading = LoadingTypes.pending;
    // });
    //
    // builder.addCase(fetchPhoneCallsOperationStatus.fulfilled, (state: RootState, action) => {
    //   state.isPhoneCallsEnabled.data = !!action.payload;
    //   state.isPhoneCallsEnabled.loading = LoadingTypes.fulfilled;
    // });
    //
    // builder.addCase(fetchPhoneCallsOperationStatus.rejected, (state) => {
    //   state.isPhoneCallsEnabled.loading = LoadingTypes.rejected;
    // });

    builder.addCase(fetchFilesLimitations.pending, (state) => {
      state.filesLimits.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchFilesLimitations.fulfilled, (state, { payload }) => {
      state.filesLimits.data = payload;
      state.filesLimits.loading = LoadingTypes.fulfilled;
    });

    builder.addCase(fetchFilesLimitations.rejected, (state) => {
      state.filesLimits.loading = LoadingTypes.rejected;
    });

    builder.addCase(fetchTenantConfiguration.pending, (state) => {
      state.tenantConfig.loading = LoadingTypes.pending;
    });

    builder.addCase(fetchTenantConfiguration.fulfilled, (state, { payload }) => {
      state.tenantConfig.loading = LoadingTypes.fulfilled;
      state.tenantConfig.data = payload;
    });

    builder.addCase(fetchTenantConfiguration.rejected, (state) => {
      state.tenantConfig.loading = LoadingTypes.rejected;
    });
  },
});

export const actions = settingsSlice.actions;
export default settingsSlice.reducer;
