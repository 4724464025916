import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  NavigationProvider,
  StateProvider,
  AuthProvider,
  RequiredDataProvider,
  EventsProvider,
  TenantProvider,
  ActivityProvider,
  NotificationsProvider,
  FilePreviewProvider,
  AppVisibilityStateProvider,
  ErrorBoundary,
} from '@providers';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Router>
    <StateProvider>
      <ErrorBoundary>
        <AuthProvider>
          <TenantProvider>
            <RequiredDataProvider>
              <EventsProvider>
                <NotificationsProvider>
                  <ActivityProvider>
                    <AppVisibilityStateProvider>
                      <FilePreviewProvider>
                        <NavigationProvider />
                      </FilePreviewProvider>
                    </AppVisibilityStateProvider>
                  </ActivityProvider>
                </NotificationsProvider>
              </EventsProvider>
            </RequiredDataProvider>
          </TenantProvider>
        </AuthProvider>
      </ErrorBoundary>
    </StateProvider>
  </Router>
);

serviceWorkerRegistration.register();

reportWebVitals();
