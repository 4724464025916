import React, { useEffect, useState } from 'react';
import { iOS } from '@utils';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@state/types';

const useAppVisibilityStateProvider = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [prevAppVisibilityState, setPrevAppVisibilityState] = useState<string | undefined>();

  const onAppRestart = () => {
    dispatch({ type: 'auth/reload' });
    window.location.reload();
  };

  const onAppVisibilityChange = () => {
    const currentAppVisibilityState = document.visibilityState;

    if (currentAppVisibilityState === 'visible' && prevAppVisibilityState === 'hidden') {
      onAppRestart();
    }

    setPrevAppVisibilityState(document.visibilityState);
  };

  useEffect(() => {
    const isIOS = iOS();

    if (isIOS) {
      document.addEventListener('visibilitychange', onAppVisibilityChange);
      return () => document.removeEventListener('visibilitychange', onAppVisibilityChange);
    }
  }, [prevAppVisibilityState]);
};

export default useAppVisibilityStateProvider;
