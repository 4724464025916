import { ChannelEntry } from '@commonTypes/message';

export enum ChannelListTypes {
  my = 'MyChannels',
  all = 'AllChannels',
  new = 'NewChannels',
  mass = 'MassMessageChannels',
}

export enum ChannelTypes {
  fieldAgentPhone = 'FieldAgentPhone',
  fieldAgentWhatsApp = 'FieldAgentWhatsApp',
  massMessageChannel = 'MassMessageChannel',
}

export interface ChannelFieldAgent {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isVerified?: boolean;
}

export interface Channel {
  id: number;
  name?: string;
  type: ChannelTypes;
  fieldAgent?: ChannelFieldAgent;
  departmentId: number;
  lastEntry: ChannelEntry;
  createdAt: string;
  updatedAt: string;
  unreadMessagesCount: number;
}

export interface ChannelFilesLimitOptions {
  allowedContentTypes: {
    [key: string]: string[];
  };
  maxFileSizeInMb: number;
}

export interface ChannelFilesLimit {
  type: ChannelTypes;
  options: ChannelFilesLimitOptions;
}
