import { createSlice } from '@reduxjs/toolkit';
import { fetchChannelsFiles } from './thunks';
import { LoadingTypes } from '@commonTypes/common';
import { FilesState } from './types';

export const initialState: FilesState = {};

export const settingsSlice = createSlice({
  name: 'channelsFiles',
  initialState,
  reducers: {
    addFiles: (state, action) => {
      const { channelId, data } = action.payload;
      const prevData = state[channelId]?.data || [];
      const newData = data || [];

      state[channelId] = {
        data: [...prevData, ...newData],
        loading: LoadingTypes.fulfilled,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChannelsFiles.pending, (state, { meta }) => {
      const channelId = meta.arg.channelId;
      const prevData = state[channelId]?.data || [];

      state[channelId] = {
        data: prevData,
        loading: LoadingTypes.pending,
      };
    });

    builder.addCase(fetchChannelsFiles.rejected, (state, { meta }) => {
      const channelId = meta.arg.channelId;
      const prevData = state[channelId]?.data || [];

      state[channelId] = {
        data: prevData,
        loading: LoadingTypes.rejected,
      };
    });

    builder.addCase(fetchChannelsFiles.fulfilled, (state, { meta, payload }) => {
      const channelId = meta.arg.channelId;
      const prevData = state[channelId]?.data || [];
      const newData = payload || [];

      state[channelId] = {
        data: [...newData, ...prevData],
        loading: LoadingTypes.fulfilled,
      };
    });
  },
});

export const actions = settingsSlice.actions;
export default settingsSlice.reducer;
