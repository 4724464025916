import React from 'react';
import classNames from 'classnames';
import { ChannelsFilterProps } from './ChannelsFilter.types';
import { CloseIcon, SearchIcon, StarIcon } from '@components/Common/Icons';
import { Button, Select, DebouncedTextField } from '@components/Common';
import useChannelsFilter from './useChannelFilter';
import styles from './ChannelsFilter.module.scss';

const ChannelsFilter = (props: ChannelsFilterProps) => {
  const { isSearchOpen, setIsSearchOpen } = useChannelsFilter();

  return (
    <div className={classNames(styles.container, props.className)}>
      <Select
        className={styles.select}
        value={props.selectedFilter}
        onSelectChange={({ value }) => props.onFilterSelected(value)}
        options={props.options}
        dropdownVerticalOffset={12}
      />
      {!isSearchOpen && (
        <div className={styles.controls}>
          <Button
            icon={<SearchIcon />}
            size="small"
            variant="outlined"
            onClick={() => setIsSearchOpen(true)}
          />

          <Button
            icon={<StarIcon />}
            size="small"
            active={props.isStarrButtonActive}
            variant="outlined"
            onClick={props.onStarrButtonClicked}
          />
        </div>
      )}

      {isSearchOpen && (
        <div className={styles.searchControls}>
          <DebouncedTextField
            delay={500}
            icon={<SearchIcon />}
            inputSize="small"
            placeholder="Search drivers..."
            value={props.searchValue || ''}
            onSearch={props.onSearch}
          />
          <Button
            icon={<CloseIcon />}
            size="small"
            variant="outlined"
            onClick={() => setIsSearchOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

export default ChannelsFilter;
