import React from 'react';
import classNames from 'classnames';
import { LoadingTypes } from '@commonTypes/common';
import { Button, Loader, MenuDetailedItem, RoundButton, TextInfo } from '@components/Common';
import { getInitials } from '@utils';
import { UserTypeLabels } from '@commonTypes/user';
import { ArrowLeftIcon, MenuIcon, SearchIcon } from '@components/Common/Icons';
import useSubscribers from './useSubscribers';
import { NavLayout } from '@components/Navigation';
import MultiSelect from '@components/Common/Selects/MultiSelect';
import { SubscribersAddArea, SubscribersEmptyMessage } from '@components/Subscribers';
import styles from './Subscribers.module.scss';

const ConversationSubscribers = () => {
  const {
    availableUsers,
    setSearchValue,
    currentUserId,
    sortedSubscribers,
    subscribersLoading,
    selectedSubscribers,
    onSelectChange,
    onSelectRemove,
    onRedirectToConversation,
    onRedirectToChannelsList,
    onFetchMoreUsers,
    onaAddChannelsSubscribers,
    onDeleteChannelsSubscriber,
  } = useSubscribers();

  return (
    <NavLayout
      className={styles.container}
      backButton={
        <RoundButton variant="text" icon={<MenuIcon />} onClick={onRedirectToChannelsList} />
      }
    >
      <div className={styles.searchContainer}>
        <RoundButton variant="text" icon={<ArrowLeftIcon />} onClick={onRedirectToConversation} />

        <MultiSelect
          icon={<SearchIcon />}
          initLoading={LoadingTypes.fulfilled}
          moreLoading={LoadingTypes.fulfilled}
          options={availableUsers?.map((user) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          }))}
          placeholder={'Add users...'}
          size="small"
          value={selectedSubscribers.map((follower) => ({
            label: `${follower.firstName} ${follower.lastName}`,
            value: follower.id,
          }))}
          onFilter={setSearchValue}
          onMoreFetch={onFetchMoreUsers}
          onSelectChange={onSelectChange}
          onSelectRemove={onSelectRemove}
        />
      </div>

      {selectedSubscribers?.length ? (
        <SubscribersAddArea
          selectedSubscribers={selectedSubscribers}
          onAddButtonClick={onaAddChannelsSubscribers}
        />
      ) : null}

      <div className={styles.subscribersList}>
        {subscribersLoading === LoadingTypes.pending ? (
          <Loader fullSize visible />
        ) : (
          sortedSubscribers.map((subscriber) => (
            <MenuDetailedItem
              className={styles.subscriberItem}
              key={`subscriber-${subscriber.id}`}
              title={`${subscriber.firstName} ${subscriber.lastName}`}
              subTitle={UserTypeLabels[subscriber.type]}
              initials={getInitials(`${subscriber.firstName} ${subscriber.lastName}`)}
              actionButton={
                <Button
                  className={classNames(styles.unsubscribeButton, {
                    [styles.removeButton]: currentUserId !== subscriber.id,
                  })}
                  variant="contained"
                  // update
                  onClick={() => onDeleteChannelsSubscriber(subscriber.id)}
                >
                  {currentUserId === subscriber.id ? 'Unsubscribe' : 'Remove'}
                </Button>
              }
            />
          ))
        )}

        {subscribersLoading !== LoadingTypes.pending &&
        (!sortedSubscribers?.length ||
          (sortedSubscribers?.length === 1 && sortedSubscribers[0]?.id === currentUserId)) ? (
          <SubscribersEmptyMessage
            children={
              <>
                {!sortedSubscribers?.length ? (
                  <TextInfo>
                    No subscribers yet. Use the field above to add other followers.
                  </TextInfo>
                ) : null}

                {sortedSubscribers?.length === 1 && sortedSubscribers[0]?.id === currentUserId ? (
                  <TextInfo>
                    No other subscribers but you. Use the field above to add other followers.
                  </TextInfo>
                ) : null}
              </>
            }
          />
        ) : null}
      </div>
    </NavLayout>
  );
};

export default ConversationSubscribers;
