import React, { useEffect, useState } from 'react';
import { Button, H4, Image, Modal } from '@components/Common';
import { CloseIcon, DownloadIcon } from '@components/Common/Icons';
import { downloadFile } from '@utils';
import { FullImagePreviewProps } from './FullImagePreview.types';
import styles from './FullImagePreview.module.scss';

const FullImagePreview = ({ src, name, isOpen, onClose }: FullImagePreviewProps) => {
  const [isPreviewOpened, setIsPreviewOpened] = useState(false);

  const onImageDownload = () => downloadFile(src, name);

  useEffect(() => {
    setIsPreviewOpened(isOpen);
  }, [isOpen]);

  return (
    <Modal visible={isPreviewOpened}>
      <div className={styles.container}>
        <div className={styles.header}>
          <H4 className={styles.name}>{name}</H4>
          <div className={styles.controls}>
            <Button variant="text" onClick={onImageDownload} icon={<DownloadIcon />} />
            <Button variant="text" onClick={() => onClose?.()} icon={<CloseIcon />} />
          </div>
        </div>
        <Image src={src} title={name} className={styles.image} />
      </div>
    </Modal>
  );
};

export default FullImagePreview;
