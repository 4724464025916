import React, { Children, KeyboardEvent } from 'react';
import classNames from 'classnames';
import { Loader } from '@components/Common';
import { FormContainerProps } from '@forms/FormContainer/FormContainer.types';
import styles from './FormContainer.module.scss';
import { LoadingTypes } from '@commonTypes/common';

const FormContainer = ({ children, loading, saving, className, ...rest }: FormContainerProps) => {
  return (
    <form
      {...rest}
      className={classNames(styles.container, className)}
      onSubmit={(event: KeyboardEvent<HTMLFormElement>) => event.preventDefault()}
    >
      <Loader fullSize visible={loading === LoadingTypes.pending} />

      <Loader fullSize visible={saving === LoadingTypes.pending} />

      {Children?.map(children, (child) => child)}
    </form>
  );
};

export default FormContainer;
