import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import activityState from '@state/activity';

export interface ActivityProviderProps {
  children: ReactElement;
}

const ActivityProvider = (props: ActivityProviderProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { list: departmentsData } = useSelector((state: RootState) => state.departments.data);
  const { activeDepartment } = useSelector((state: RootState) => state.activity.data);

  useEffect(() => {
    if (!activeDepartment && departmentsData && departmentsData[0]) {
      dispatch(activityState.actions.setActiveDepartment(departmentsData[0]));
    }
  }, [activeDepartment, departmentsData]);

  return props.children;
};

export default ActivityProvider;
