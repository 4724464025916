import React from 'react';
import { SearchItemProps } from './SearchItem.types';
import { Avatar, H4, H6 } from '@components/Common';
import { formatPhoneNumberToLocalUS } from '@utils';
import styles from './SearchItem.module.scss';

const SearchItem = (props: SearchItemProps) => {
  const extractUserInitials = () => {
    try {
      const initials = props.channel.name.split(' ');
      const firstName = initials[0];
      const lastName = initials[1];

      return `${firstName[0]}${lastName[0]}`.toUpperCase();
    } catch (error) {
      return 'NP';
    }
  };

  return (
    <div className={props.className} key={props.channel.id} onClick={props.onClick}>
      <div className={styles.container}>
        <Avatar size="large">{extractUserInitials()}</Avatar>
        <div className={styles.name}>
          <H4 weight="normal">{formatPhoneNumberToLocalUS(props.channel.name)}</H4>
          <div className={styles.telephone}>
            <H6 weight="bold">{formatPhoneNumberToLocalUS(props.channel.phoneNumber || '')}</H6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
