import React, { Children } from 'react';
import classNames from 'classnames';
import { TabsGroupProps } from './Tabs.types';
import styles from './Tabs.module.scss';

const TabsGroup = ({ children, className }: TabsGroupProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      {Children.map(children, (child) => child)}
    </div>
  );
};

export default TabsGroup;
