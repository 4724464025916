import { createSlice } from '@reduxjs/toolkit';
import { fetchMessages } from './thunks';
import { MessagesState } from './types';
import { LoadingTypes } from '@commonTypes/common';

export const initialState: MessagesState = {
  starredCount: 0,
  data: {},
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const { channelId } = action.payload;
      const prevState = state.data[channelId] || {};

      prevState?.data?.push(action.payload);

      state.data[channelId] = {
        ...prevState,
      };
    },
    resetMessages: (state) => {
      state.data = initialState.data;
    },
    modifyMessage: (state, action) => {
      const { messageId, data } = action.payload;
      const { channelId } = data;

      const prevState = state.data[channelId] || {};
      prevState.data = prevState.data.map((message) =>
        message.id === messageId ? { ...message, ...data } : message
      );

      state.data[channelId] = {
        ...prevState,
      };
    },
    removeMessage: (state, action) => {
      const { messageId, channelId } = action.payload;

      const prevState = state.data[channelId] || {};
      prevState.data = prevState.data.filter((message) => message.id !== messageId);

      state.data[channelId] = {
        ...prevState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.pending, (state, { meta }) => {
      const channelId = meta.arg.channelId;
      const prevData = state.data[channelId]?.data || [];

      state.data[channelId] = {
        data: prevData,
        loading: LoadingTypes.pending,
      };
    });

    builder.addCase(fetchMessages.rejected, (state, { meta }) => {
      const channelId = meta.arg.channelId;
      const prevData = state.data[channelId]?.data || [];

      state.data[channelId] = {
        data: prevData,
        loading: LoadingTypes.rejected,
      };
    });

    builder.addCase(fetchMessages.fulfilled, (state, { meta, payload }) => {
      const channelId = meta.arg.channelId;
      const prevData = (meta.arg.beforeMessageId ? state.data[channelId]?.data : []) || [];
      const newData = payload || [];

      state.data[channelId] = {
        data: [...newData, ...prevData],
        loading: LoadingTypes.fulfilled,
      };
    });
  },
});

export const actions = messagesSlice.actions;
export default messagesSlice.reducer;
