import React from 'react';
import { FormDataValuesType } from '@commonTypes/forms';
import { UserEditFormProps } from './UserEditForm.types';
import { Option } from '@commonTypes/common';
import { extractModifiedUsersAttributes } from '@utils/user';

const useUserEditForm = ({ onSubmit, attributes }: Partial<UserEditFormProps>) => {
  const extractModifiedTags = (tagsValues: Option[]) => {
    if (tagsValues?.length) {
      return tagsValues.map((tag: Option) => tag.value);
    }

    return [];
  };

  const groupValuesBeforeSubmit = (values: FormDataValuesType) => {
    const { tagsValues, customAttributes, ...initialValues } = values || {};

    onSubmit?.({
      tagIds: extractModifiedTags(tagsValues),
      attributes: extractModifiedUsersAttributes(customAttributes, attributes),
      ...initialValues,
    });
  };

  return {
    groupValuesBeforeSubmit,
  };
};

export default useUserEditForm;
