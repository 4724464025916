import React from 'react';
import styles from './DriverNote.module.scss';
import { DriverNoteProps } from './DriverNote.types';
import DriverInfoContent from '../DriverInfoContent';
import { DebouncedTextArea, Loader } from '@components/Common';
import useDriverNote from './useDriverNote';
import { LoadingTypes } from '@commonTypes/common';

const DriverNote = (props: DriverNoteProps) => {
  const { noteValue, noteLoading, onNoteValueChange } = useDriverNote(props);

  return (
    <DriverInfoContent title={'Notes'} className={styles.container} onClose={props.onClose}>
      <Loader fullSize visible={!noteValue && noteLoading === LoadingTypes.pending} />

      <DebouncedTextArea
        className={styles.note}
        delay={500}
        value={noteValue}
        onSearch={onNoteValueChange}
      />
    </DriverInfoContent>
  );
};

export default DriverNote;
