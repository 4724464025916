import { useContext, useEffect, useState } from 'react';
import { LoadingTypes } from '@commonTypes/common';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@state/types';
import channelsFilesState from '@state/channelsFiles';
import { Attachment } from '@commonTypes/attachment';
import { FilePreviewContext } from '@providers/FilePreviewProvider';

const useConversationFiles = () => {
  const dispatch = useDispatch<AppDispatch>();
  const filesModalContext = useContext(FilePreviewContext);

  const [initLoading, setInitLoading] = useState(LoadingTypes.idle);
  const [moreLoading, setMoreLoading] = useState(LoadingTypes.idle);
  const activeChannelId = useSelector((state: RootState) => state.activity.data?.activeChannel?.id);
  const currentUserId = useSelector((state: RootState) => state.currentUser.profile?.data?.id);
  const files = useSelector((state: RootState) =>
    activeChannelId ? state.channelsFiles?.[activeChannelId]?.data : []
  );

  const onFetchMoreFiles = async () => {
    try {
      if (activeChannelId) {
        setMoreLoading(LoadingTypes.pending);

        await dispatch(
          channelsFilesState.actions.fetchChannelsFiles({
            channelId: activeChannelId,
            amount: 20,
            beforeFileId: files[0]?.id,
          })
        ).unwrap();

        setMoreLoading(LoadingTypes.fulfilled);
      }
    } catch (error) {
      setMoreLoading(LoadingTypes.rejected);
    }
  };

  const fetchInitialFiles = async () => {
    try {
      if (activeChannelId) {
        setInitLoading(LoadingTypes.pending);

        await dispatch(
          channelsFilesState.actions.fetchChannelsFiles({
            channelId: activeChannelId,
            amount: 20,
            beforeFileId: undefined,
          })
        ).unwrap();

        setInitLoading(LoadingTypes.fulfilled);
      }
    } catch (error) {
      setInitLoading(LoadingTypes.rejected);
    }
  };

  const onFilePreview = (file: Attachment) => {
    if (file.mimeType.includes('image')) {
      filesModalContext?.openFileInModalWindow(file);
    }
  };

  useEffect(() => {
    if (initLoading !== LoadingTypes.pending) {
      fetchInitialFiles();
    }
  }, []);

  return {
    files,
    currentUserId,
    initLoading,
    moreLoading,
    onFetchMoreFiles,
    onFilePreview,
  };
};

export default useConversationFiles;
