import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '@api';
import { FetchUsersPayload, PostUsersPayload, PutUsersPayload } from './types';
import channelsState from '@state/channels';
import activityState from '@state/activity';

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (params: FetchUsersPayload, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.instance.get('/users', {
        params: params,
      });

      return data;
    } catch (error) {
      return rejectWithValue({});
    }
  }
);

export const postUser = createAsyncThunk(
  'users/postUser',
  async (usersPayload: PostUsersPayload, { rejectWithValue }) => {
    try {
      const response = await apiClient.instance.post('/users', usersPayload);

      if (response?.data?.errors?.length) {
        return rejectWithValue(response?.data?.errors?.join('\n'));
      }

      return response.data.user;
    } catch (error) {
      return rejectWithValue('Failed to create new user');
    }
  }
);

export const putUser = createAsyncThunk(
  'users/putUser',
  async ({ params, userId }: PutUsersPayload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiClient.instance.put(`/users/${userId}`, {
        ...params,
      });

      if (data.user) {
        const payload = {
          id: data.user.id,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          isVerified: data.user.isVerified,
        };

        dispatch(channelsState.actions.modifyChannelFieldAgent(payload));
        dispatch(activityState.actions.modifyActiveChannelFieldAgent(payload));
      }

      return data.user;
    } catch (error) {
      return rejectWithValue('Failed to update user');
    }
  }
);
